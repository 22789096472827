import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const QCADIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 292 292" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_714_81579)">
        <path
          d="M146 292C226.634 292 292 226.634 292 146C292 65.3664 226.634 0 146 0C65.3664 0 0 65.3664 0 146C0 226.634 65.3664 292 146 292Z"
          fill="#191918"
        />
        <path
          opacity="0.57"
          d="M101.059 68.4375L99.4625 109.272L119.081 119.081L125.925 99.9187L101.059 68.4375Z"
          fill="url(#paint0_linear_714_81579)"
        />
        <path
          opacity="0.57"
          d="M194.134 70.7188L194.362 111.325L174.516 120.45L168.356 101.288L194.134 70.7188Z"
          fill="url(#paint1_linear_714_81579)"
        />
        <path
          opacity="0.57"
          d="M250.253 149.422L228.353 172.919L211.928 167.444L218.772 152.844L250.253 149.422Z"
          fill="url(#paint2_linear_714_81579)"
        />
        <path
          opacity="0.57"
          d="M46.3094 152.616L69.35 175.2L85.5469 169.041L78.0188 154.897L46.3094 152.616Z"
          fill="url(#paint3_linear_714_81579)"
        />
        <path
          opacity="0.88"
          d="M252.763 185.009L179.306 158.775L151.019 188.431L180.447 213.525L252.763 185.009Z"
          fill="url(#paint4_linear_714_81579)"
        />
        <path
          opacity="0.88"
          d="M54.75 92.8469L146.912 134.822L146.456 187.975L96.2687 184.781L54.75 92.8469Z"
          fill="url(#paint5_linear_714_81579)"
        />
        <path
          opacity="0.88"
          d="M44.4844 189.116L116.572 159.916L146.228 188.431L117.713 214.666L44.4844 189.116Z"
          fill="url(#paint6_linear_714_81579)"
        />
        <path
          opacity="0.85"
          d="M145.772 191.169L148.509 189.116L151.475 191.169V255.044H145.316L145.772 191.169Z"
          fill="url(#paint7_linear_714_81579)"
        />
        <path
          opacity="0.88"
          d="M146.913 53.8375L110.869 148.738L148.738 186.15L182.044 148.509L146.913 53.8375Z"
          fill="url(#paint8_linear_714_81579)"
        />
        <path
          opacity="0.88"
          d="M242.725 92.6188L150.334 134.594L150.791 187.747L200.978 184.553L242.725 92.6188Z"
          fill="url(#paint9_linear_714_81579)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_714_81579"
          x1="99.9456"
          y1="93.4779"
          x2="126.104"
          y2="94.2085"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_714_81579"
          x1="194.431"
          y1="95.5958"
          x2="168.264"
          y2="95.5958"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_714_81579"
          x1="198.651"
          y1="127.707"
          x2="183.563"
          y2="113.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_714_81579"
          x1="60.5304"
          y1="166.474"
          x2="74.9027"
          y2="151.622"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_714_81579"
          x1="141.949"
          y1="127.655"
          x2="192.175"
          y2="177.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_714_81579"
          x1="145.615"
          y1="139.949"
          x2="53.6342"
          y2="140.869"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_714_81579"
          x1="141.414"
          y1="139.194"
          x2="93.3256"
          y2="190.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_714_81579"
          x1="145.361"
          y1="222.125"
          x2="151.407"
          y2="222.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_714_81579"
          x1="110.892"
          y1="120.039"
          x2="182.135"
          y2="120.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_714_81579"
          x1="150.319"
          y1="139.844"
          x2="242.295"
          y2="140.599"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DCDBDB" />
          <stop offset="1" stopColor="#B2B2B2" />
        </linearGradient>
        <clipPath id="clip0_714_81579">
          <rect width="292" height="292" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
