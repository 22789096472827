import React from 'react'
import { ProvideAuth } from '../auth'
import { CombineProviders } from './CombineProviders'

/**
 * ordering matter, providers will be combined from left to right (outer to inner)
 * this is important because some contexts might be used in other contexts
 * (e.g. we want auth context to be on top since we're using it to check if the user
 * exists in the wallet context)
 */
const providers = [ProvideAuth] as React.FC[]

const AppContextProvider: React.FC = CombineProviders(providers)

export default AppContextProvider
