import { Alert, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BillPayDetailsForm from 'src/common/components/BillPayDetailsForm'
import { FormInput } from 'src/common/components/CrudActionCard/CrudActionCard'
import FormGroup from 'src/common/components/FormGroup'
import { billPayDetails } from 'src/pages/ApiMembers/Common/data'
import { BillPayInfo, MemberDetailsInfo } from '.././types'
import { adminCreateMemberBillpayDetails } from 'src/common/api/organizations'
import { alertNotification } from 'src/ui'
import { LoadingButton } from '@mui/lab'
import { ValidationError } from 'src/common/helpers'

interface CreateMemberBillPayProps {
  entityData: FormInput[][]
}

interface CreateMemberBillpayState {
  detailsInfo: MemberDetailsInfo
  billPays: BillPayInfo[]
}

const CreateBillPayDetails: React.FC<CreateMemberBillPayProps> = ({ entityData }: CreateMemberBillPayProps) => {
  const entityInfo: FormInput[] = entityData[0]
  const entityBillPayDetails: FormInput[] = entityData[1]

  const emptyMemberDetailsInfo: MemberDetailsInfo = {
    orgId: '',
    memberId: ''
  }

  const emptyMemberBillPay: BillPayInfo = {
    payeeName: '',
    payeeCode: '',
    payeeAccountNumber: ''
  }

  // store form information about member
  const [billPayDetailsArr, setBillPayDetailsArr] = useState<FormInput[][]>([entityBillPayDetails])

  // store payload for create req
  const [memberDetailsInfo, setMemberDetailsInfo] = useState<MemberDetailsInfo>(emptyMemberDetailsInfo)
  const [memberBillPay, setMemberBillPay] = useState<BillPayInfo[]>([emptyMemberBillPay])

  const emptyCreateMemberBillpayState: CreateMemberBillpayState = {
    detailsInfo: emptyMemberDetailsInfo,
    billPays: [emptyMemberBillPay]
  }

  const [memberState, setMemberState] = useState<CreateMemberBillpayState>(emptyCreateMemberBillpayState)
  const [loading, setLoading] = useState<boolean>(false)

  const handleAddBillPay = (): void => {
    setBillPayDetailsArr((prev) => [...prev, billPayDetails])

    setMemberBillPay((prev) => [...prev, emptyMemberBillPay])
  }

  const handleCreateMemberBillPayDetailsRequest = async () => {
    try {
      setLoading(true)
      const response = await adminCreateMemberBillpayDetails({
        orgId: memberState.detailsInfo.orgId,
        memberId: memberState.detailsInfo.memberId,
        billPayDetails: memberState.billPays
      })
      if (response) {
        alertNotification(
          `Member ${memberState.detailsInfo.memberId}'s bill pay details has been created successfully`,
          'success'
        )
        setMemberDetailsInfo(emptyMemberDetailsInfo)
        setMemberBillPay([emptyMemberBillPay])
        setBillPayDetailsArr([billPayDetails])
        setLoading(false)
      }
      setFormKey((prevKey) => prevKey + 1)
    } catch (e) {
      setLoading(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }
  const [formKey, setFormKey] = useState<number>(0)

  useEffect(() => {
    setMemberState({
      detailsInfo: memberDetailsInfo,
      billPays: memberBillPay
    })
  }, [memberDetailsInfo, memberBillPay, billPayDetailsArr])

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <Grid container spacing={2} marginBottom={10}>
        <Grid item md={6}>
          <FormGroup
            header="Member Information"
            formInputs={entityInfo}
            setInfo={setMemberDetailsInfo}
            info={memberDetailsInfo}
          />
        </Grid>
        <Grid item md={6}>
          <BillPayDetailsForm
            key={formKey}
            billPayDetails={billPayDetailsArr}
            handleAddBillPay={handleAddBillPay}
            setBillPay={setMemberBillPay}
            info={memberBillPay}
          />
        </Grid>
      </Grid>
      <LoadingButton loading={loading} onClick={handleCreateMemberBillPayDetailsRequest} variant="contained" fullWidth>
        Create member billpay details
      </LoadingButton>
    </Box>
  )
}

export default CreateBillPayDetails
