import React, { useState } from 'react'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { DeleteMemberBillPayDetailsInfo } from './types'
import { CrudActionContext } from '../context'
import { Box } from '@mui/material'
import { alertNotification } from 'src/ui'
import { ValidationError } from 'src/common/helpers'
import { adminDeleteMemberBillPayDetails } from 'src/common/api/organizations'

interface DeleteMemberProps {
  formInputs: FormInput[]
}

const DeleteMemberBillPayDetails: React.FC<DeleteMemberProps> = ({ formInputs }: DeleteMemberProps) => {
  const emptyDeleteMemberBillPayDetails: DeleteMemberBillPayDetailsInfo = {
    memberId: '',
    billPayDetailsId: ''
  }

  const [deleteMemberBillpayDetails, setDeleteMemberBillpayDetails] = useState<DeleteMemberBillPayDetailsInfo>(
    emptyDeleteMemberBillPayDetails
  )

  const handleDeleteMemberBillPayDetailsRequest = async () => {
    try {
      const response = await adminDeleteMemberBillPayDetails(
        deleteMemberBillpayDetails.memberId,
        deleteMemberBillpayDetails.billPayDetailsId
      )
      if (response) {
        alertNotification(
          `Member ${deleteMemberBillpayDetails.memberId}'s bill pay details ${deleteMemberBillpayDetails.billPayDetailsId} has been deleted successfully`,
          'success'
        )
        setDeleteMemberBillpayDetails(emptyDeleteMemberBillPayDetails)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <CrudActionContext.Provider value={handleDeleteMemberBillPayDetailsRequest}>
        <CrudActionCard
          crudType={CrudTypes.Delete}
          formInputs={formInputs}
          setInfo={setDeleteMemberBillpayDetails}
          info={deleteMemberBillpayDetails}
        />
      </CrudActionContext.Provider>
    </Box>
  )
}

export default DeleteMemberBillPayDetails
