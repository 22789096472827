import { Alert, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import { alertNotification, Container } from 'src/ui'
import { getFeatureStates, patchFeatureStates } from 'src/common/api/flagsmith'

interface FeatureFlagState {
  selectedFlag: 'enable-stellar' | 'enable-purchase-billpay' | 'enable-purchase-flow'
  enable: 'true' | 'false'
  description: string
}

const FeatureFlags: React.FC = () => {
  const [featureFormState, setFeatureFormState] = useState<FeatureFlagState>({
    selectedFlag: 'enable-stellar',
    enable: 'true',
    description: ''
  })
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const featureName = featureFormState.selectedFlag

  useEffect(() => {
    // Fetch the existing feature state and prefill the form
    const fetchFeatureState = async () => {
      try {
        const getFeatureStatesResponse = await getFeatureStates(featureName)
        const featureState = getFeatureStatesResponse.data.results[0]

        setFeatureFormState((prevState) => ({
          ...prevState,
          enable: featureState.enabled.toString(),
          description: featureState.feature_state_value
        }))
      } catch (error) {
        console.error('Error fetching feature state:', error)
      }
    }

    fetchFeatureState()
  }, [featureFormState.selectedFlag])

  const handleRequest = async () => {
    try {
      setSubmitting(true)

      // Get the existing feature state id based on the feature name
      const getFeatureStatesResponse = await getFeatureStates(featureName)
      const featureStateId = getFeatureStatesResponse.data.results[0].id

      // Construct the payload using the form data
      const payload = {
        id: featureStateId,
        enabled: featureFormState.enable === 'true'
      }

      // Update the feature state
      const updateFeatureStateResponse = await patchFeatureStates(featureStateId, payload)

      // Handle successful update
      console.log('Feature state updated successfully:', updateFeatureStateResponse.data)

      // Update success message and reset the form state
      setSuccessMessage('Feature Flag updated successfully!')
      setSubmitting(false)
    } catch (error) {
      // Handle error case
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFeatureFormState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const { selectedFlag: selectFlag, enable, description } = featureFormState

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <>
          <Typography variant="h6">Grapes Feature Flags</Typography>
          <Alert severity="info">{description}</Alert>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Select Feature'}
            <TextField
              name="selectedFlag"
              select
              placeholder="Select Flag"
              onChange={handleSelectChange}
              value={selectFlag}
              fullWidth
            >
              <MenuItem value="enable-stellar">Enable Stellar</MenuItem>
              <MenuItem value="enable-purchase-billpay">Enable Purchase Billpay</MenuItem>
              <MenuItem value="enable-purchase-flow">Enable Purchase Flow</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Enable Feature'}
            <TextField
              name="enable"
              select
              placeholder="Select Flag"
              onChange={handleSelectChange}
              value={enable}
              fullWidth
            >
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </TextField>
          </Stack>
          <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
            Update Feature Flag
          </LoadingButton>
        </>
      </Stack>
    </Container>
  )
}

export default FeatureFlags
