import { Box, Button } from '@mui/material'
import React from 'react'
import { useCrudActionContext } from 'src/pages/ApiMembers/context'
import DropDown from '../dropDown/DropDown'
import { DropDownTypes } from '../dropDown/constants/DropDownTypes'
import { CrudTypes } from './constants/CrudTypes'

interface CrudButtonProps {
  crudType: CrudTypes
  dropdownType?: DropDownTypes
}

const CrudButtons: React.FC<CrudButtonProps> = ({ crudType, dropdownType }: CrudButtonProps) => {
  const onButtonClick = useCrudActionContext()
  return (
    <Box>
      {dropdownType === undefined ? (
        <Button onClick={() => onButtonClick(crudType, dropdownType)}>{crudType}</Button>
      ) : (
        <DropDown placeholder={crudType} type={dropdownType} />
      )}
    </Box>
  )
}

export default CrudButtons
