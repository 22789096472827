/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, TextField, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import SummaryTable, { Row } from 'src/ui/SummaryTable/SummaryTable'
import { FormType } from '../dropDown/constants/FormType'
import { DropDownTypes } from '../dropDown/constants/DropDownTypes'
import DropDown from '../dropDown/DropDown'

// TODO: consider reducing the props
interface EntityCardGroupProps {
  header: string
  cardInputs: CardInput[]
  setInfo?: Dispatch<SetStateAction<any>>
  info?: any
  isEdit?: boolean
  index?: number
}
export interface CardInput {
  formTitle: string
  formId: string
  formType: FormType
  formValue: string
  dropDownType?: DropDownTypes
}

const EntityCardGroup: React.FC<EntityCardGroupProps> = ({
  header,
  cardInputs,
  isEdit,
  setInfo,
  info,
  index
}: EntityCardGroupProps) => {
  const uneditableFields: string[] = [
    'id',
    'orgId',
    'createdAt',
    'updatedAt',
    'type',
    'accountId',
    'accountType',
    'isPrimary',
    'email'
  ]
  const handleEditChange = (value: string, target: string) => {
    if (setInfo) {
      if (index !== undefined) {
        setInfo((prevInputs: any) => {
          const updatedInputs = [...prevInputs]
          const updatedInput = { ...updatedInputs[index], [target]: value }
          updatedInputs[index] = updatedInput
          return updatedInputs
        })
      } else {
        setInfo((prevInputs: any) => ({
          ...prevInputs,
          [target]: value
        }))
      }
    }
  }

  function convertFormInputsToRows(cardInputs: CardInput[]): Row[] {
    const res = cardInputs.map((input) => ({
      key: <Typography fontWeight={500}>{input.formTitle}</Typography>,
      value: isEdit ? (
        uneditableFields.includes(input.formId) ? (
          <Typography sx={{ whiteSpace: 'normal', width: '50%', overflow: 'auto' }}>{input.formValue}</Typography>
        ) : input.dropDownType !== undefined ? (
          <DropDown type={input.dropDownType} handleChange={(value) => handleEditChange(value, input.formId)} />
        ) : (
          <TextField
            label={input.formValue}
            name={input.formId}
            value={info ? (index === undefined ? info[input.formId] : info[index][input.formId]) : ''}
            onChange={(e) => handleEditChange(e.target.value, input.formId)}
          ></TextField>
        )
      ) : (
        <Typography sx={{ whiteSpace: 'normal', width: '70%', overflow: 'scroll' }}>{input.formValue}</Typography>
      )
    }))
    return res
  }
  const data = convertFormInputsToRows(cardInputs)

  return (
    <Box sx={{ marginLeft: '44px', marginBottom: '10px' }}>
      {(index === 0 || index === undefined) && (
        <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
          {header}
        </Typography>
      )}
      <SummaryTable data={data} padding="8px 0" />
    </Box>
  )
}

export default EntityCardGroup
