import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { DropDownTypes } from 'src/common/components/dropDown/constants/DropDownTypes'
import { FormType } from 'src/common/components/dropDown/constants/FormType'

export const kybFormInput: FormInput[] = [
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  },
  {
    formTitle: 'KYB Status',
    formId: 'kybStatus',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.KybStatus
  }
]
