import { CardInput } from 'src/common/components/EntityCardGroup/EntityCardGroup'
import { DropDownTypes } from 'src/common/components/dropDown/constants/DropDownTypes'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { OrgCardHeaderInput, OrgCardInput } from '../types'

export const orgCardInfoInput: CardInput[] = [
  {
    formTitle: 'Organization ID',
    formId: 'id',
    formType: FormType.input,
    formValue: 'Organization ID'
  },
  {
    formTitle: 'Organization Name',
    formId: 'orgName',
    formType: FormType.input,
    formValue: 'Organization Name'
  },
  {
    formTitle: 'Email',
    formId: 'email',
    formType: FormType.input,
    formValue: 'Email'
  },
  {
    formTitle: 'Address',
    formId: 'address',
    formType: FormType.input,
    formValue: 'Address'
  },
  {
    formTitle: 'City',
    formId: 'city',
    formType: FormType.input,
    formValue: 'City'
  },
  {
    formTitle: 'State/Province',
    formId: 'stateProvince',
    formType: FormType.input,
    formValue: 'State/Province'
  },
  {
    formTitle: 'Country',
    formId: 'countryCode',
    formType: FormType.input,
    formValue: 'Country'
  },
  {
    formTitle: 'Topic ARN',
    formId: 'topicARN',
    formType: FormType.input,
    formValue: 'Topic ARN'
  },
  {
    formTitle: 'Created At',
    formId: 'createdAt',
    formType: FormType.input,
    formValue: 'Country At'
  },
  {
    formTitle: 'Updated At',
    formId: 'updatedAt',
    formType: FormType.input,
    formValue: 'Updated At'
  }
]

export const orgCardBankInput: CardInput[][] = [
  [
    {
      formTitle: 'ID',
      formId: 'id',
      formType: FormType.input,
      formValue: 'ID'
    },
    {
      formTitle: 'Country',
      formId: 'country',
      formType: FormType.input,
      formValue: 'Country',
      dropDownType: DropDownTypes.Country
    },
    {
      formTitle: 'Holder Name',
      formId: 'holderName',
      formType: FormType.input,
      formValue: 'Holder Name'
    },
    {
      formTitle: 'Routing Number',
      formId: 'rountingNum',
      formType: FormType.input,
      formValue: 'Routing Number'
    },
    {
      formTitle: 'Account Number',
      formId: 'accNum',
      formType: FormType.input,
      formValue: 'Account Number'
    },
    {
      formTitle: 'ACH Code',
      formId: 'achCode',
      formType: FormType.input,
      formValue: 'ACH Code'
    },
    {
      formTitle: 'Swift Code',
      formId: 'swiftCode',
      formType: FormType.input,
      formValue: 'Swift Code'
    },
    {
      formTitle: 'Is Primary',
      formId: 'isPrimary',
      formType: FormType.input,
      formValue: 'Is Primary'
    },
    {
      formTitle: 'Created At',
      formId: 'createdAt',
      formType: FormType.input,
      formValue: 'Created At'
    },
    {
      formTitle: 'Updated At',
      formId: 'updatedAt',
      formType: FormType.input,
      formValue: 'Updated At'
    }
  ]
]

export const orgCardBlockchainInput: CardInput[][] = [
  [
    {
      formTitle: 'ID',
      formId: 'id',
      formType: FormType.input,
      formValue: 'ID'
    },
    {
      formTitle: 'Name',
      formId: 'name',
      formType: FormType.input,
      formValue: 'Name'
    },
    {
      formTitle: 'Address',
      formId: 'address',
      formType: FormType.input,
      formValue: 'Address'
    },
    {
      formTitle: 'Chain',
      formId: 'chain',
      formType: FormType.input,
      formValue: 'Chain',
      dropDownType: DropDownTypes.Chain
    },
    {
      formTitle: 'Type',
      formId: 'type',
      formType: FormType.input,
      formValue: 'Type'
    },
    {
      formTitle: 'External Memo',
      formId: 'externalMemo',
      formType: FormType.input,
      formValue: 'External Memo'
    },
    {
      formTitle: 'Created At',
      formId: 'createdAt',
      formType: FormType.input,
      formValue: 'Created At'
    },
    {
      formTitle: 'Updated At',
      formId: 'updatedAt',
      formType: FormType.input,
      formValue: 'Updated At'
    }
  ]
]

export const orgCardBillPayInput: CardInput[][] = [
  [
    {
      formTitle: 'ID',
      formId: 'id',
      formType: FormType.input,
      formValue: 'ID'
    },
    {
      formTitle: 'Payee Name',
      formId: 'payeeName',
      formType: FormType.input,
      formValue: 'Payee Name'
    },
    {
      formTitle: 'Payee Code',
      formId: 'payeeCode',
      formType: FormType.input,
      formValue: 'Payee Code'
    },
    {
      formTitle: 'Payee Account Number',
      formId: 'payeeAccountNumber',
      formType: FormType.input,
      formValue: 'Payee Account Number'
    },
    {
      formTitle: 'Fingerprint',
      formId: 'fingerprint',
      formType: FormType.input,
      formValue: 'Fingerprint'
    },
    {
      formTitle: 'Created At',
      formId: 'createdAt',
      formType: FormType.input,
      formValue: 'Created At'
    },
    {
      formTitle: 'Updated At',
      formId: 'updatedAt',
      formType: FormType.input,
      formValue: 'Updated At'
    }
  ]
]

export const orgCardHeaderInput: OrgCardHeaderInput = {
  infoHeader: 'Organization Details',
  bankHeader: 'Bank Details',
  blockchainHeader: 'Blockchain Details',
  billPayHeader: 'Bill Pay Details'
}

export const orgCardInput: OrgCardInput = {
  info: orgCardInfoInput,
  bank: orgCardBankInput,
  blockchain: orgCardBlockchainInput,
  billPay: orgCardBillPayInput
}
