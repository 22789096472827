/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import CrudButtons from '../CrudButtons'
import { CrudTypes } from '../CrudButtons/constants/CrudTypes'
import FormGroup from '../FormGroup'
import { DropDownTypes } from '../dropDown/constants/DropDownTypes'
import { FormType } from '../dropDown/constants/FormType'
import { MemberBasicInfo, MemberDetailsInfo } from 'src/pages/ApiMembers/types'
import { OrgBasicInfo } from 'src/pages/ApiOrganizations/types'
import {
  DeleteMemberBankDetailsInfo,
  DeleteMemberBillPayDetailsInfo,
  DeleteMemberBlockchainDetailsInfo
} from 'src/pages/ApiMembers/Delete'
import { GetOrderInput, GetPurchaseOrderInput, GetRedeemOrderInput } from 'src/pages/ApiOrders/types'

interface CrudActionCardProps {
  crudType: CrudTypes
  formInputs: FormInput[]
  setInfo?: Dispatch<SetStateAction<any>>
  info?:
    | MemberDetailsInfo
    | OrgBasicInfo
    | DeleteMemberBlockchainDetailsInfo
    | DeleteMemberBankDetailsInfo
    | DeleteMemberBillPayDetailsInfo
    | MemberBasicInfo
    | GetOrderInput
    | GetPurchaseOrderInput
    | GetRedeemOrderInput
}

export interface FormInput {
  formTitle: string
  formId: string
  formType: FormType
  dropDownType?: DropDownTypes
  formValue?: string
}

// Todo: look at this to handle requests
const CrudActionCard: React.FC<CrudActionCardProps> = ({
  crudType,
  formInputs,
  setInfo,
  info
}: CrudActionCardProps) => {
  return (
    <Box>
      <FormGroup formInputs={formInputs} setInfo={setInfo} info={info} />
      <CrudButtons crudType={crudType} />
    </Box>
  )
}

export default CrudActionCard
