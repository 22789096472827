/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { adminGetMemberByMemId, MemberDetailsModel, MemberModel } from 'src/common/api/organizations'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { ValidationError } from 'src/common/helpers'
import { alertNotification } from 'src/ui'
import {
  memberCardBankInput,
  memberCardBillPayInput,
  memberCardBlockchainInput,
  memberCardHeaderInput,
  memberCardInfoInput
} from '../Common/formInputs'
import MemberCard from '../MemberCard'
import { CrudActionContext } from '../context'
import { MemberCardInput, MemberDetailsInfo } from '../types'

// Constants for default values
const NULL_STRING = 'NULL'
const EMPTY_STRING = ''
const DEFAULT_MEMBER_DETAILS: MemberModel = {
  accountId: EMPTY_STRING,
  id: EMPTY_STRING,
  orgId: EMPTY_STRING,
  firstName: EMPTY_STRING,
  middleName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  email: EMPTY_STRING,
  businessName: EMPTY_STRING,
  address: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  countryCode: EMPTY_STRING,
  city: EMPTY_STRING,
  stateProvince: EMPTY_STRING,
  accountType: EMPTY_STRING,
  createdAt: EMPTY_STRING,
  updatedAt: EMPTY_STRING
}

interface GetMemberProps {
  formInputs: FormInput[]
}

const GetMember: React.FC<GetMemberProps> = ({ formInputs }: GetMemberProps) => {
  const emptyMemberDetailsFormInfo: MemberDetailsInfo = {
    orgId: '',
    memberId: ''
  }

  const emptyMemberDetails: MemberModel = DEFAULT_MEMBER_DETAILS

  const emptyFullMemberDetails: MemberDetailsModel = {
    memberDetails: emptyMemberDetails,
    bankDetails: [],
    blockchainDetails: [],
    billPayDetails: []
  }

  const [memberCardDataInput, setMemberCardDataInput] = useState<MemberCardInput>({
    info: memberCardInfoInput,
    bank: memberCardBankInput,
    blockchain: memberCardBlockchainInput,
    billPay: memberCardBillPayInput
  })

  const [memberDetailsFormInfo, setMemberDetailsFormInfo] = useState<MemberDetailsInfo>(emptyMemberDetailsFormInfo)
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const [memberFullDetails, setFullMemberDetails] = useState<MemberDetailsModel>(emptyFullMemberDetails)

  const handleGetMemberbyMemIdRequest = async () => {
    try {
      const response = await adminGetMemberByMemId(memberDetailsFormInfo.memberId, memberDetailsFormInfo.orgId)
      if (response) {
        alertNotification(
          `Member ${memberDetailsFormInfo.memberId}'s details have been fetched successfully`,
          'success'
        )
        setFullMemberDetails(response.data)
      }
      setIsDataFetched(true)
      setMemberCardDataInput(memberCardDataInput)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  const updateMemberDetailsCardInput = () => {
    const { memberDetails } = memberFullDetails
    // update the member details here
    memberCardInfoInput.forEach((input) => {
      const { formId } = input
      const value = (memberDetails as unknown as Record<string, string>)[formId] || EMPTY_STRING
      if (formId === 'firstName' || formId === 'lastName') {
        input.formValue = value !== EMPTY_STRING ? value : NULL_STRING
      } else {
        input.formValue = value
      }
    })
    const orgIdElement = memberCardInfoInput.find((element) => element.formId === 'orgId')
    if (orgIdElement) {
      orgIdElement.formValue = memberDetailsFormInfo.orgId
    }
  }

  const updateMemberBankDetailsCardInput = () => {
    const { bankDetails } = memberFullDetails

    const bankDataArrLen = bankDetails.length

    const updatedMemberCardBankInput = []
    for (let i = 0; i < bankDataArrLen; i++) {
      updatedMemberCardBankInput.push([
        {
          formTitle: 'Bank Details ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Bank Details ID'
        },
        {
          formTitle: 'Country',
          formId: 'country',
          formType: FormType.input,
          formValue: 'Country'
        },
        {
          formTitle: 'Bank Details Name',
          formId: 'name',
          formType: FormType.input,
          formValue: 'Bank Details Name'
        },
        {
          formTitle: 'Account Holder Name',
          formId: 'accHolderName',
          formType: FormType.input,
          formValue: 'Account Holder Name'
        },
        {
          formTitle: 'Routing Number',
          formId: 'routingNum',
          formType: FormType.input,
          formValue: 'routing Number'
        },
        {
          formTitle: 'Account Number',
          formId: 'accountNum',
          formType: FormType.input,
          formValue: 'Account Number'
        },
        {
          formTitle: 'ACH Code',
          formId: 'achCode',
          formType: FormType.input,
          formValue: 'ACH Code'
        },
        {
          formTitle: 'SWIFT/BIC Code',
          formId: 'swiftBicCode',
          formType: FormType.input,
          formValue: 'SWIFT/BIC Code'
        },
        {
          formTitle: 'Primary Bank',
          formId: 'isPrimary',
          formType: FormType.input,
          formValue: 'Primary Bank'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Created At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Created At'
        }
      ])
    }

    updatedMemberCardBankInput.map((input, index) => {
      input.forEach((bank: any) => {
        const { formId } = bank
        const matchingBankDetail = (bankDetails[index] as unknown as Record<string, string>) || EMPTY_STRING
        if (matchingBankDetail) {
          bank.formValue = matchingBankDetail[formId] !== null ? String(matchingBankDetail[formId]) : NULL_STRING
        }
      })
    })
    memberCardDataInput.bank = updatedMemberCardBankInput
  }

  const updateMemberBlockchainDetailsCardInput = () => {
    const { blockchainDetails } = memberFullDetails

    const blockchainDataArrLen = blockchainDetails.length

    const updatedMemberCardBlockchainInput = []
    for (let i = 0; i < blockchainDataArrLen; i++) {
      updatedMemberCardBlockchainInput.push([
        {
          formTitle: 'Blockchain Details ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Blockchain Details ID'
        },
        {
          formTitle: 'Blockchain Details Name',
          formId: 'name',
          formType: FormType.input,
          formValue: 'Blockchain Details Name'
        },
        {
          formTitle: 'Address',
          formId: 'address',
          formType: FormType.input,
          formValue: 'Address'
        },
        {
          formTitle: 'Chain',
          formId: 'chain',
          formType: FormType.input,
          formValue: 'Chain'
        },
        {
          formTitle: 'Type',
          formId: 'type',
          formType: FormType.input,
          formValue: 'Type'
        },
        {
          formTitle: 'External Memo',
          formId: 'externalMemo',
          formType: FormType.input,
          formValue: 'External Memo'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Created At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Updated At'
        }
      ])
    }

    updatedMemberCardBlockchainInput.map((input, index) => {
      input.forEach((bc: any) => {
        const { formId } = bc
        const matchingBlockchainDetail = (blockchainDetails[index] as unknown as Record<string, string>) || EMPTY_STRING
        if (matchingBlockchainDetail) {
          bc.formValue = matchingBlockchainDetail[formId] !== null ? matchingBlockchainDetail[formId] : NULL_STRING
        }
      })
    })
    memberCardDataInput.blockchain = updatedMemberCardBlockchainInput
  }

  const updateMemberBillPayDetailsCardInput = () => {
    const { billPayDetails } = memberFullDetails

    const billPayDataArrLen = billPayDetails.length

    const updatedMemberCardBillPayInput = []
    for (let i = 0; i < billPayDataArrLen; i++) {
      updatedMemberCardBillPayInput.push([
        {
          formTitle: 'Billpay ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'ID'
        },
        {
          formTitle: 'Payee Name',
          formId: 'payeeName',
          formType: FormType.input,
          formValue: 'Payee Name'
        },
        {
          formTitle: 'Payee Code',
          formId: 'payeeCode',
          formType: FormType.input,
          formValue: 'Payee Code'
        },
        {
          formTitle: 'Payee Account Number',
          formId: 'payeeAccountNumber',
          formType: FormType.input,
          formValue: 'Payee Account Number'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Createad At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Updated At'
        }
      ])
    }

    updatedMemberCardBillPayInput.map((input, index) => {
      input.forEach((bank: any) => {
        const { formId } = bank
        const matchingBankDetail = (billPayDetails[index] as unknown as Record<string, string>) || EMPTY_STRING
        if (matchingBankDetail) {
          bank.formValue = matchingBankDetail[formId] !== null ? matchingBankDetail[formId] : NULL_STRING
        }
      })
    })
    memberCardDataInput.billPay = updatedMemberCardBillPayInput
  }

  useEffect(() => {
    updateMemberDetailsCardInput()
    updateMemberBankDetailsCardInput()
    updateMemberBlockchainDetailsCardInput()
    updateMemberBillPayDetailsCardInput()
  }, [memberFullDetails])

  return (
    <Box>
      <CrudActionContext.Provider value={handleGetMemberbyMemIdRequest}>
        {!isDataFetched && (
          <CrudActionCard
            crudType={CrudTypes.Get}
            formInputs={formInputs}
            setInfo={setMemberDetailsFormInfo}
            info={memberDetailsFormInfo}
          />
        )}
        {isDataFetched && (
          <>
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <MemberCard
              entityId={`Member ID: ${memberFullDetails.memberDetails.id.toString()}`}
              entityCardInput={memberCardDataInput}
              headers={memberCardHeaderInput}
            />
          </>
        )}
      </CrudActionContext.Provider>
    </Box>
  )
}

export default GetMember
