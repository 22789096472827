import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { CreateOrgInput } from '../types'

export const orgActionCardCrud = {
  create: { isPresent: true, isDropdown: false },
  get: { isPresent: true, isDropdown: false },
  update: { isPresent: false, isDropdown: false },
  delete: { isPresent: false, isDropdown: false }
}

const orgInfoInput: FormInput[] = [
  {
    formTitle: 'Organization ID',
    formId: 'orgId',
    formType: FormType.input,
    formValue: 'Organization ID'
  },
  {
    formTitle: 'Organization Name',
    formId: 'orgName',
    formType: FormType.input,
    formValue: 'Organization Name'
  },
  {
    formTitle: 'Email',
    formId: 'email',
    formType: FormType.input,
    formValue: 'Email'
  },
  {
    formTitle: 'Topic ARN',
    formId: 'topicARN',
    formType: FormType.input,
    formValue: 'Topic ARN'
  }
]

const orgAddressInput: FormInput[] = [
  {
    formTitle: 'Address',
    formId: 'address',
    formType: FormType.input,
    formValue: 'Address'
  },
  {
    formTitle: 'Postal Code',
    formId: 'postalCode',
    formType: FormType.input,
    formValue: 'Postal Code'
  },
  {
    formTitle: 'Country',
    formId: 'countryCode',
    formType: FormType.input,
    formValue: 'Country'
  },
  {
    formTitle: 'City',
    formId: 'city',
    formType: FormType.input,
    formValue: 'City'
  },
  {
    formTitle: 'State/Province',
    formId: 'stateProvince',
    formType: FormType.input,
    formValue: 'State/Province'
  }
]

export const createOrganizationInput: CreateOrgInput = {
  orgInfoInput: orgInfoInput,
  orgAddressInput: orgAddressInput
}

export const getOrgByEmail: FormInput[] = [
  {
    formTitle: 'Email',
    formId: 'email',
    formType: FormType.input,
    formValue: 'Email'
  }
]
