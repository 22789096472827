import { LoadingButton } from '@mui/lab'
import { Alert, Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import { adminCreateOrder } from 'src/common/api/apiOrders'
import FormGroup, { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { ValidationError, isBlockchainAddressValid } from 'src/common/helpers'
import { ChainName } from 'src/common/static'
import { alertNotification } from 'src/ui'
import { CreateOrderInput, OrderInfo } from './types'

interface CreateOrderProps {
  entityInput: CreateOrderInput
}

const ApiCreateOrder: React.FC<CreateOrderProps> = ({ entityInput }: CreateOrderProps) => {
  const entityInfo: FormInput[] = entityInput.orderInfoInput
  const [loading, setLoading] = useState<boolean>(false)
  const [formKey, setFormKey] = useState<number>(0)

  const emptyOrderInfo: OrderInfo = {
    memberId: '',
    firstName: '',
    lastName: '',
    fiatAmt: '',
    fiatCurrency: '',
    cryptoAmount: '',
    chain: ChainName.ETHEREUM,
    address: '',
    asset: '',
    paymentMethod: '',
    fxRate: '',
    bankingDetailsId: ''
  }

  const validateBlockchainAddress = () => {
    const { address, chain } = orderInfo
    if (address && !isBlockchainAddressValid(address, chain)) {
      throw new ValidationError('Invalid blockchain address')
    }
  }

  const [orderInfo, setOrderInfo] = useState<OrderInfo>(emptyOrderInfo)

  const handleCreateOrderDetailsRequest = async () => {
    try {
      setLoading(true)
      validateBlockchainAddress()
      const response = await adminCreateOrder({
        memberId: orderInfo.memberId,
        firstName: orderInfo.firstName,
        lastName: orderInfo.lastName,
        fiatAmount: parseFloat(orderInfo.fiatAmt),
        fiatCurrency: orderInfo.fiatCurrency,
        cryptoAmount: parseFloat(orderInfo.cryptoAmount),
        chain: orderInfo.chain,
        address: orderInfo.address,
        asset: orderInfo.asset,
        paymentMethod: orderInfo.paymentMethod,
        fxRate: parseFloat(orderInfo.fxRate),
        bankingDetailsId: parseInt(orderInfo.bankingDetailsId),
        attestation: true,
        fxRateTimestamp: new Date().toDateString()
      })
      if (response) {
        alertNotification(`Order has been created successfully`, 'success')
        resetOrderState()
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Order not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  const resetOrderState = () => {
    setOrderInfo(emptyOrderInfo)
    setFormKey((prevKey) => prevKey + 1)
  }

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <Grid container spacing={2} marginBottom={10}>
        <Grid item md={6}>
          <FormGroup
            key={formKey}
            header="Organization Information"
            formInputs={entityInfo}
            setInfo={setOrderInfo}
            info={orderInfo}
          />
        </Grid>
      </Grid>
      <LoadingButton variant="contained" onClick={handleCreateOrderDetailsRequest} loading={loading} fullWidth>
        Create Order
      </LoadingButton>
    </Box>
  )
}

export default ApiCreateOrder
