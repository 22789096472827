import * as React from 'react'
import { useState } from 'react'
import { DataGrid, GridRowSelectionModel, GridRowsProp, GridToolbar } from '@mui/x-data-grid'
import { adminExportOrderData, OrderDetailsModel } from '../../common/api/apiOrders'
import { OrderStatus } from '../../common/static'
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { alertNotification, Container } from '../../ui'
import {
  columnGroupingModel,
  orderDetailsColumns,
  transactionsDetailsColumns,
  transferDetailsColumns
} from './Table/orderDetailsStructure'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const ExportOrders: React.FC = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100
  })
  const [data, setData] = React.useState<OrderDetailsModel[]>([])
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [loading, setLoading] = React.useState(false)
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
  const [orderType, setOrderType] = useState(OrderStatus.INITIATED as string)
  const [rowLen, setRowLen] = useState(0)
  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs())
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs())

  const handleOrderTypeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setOrderType(event.target.value)
  }

  const handleSearch = async () => {
    await fetchOrderData()
  }

  const fetchOrderData = async () => {
    setLoading(true)
    try {
      // start and end date cannot be null
      if (startDate && endDate) {
        const response = await adminExportOrderData(
          orderType,
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD')
        )
        setData(response.data.data)
        setRowLen(response.data.totalCount)
        setRows(response.data.data)
        alertNotification(
          `Total ${response.data.totalCount} orders from ${startDate.format('YYYY-MM-DD')} to ${endDate.format(
            'YYYY-MM-DD'
          )} fetched successfully!`,
          'success'
        )
      }
    } catch (error) {
      alertNotification('Error fetching orders')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth={2000}>
      <Box>
        <Typography marginBottom={2} variant="h5" fontWeight="semi-bold">
          {'Export Orders'}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>
              Select Start Date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate}
                onChange={(newStartDate) => setStartDate(newStartDate)}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>
              Select End Date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={endDate}
                onChange={(newEndDate) => setEndDate(newEndDate)}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>
              Select Order Type:
            </Typography>
            <Select value={orderType} onChange={handleOrderTypeChange} style={{ marginBottom: 10, minWidth: 150 }}>
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              columns={[...orderDetailsColumns, ...transferDetailsColumns, ...transactionsDetailsColumns]}
              {...data}
              rows={rows}
              autoHeight
              pagination
              checkboxSelection
              paginationModel={paginationModel}
              rowCount={rowLen}
              onPaginationModelChange={setPaginationModel}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel)
              }}
              rowSelectionModel={rowSelectionModel}
              loading={loading}
              columnGroupingModel={columnGroupingModel}
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }} // Hide the print button
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ExportOrders
