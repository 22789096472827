import { createContext, useContext } from 'react'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'

export const CrudActionContext = createContext<((crudType: CrudTypes, dropDownType?: string) => void) | undefined>(
  undefined
)

export function useCrudActionContext() {
  const onButtonClick = useContext(CrudActionContext)

  if (onButtonClick === undefined) {
    throw new Error('useCrudActionContext must be used with a provided value with onButtonClick')
  }
  return onButtonClick
}

export const dropDownChangeContext = createContext<(() => void) | undefined>(undefined)

export function useDropDownChangeContext() {
  const onDropDownChange = useContext(dropDownChangeContext)

  if (onDropDownChange === undefined) {
    throw new Error('useDropDownChangeContext must be used with a provided value with onDropDownChange')
  }
  return onDropDownChange
}
