import { Box, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { ApiKYCInfo } from 'src/pages/ApiKYC/ApiKYC'
import CrudButtons from '../CrudButtons'
import { CrudTypes } from '../CrudButtons/constants/CrudTypes'
import FormGroup from '../FormGroup'
import { FormInput } from '../FormGroup/FormGroup'
import { ApiKYBInfo } from 'src/pages/ApiKYB/ApiKYB'

interface KYActionCardProps {
  headers: string
  formInput: FormInput[]
  setInfo: Dispatch<SetStateAction<ApiKYCInfo>> | Dispatch<SetStateAction<ApiKYBInfo>>
  info: ApiKYCInfo | ApiKYBInfo
}
const KYActionCard: React.FC<KYActionCardProps> = ({ headers, formInput, setInfo, info }: KYActionCardProps) => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        width: '70%',
        backgroundColor: 'white',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' // Add shadow here
      }}
    >
      <Typography marginBottom={2} variant="h6">
        {headers}
      </Typography>
      <FormGroup formInputs={formInput} setInfo={setInfo} info={info} />
      <CrudButtons crudType={CrudTypes.Update} />
    </Box>
  )
}

export default KYActionCard
