import * as React from 'react'
import { useState } from 'react'
import { DataGrid, GridRowSelectionModel, GridRowsProp, GridToolbar } from '@mui/x-data-grid'
import { adminGetAppOrderByStatus, OrderDetailsModel } from '../../common/api/apiOrders'
import { ORDER_SORT, OrderStatus } from '../../common/static'
import { Box, Divider, MenuItem, Select, Stack, Typography } from '@mui/material'
import { alertNotification } from '../../ui'
import {
  columnGroupingModel,
  orderDetailsColumns,
  transactionsDetailsColumns,
  transferDetailsColumns
} from './Table/appOrderStructure'
import * as S from './styles'

const AppOrder: React.FC = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100
  })
  const [data, setData] = React.useState<OrderDetailsModel[]>([])
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [loading, setLoading] = React.useState(false)
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
  const [orderType, setOrderType] = useState(OrderStatus.INITIATED as string)
  const [sortBy, setSortBy] = useState(ORDER_SORT.NEWEST as string)
  const [rowLen, setRowLen] = useState(0)

  const handleOrderTypeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setOrderType(event.target.value)
  }
  const handleSortByChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSortBy(event.target.value)
  }

  React.useEffect(() => {
    let active = true

    // TODO: update this
    const fetchOrderData = async () => {
      setLoading(true)
      try {
        const response = await adminGetAppOrderByStatus(
          orderType,
          sortBy,
          paginationModel.page + 1,
          paginationModel.pageSize
        )
        setData(response.data.data)
        setRowLen(response.data.totalCount)
        if (active) {
          setRows(response.data.data)
        }
      } catch (error) {
        alertNotification('Error fetching orders')
      } finally {
        setLoading(false)
      }
    }

    fetchOrderData()

    return () => {
      active = false
    }
  }, [orderType, paginationModel.page, sortBy])

  return (
    <S.StyledContainer
      maxWidth="xl"
      fixed
      sx={{
        p: 2,
        borderRadius: 1,
        width: '70%',
        backgroundColor: 'white',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h5" fontWeight={600}>
          Grapes App Orders
        </Typography>
        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
      </Stack>
      <Box style={{ minHeight: '100%', width: '100%' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: '100%',
            width: '100%'
          }}
        >
          <div style={{ marginRight: 10 }}>
            <Typography variant="subtitle1" gutterBottom>
              Select Order Type:
            </Typography>
            <Select value={orderType} onChange={handleOrderTypeChange} style={{ marginBottom: 10 }}>
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              Select Sort By:
            </Typography>
            <Select value={sortBy} onChange={handleSortByChange} style={{ marginBottom: 10 }}>
              {Object.values(ORDER_SORT).map((sortBy) => (
                <MenuItem key={sortBy} value={sortBy}>
                  {sortBy}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Box>
        <DataGrid
          columns={[...orderDetailsColumns, ...transferDetailsColumns, ...transactionsDetailsColumns]}
          {...data}
          rows={rows}
          autoHeight
          pagination
          checkboxSelection
          paginationModel={paginationModel}
          rowCount={rowLen}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          loading={loading}
          columnGroupingModel={columnGroupingModel}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </S.StyledContainer>
  )
}

export default AppOrder
