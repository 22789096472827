import httpClient from 'src/common/axios/config'
import { UpdateUserKybDTO, UpdateUserKycByAdminDTO } from './dto'
import { BankDetails, ContactDetails, ContactModel, UserModel } from './models'

export const getUserDetails = (email: string) => httpClient.get<UserModel>(`/users/admin/user?userEmail=${email}`)

export const getUserBankDetails = (bankDetailsId: string) =>
  httpClient.get<BankDetails>(`/users/admin/user/bankDetails?bankDetailsId=${bankDetailsId}`)

export const getUserContactsDetails = (userId: string | undefined) =>
  httpClient.get<ContactModel[]>(`/contacts/admin/contacts?userId=${userId}`)

export const getContactBankDetails = (contactId: string) =>
  httpClient.get<BankDetails[]>(`/contacts/admin/bankDetails?contactId=${contactId}`)

export const getContactDetails = (contactId: string) =>
  httpClient.get<ContactDetails>(`/contacts/admin/contact?contactId=${contactId}`)

export const updateUserKYB = (params: UpdateUserKybDTO) => httpClient.put('users/admin/user/kyb', params)

export const updateUserKYCByAdmin = (params: UpdateUserKycByAdminDTO) => httpClient.put('users/admin/user/kyc', params)
