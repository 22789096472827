import httpClient from 'src/common/axios/config'
import {
  AdminCreateMemberBankDetailsDTO,
  AdminCreateMemberBillpayDetailsDTO,
  AdminCreateMemberBlockchainDetailsDTO,
  AdminCreateMemberDTO,
  AdminCreateOrganizationDTO,
  AdminDeleteMemberDTO,
  AdminUpdateMemberDTO,
  AdminUpdateMemberKybDTO,
  AdminUpdateMemberKycDTO,
  AdminUpdateOrganizationDTO
} from './dto'
import { MemberDetailsModel, MemberModel, OrganizationDetailsModel, OrganizationModel } from './models'

// TODO: update the return type
export const adminCreateOrganization = (params: AdminCreateOrganizationDTO) =>
  httpClient.post<OrganizationModel>('/organizations', params)

export const adminUpdateMemberKybStatus = (params: AdminUpdateMemberKybDTO) =>
  httpClient.put<OrganizationModel>(`/organizations/member/kyb`, params)

export const adminUpdateMemberKyCId = (params: AdminUpdateMemberKycDTO) =>
  httpClient.put<OrganizationModel>(`/organizations/member/kyc`, params)

export const adminDeleteMember = (params: AdminDeleteMemberDTO) =>
  httpClient.delete<OrganizationModel>(`/organizations/member`, { params })

export const adminDeleteMemberBankDetails = (memberId: string, bankingDetailsId: string) =>
  httpClient.delete<OrganizationModel>(
    `/organizations/member/bankDetails?memberId=${memberId}&bankingDetailsId=${bankingDetailsId}`
  )

export const adminDeleteBlockchainDetails = (memberId: string, blockchainDetailsId: string) =>
  httpClient.delete<OrganizationModel>(
    `/organizations/member/blockchainDetails?memberId=${memberId}&blockchainDetailsId=${blockchainDetailsId}`
  )

export const adminDeleteMemberBillPayDetails = (memberId: string, billPayDetailsId: string) =>
  httpClient.delete<OrganizationModel>(
    `/organizations/member/billPayDetails?memberId=${memberId}&billPayDetailsId=${billPayDetailsId}`
  )

export const adminCreateMember = (params: AdminCreateMemberDTO) =>
  httpClient.post<MemberModel>(`/organizations/member`, params)

export const adminCreateMemberBillpayDetails = (params: AdminCreateMemberBillpayDetailsDTO) =>
  httpClient.post<OrganizationModel>(`/organizations/member/billPayDetails`, params)

export const adminCreateMemberBlockchainDetails = (params: AdminCreateMemberBlockchainDetailsDTO) =>
  httpClient.post<OrganizationModel>(`/organizations/member/blockchainDetails`, params)

export const adminCreateMemberBankDetails = (params: AdminCreateMemberBankDetailsDTO) =>
  httpClient.post<OrganizationModel>(`/organizations/member/bankDetails`, params)

export const adminGetOrganizationByEmail = (email: string) =>
  httpClient.get<OrganizationDetailsModel | string>(`/organizations?email=${email}`)

export const adminGetMemberByMemId = (memberId: string, orgId: string) =>
  httpClient.get<MemberDetailsModel>(`/organizations/member?orgId=${orgId}&memberId=${memberId}`)

export const adminGetAllMember = (orgId: string) =>
  httpClient.get<MemberDetailsModel[]>(`/organizations/member/all?orgId=${orgId}`)

export const adminUpdateOrganization = (params: AdminUpdateOrganizationDTO) =>
  httpClient.put<OrganizationModel>('/organizations', params)

export const adminUpdateMember = (params: AdminUpdateMemberDTO) =>
  httpClient.put<MemberModel>('/organizations/member', params)

export const adminGetAllOrganizations = () => httpClient.get<OrganizationModel[]>(`/organizations/all`)
