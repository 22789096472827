import { Box, Button, Divider, Stack, TextField, Typography, Menu, MenuItem, Alert } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { useState } from 'react'
import { getUserDetails, createCustodialWallet } from 'src/common/api'
import { UserModel } from 'src/common/api/users/models'
import { alertNotification, Container } from 'src/ui'
import { ChainName } from 'src/common/static'

const CustodialWallet: React.FC = () => {
  const [values, setValues] = useState({
    email: '',
    address: '',
    name: 'Grapes Wallet',
    externalId: ''
  })
  const [user, setUser] = useState<UserModel | null>(null)
  const [loading, setLoading] = useState(false)
  const [chain, setChain] = useState<ChainName>(ChainName.ETHEREUM)
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const clearOrderState = () => {
    setUser(null)
    setLoading(true)
  }

  const chooseChain = (chain: ChainName) => {
    setChain(chain)
    handleCloseChainMenu()
  }

  const fetchUser = async () => {
    try {
      clearOrderState()
      const response = await getUserDetails(values.email)
      if (response.data) {
        setUser(response.data)
      }
      setLoading(false)
    } catch {
      alertNotification('User Not Found')
      setLoading(false)
    }
  }

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      if (!values.externalId) {
        throw new Error('External Custodian Wallet ID required.')
      }
      await createCustodialWallet({
        userId: user?.id as string,
        address: values.address,
        chain: chain as string,
        externalId: values.externalId,
        name: values.name
      })
      setSuccessMessage(`Custodial wallet added successfully!`)
      setUser(null)
      setSubmitting(false)
    } catch {
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValues({
      ...values,
      [event.target.name]: newValue
    })
  }

  const [anchorElChain, setAnchorElChain] = useState<null | HTMLElement>(null)

  const handleOpenChainMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElChain(event.currentTarget)
  }

  const handleCloseChainMenu = () => {
    setAnchorElChain(null)
  }

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="email" placeholder="User Email" onChange={handleChange} value={values.email} />
          <Box>
            <Button variant="contained" onClick={fetchUser} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {user && (
          <>
            <Divider />
            <Typography variant="h6">Add custodial wallet</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>Address</Typography>
              <TextField value={values.address} onChange={handleChange} name="address" placeholder="Address" />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>Chain</Typography>
              <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenChainMenu} size="small">
                {chain}
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>Wallet name</Typography>
              <TextField value={values.name} onChange={handleChange} name="name" placeholder="Wallet name" />
            </Stack>
            <Alert severity="warning">
              Please enter the Internal ID generated by Balance. You will find this embedded within the URL on the
              Balance dashboard when you click on the new wallet. For example,
              https://yaas-staging.balancecustody.ca/wallets/
              <span>
                <strong>195</strong>
              </span>
              /edit.
            </Alert>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>External Custodian ID</Typography>
              <TextField
                value={values.externalId}
                onChange={handleChange}
                name="externalId"
                placeholder="Balance Wallet ID"
              />
            </Stack>
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
              Add custodial wallet
            </LoadingButton>
          </>
        )}
        <Menu
          sx={{ mt: '7px' }}
          id="menu-chain-name"
          anchorEl={anchorElChain}
          keepMounted
          open={Boolean(anchorElChain)}
          onClose={handleCloseChainMenu}
        >
          {Object.values(ChainName).map((chain) => (
            <MenuItem key={chain} onClick={() => chooseChain(chain)} disabled={chain === ChainName.ALGORAND}>
              <Typography>{chain}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </Container>
  )
}

export default CustodialWallet
