import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useAuth } from 'src/common/context'
import * as S from './styles'
import { alertNotification, Container } from 'src/ui'
import TotalHoldingsOverview from './TotalHoldingsOverview'
import { fetchOrderAnalytics, fetchWalletAnalytics, OrderAnalytics, WalletAnalytics } from '../../common/api'
import WalletCard from './WalletCard'
import AssetHoldingsOverview from './AssetHoldingsOverview'
import { isSdcProject } from '../../common/helpers/isSdcProject'
import FireblocksHoldingsOverview from './FireblocksHoldingsOverview'

const AnalyticsDashboard: React.FC = () => {
  const { user } = useAuth()
  const [fetching, setFetching] = useState(true)
  const [orderAnalytics, setOrderAnalytics] = useState<OrderAnalytics[]>([])
  const [walletAnalytics, setWalletAnalytics] = useState<WalletAnalytics[]>([])

  const getWalletAnalytics = async () => {
    try {
      const response = await fetchWalletAnalytics()
      setWalletAnalytics(response.data)
      setFetching(false)
    } catch (err) {
      alertNotification('Fetching wallet balances failed. Please contact support.', 'error')
      setFetching(false)
    }
  }

  const getOrderAnalytics = async () => {
    try {
      const response = await fetchOrderAnalytics()
      setOrderAnalytics(response.data)
      setFetching(false)
    } catch (err) {
      alertNotification('Fetching withdrawals and deposits failed. Please contact support.', 'error')
      setFetching(false)
    }
  }

  useEffect(() => {
    getWalletAnalytics()
    getOrderAnalytics()
  }, [])

  return (
    <S.DashboardContainer notification={false}>
      <S.LeftSection>
        <S.SummaryContainer>
          {fetching && (
            <Box display="flex" justifyContent="center">
              <CircularProgress disableShrink />
            </Box>
          )}
          {!fetching && (
            <>
              <Container maxWidth="100%" paperPadding={30}>
                <TotalHoldingsOverview firstName={user?.given_name} walletAnalytics={walletAnalytics} />
              </Container>
              <Container maxWidth="100%" paperPadding={30}>
                <FireblocksHoldingsOverview />
              </Container>
              <Container maxWidth="100%" paperPadding={30}>
                <Typography variant="h5" fontWeight={500}>
                  {isSdcProject() ? `SDC` : `Grapes`} Admin Wallet Balances
                </Typography>
                {walletAnalytics.map((wallet) => (
                  <WalletCard key={wallet.address} wallet={wallet} />
                ))}
              </Container>
              <Container maxWidth="100%" paperPadding={30}>
                <Typography variant="h5" fontWeight={500}>
                  Order Analytics
                </Typography>
                {orderAnalytics.map((data) => (
                  <AssetHoldingsOverview key={data.asset} orderAnalytics={data} />
                ))}
              </Container>
            </>
          )}
        </S.SummaryContainer>
      </S.LeftSection>
    </S.DashboardContainer>
  )
}

export default AnalyticsDashboard
