import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { ValidationError } from 'ajv'
import React, { useEffect, useState } from 'react'
import {
  adminUpdateOrganization,
  AdminUpdateOrganizationDTO,
  BankDetailsModel,
  BillPayDetailsModel,
  BlockchainDetailsModel,
  OrganizationModel
} from 'src/common/api/organizations'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import EntityCardGroup, { CardInput } from 'src/common/components/EntityCardGroup/EntityCardGroup'
import { alertNotification } from 'src/ui'
import { ReactComponent as EditIcon } from '../../../common/assets/EditIcon.svg'
import { OrgCardHeaderInput, OrgCardInput } from '../types'

interface OrgCardProps {
  entityId: string
  entityCardInput: OrgCardInput
  headers: OrgCardHeaderInput
  onButtonClick?: (crudType: CrudTypes, dropDownType?: string) => void
}

interface UpdateOrgState {
  info: OrganizationModel
}

type DetailsModel = OrganizationModel | BankDetailsModel | BlockchainDetailsModel | BillPayDetailsModel

const OrgCard: React.FC<OrgCardProps> = ({ entityId, entityCardInput, headers }: OrgCardProps) => {
  const orgInputInfo: CardInput[] = entityCardInput.info
  const bankCardDetails: CardInput[][] = entityCardInput.bank
  const blockchainCardDetails: CardInput[][] = entityCardInput.blockchain
  const billPayCardDetails: CardInput[][] = entityCardInput.billPay
  const orgHeader: string = headers.infoHeader
  const bankHeader: string = headers.bankHeader
  const blockchainHeader: string = headers.blockchainHeader
  const billPayHeader: string = headers.billPayHeader

  const convertFormInput = (model: DetailsModel, form: CardInput[]) => {
    form.forEach((data) => {
      const { formId, formValue } = data
      const requiredFields: string[] = ['id', 'orgId', 'email', 'orgName']
      if (formId && Object.prototype.hasOwnProperty.call(model, formId) && requiredFields.includes(formId)) {
        ;(model as DetailsModel)[formId as keyof DetailsModel] = String(formValue)
      }
    })
  }

  const organizationModel: OrganizationModel = {
    id: '',
    orgName: '',
    email: '',
    address: '',
    postalCode: '',
    countryCode: '',
    city: '',
    stateProvince: '',
    topicARN: '',
    createdAt: '',
    updatedAt: ''
  }

  const bankDetailsModel = bankCardDetails.map(() => ({
    id: 0,
    country: '',
    name: '',
    routingNum: '',
    institutionNum: '',
    transitNum: '',
    accountNum: '',
    accHolderName: '',
    isPrimary: true,
    achCode: '',
    swiftBicCode: ''
  }))

  const billPayDetailsModel = billPayCardDetails.map(() => ({
    id: 0,
    payeeName: '',
    payeeCode: '',
    payeeAccountNumber: ''
  }))

  const blockchainDetailsModel = blockchainCardDetails.map(() => ({
    id: 0,
    name: '',
    chain: '',
    address: '',
    externalMemo: ''
  }))

  const [orgInfo, setOrgInfo] = useState<OrganizationModel>(organizationModel)

  const [orgBank, setOrgBank] = useState<BankDetailsModel[]>(bankDetailsModel)
  const [orgBillPay, setOrgBillPay] = useState<BillPayDetailsModel[]>(billPayDetailsModel)
  const [orgBlockchain, setOrgBlockchain] = useState<BlockchainDetailsModel[]>(blockchainDetailsModel)

  const [edit, setEdit] = useState<boolean>(false)

  const emptyUpdateOrgState: UpdateOrgState = {
    info: organizationModel
  }

  const [orgState, setOrgState] = useState<UpdateOrgState>(emptyUpdateOrgState)

  const [submitting, setSubmitting] = useState(false)

  const removeEmptyStringProperties = (orgState: UpdateOrgState): AdminUpdateOrganizationDTO => {
    const res: AdminUpdateOrganizationDTO = {
      orgId: orgState.info.id,
      email: orgState.info.email,
      orgName: orgState.info.orgName
    }
    for (const key in orgState.info) {
      if (orgState.info[key as keyof OrganizationModel] !== '') {
        res[key as keyof AdminUpdateOrganizationDTO] = orgState.info[key as keyof OrganizationModel]
      }
    }
    return res
  }

  const handleUpdateOrgDetailsRequest = async () => {
    try {
      const updatedInfo: AdminUpdateOrganizationDTO = removeEmptyStringProperties(orgState)
      setSubmitting(true)
      const response = await adminUpdateOrganization(updatedInfo)

      if (response) {
        alertNotification(`Organization has been updated successfully`, 'success')
        // resetMemberState()
      }
      setSubmitting(false)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
      setSubmitting(false)
    }
  }

  const toggleEdit = () => {
    setEdit(!edit)
  }

  useEffect(() => {
    convertFormInput(organizationModel, orgInputInfo)
    setOrgInfo(organizationModel)
    bankCardDetails.map((bankCardDetail, index) => convertFormInput(bankDetailsModel[index], bankCardDetail))
    setOrgBank(bankDetailsModel)
    blockchainCardDetails.map((blockchainCardDetail, index) =>
      convertFormInput(blockchainDetailsModel[index], blockchainCardDetail)
    )
    setOrgBlockchain(blockchainDetailsModel)
    billPayCardDetails.map((billPayCardDetail, index) =>
      convertFormInput(billPayDetailsModel[index], billPayCardDetail)
    )
    setOrgBillPay(billPayDetailsModel)
  }, [edit])

  useEffect(() => {
    setOrgState({
      info: orgInfo
    })
  }, [orgInfo])

  return (
    <Box sx={{ p: 2, border: '1px solid grey', borderRadius: 1, marginBottom: 2, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          {entityId}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={() => toggleEdit()}>
            <EditIcon />
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <EntityCardGroup
            header={orgHeader}
            cardInputs={orgInputInfo}
            isEdit={edit}
            setInfo={setOrgInfo}
            info={orgInfo}
          />
        </Grid>
        <Grid item md={6}>
          {bankCardDetails.map((bankDetail, index) => (
            <>
              <EntityCardGroup
                key={index}
                header={bankHeader}
                cardInputs={bankDetail}
                isEdit={edit}
                setInfo={setOrgBank}
                info={orgBank}
                index={index}
              />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            </>
          ))}
        </Grid>
        <Grid item md={6}>
          {blockchainCardDetails.map((blockchainDetail, index) => (
            <>
              <EntityCardGroup
                key={index}
                header={blockchainHeader}
                cardInputs={blockchainDetail}
                isEdit={edit}
                setInfo={setOrgBlockchain}
                info={orgBlockchain}
                index={index}
              />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            </>
          ))}
        </Grid>
        <Grid item md={6}>
          {billPayCardDetails.map((billPayDetail, index) => (
            <>
              <EntityCardGroup
                key={index}
                header={billPayHeader}
                cardInputs={billPayDetail}
                isEdit={edit}
                setInfo={setOrgBillPay}
                info={orgBillPay}
                index={index}
              />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            </>
          ))}
        </Grid>
      </Grid>
      {edit && (
        <LoadingButton loading={submitting} variant="contained" onClick={handleUpdateOrgDetailsRequest} fullWidth>
          Update Organization
        </LoadingButton>
      )}
    </Box>
  )
}

export default OrgCard
