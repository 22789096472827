import { Box, Typography } from '@mui/material'
import React from 'react'
import CrudButtons from '../CrudButtons'
import { CrudTypes } from '../CrudButtons/constants/CrudTypes'
import { DropDownTypes } from '../dropDown/constants/DropDownTypes'
import { isSdcProject } from '../../helpers/isSdcProject'

interface ActionCardProps {
  crud: {
    create: { isPresent: boolean; isDropdown: boolean }
    get: { isPresent: boolean; isDropdown: boolean }
  }
}

const OrganizationActionCard: React.FC<ActionCardProps> = ({ crud }: ActionCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        gap: '24px'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h4" fontWeight={600}>
          {`${isSdcProject() ? 'SDC' : 'Grapes'} Organizations`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
        {crud.create.isPresent &&
          (crud.create.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Create} dropdownType={DropDownTypes.Create} />
          ) : (
            <CrudButtons crudType={CrudTypes.Create} />
          ))}
        {crud.get.isPresent &&
          (crud.get.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Get} dropdownType={DropDownTypes.Get} />
          ) : (
            <CrudButtons crudType={CrudTypes.Get} />
          ))}
      </Box>
    </Box>
  )
}

export default OrganizationActionCard
