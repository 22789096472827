import { ExpandMore } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Divider,
  InputBaseComponentProps,
  Link,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { ChainAsset, ChainName, EXPLORER, OrderStatus, PaymentMethod } from 'src/common/static'
import { alertNotification, Container, SummaryTable } from 'src/ui'
import NumberFormat from 'react-number-format'
import { formatDate, formatNumber, formatTime, trimId } from 'src/common/helpers'
import {
  BankDetails,
  ContactDetails,
  getContactDetails,
  getOrder,
  getUserBankDetails,
  OrderDetails,
  updateAdminSendOrder,
  UserModel
} from 'src/common/api'
import LoadingButton from '@mui/lab/LoadingButton'

const UpdateSend: React.FC = () => {
  const [order, setOrder] = useState<OrderDetails | null>(null)
  const [values, setValues] = useState({
    orderId: '',
    transferReferenceId: '',
    transactionReferenceId: '',
    cryptoAmount: '',
    fiatAmount: ''
  })

  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setIsLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [statuses, setStatuses] = useState({
    overall: 'INITIATED',
    transfer: 'INITIATED',
    transaction: 'INITIATED'
  })

  const [recipient, setRecipient] = useState<ContactDetails | null>(null)
  const [recipientBankDetails, setRecipientBankDetails] = useState<BankDetails | null>(null)
  const [user, setUser] = useState<UserModel | null>(null)

  const [asset, setAsset] = useState(ChainAsset.USDC)

  const [anchorElStatus, setAnchorElStatus] = useState<null | HTMLElement>(null)
  const [anchorElTransferStatus, setAnchorElTransferStatus] = useState<null | HTMLElement>(null)
  const [anchorElTransactionStatus, setAnchorElTransactionStatus] = useState<null | HTMLElement>(null)
  const [anchorElAsset, setAnchorElAsset] = useState<null | HTMLElement>(null)

  const chooseStatus = (type: string, status: OrderStatus) => {
    setStatuses({ ...statuses, [type]: status })
    if (type === 'overall') handleCloseStatusMenu()
    if (type === 'transfer') handleCloseTransferStatusMenu()
    if (type === 'transaction') handleCloseTransactionStatusMenu()
  }

  const chooseAsset = (asset: ChainAsset) => {
    setAsset(asset)
    handleCloseAssetMenu()
  }

  const clearOrderState = () => {
    setOrder(null)
    setIsLoading(true)
    setRecipient(null)
    setRecipientBankDetails(null)
    setUser(null)
  }

  const fetchOrder = async () => {
    try {
      clearOrderState()
      const response = await getOrder(values.orderId)
      if (response.data) {
        setOrder(response.data)
        setStatuses({
          overall: response.data.status,
          transfer: response.data.transfers[0].status,
          transaction: response.data.transactions[0].status
        })
        setValues({
          ...values,
          transferReferenceId: response.data.transfers[0].referenceId,
          cryptoAmount: response.data.cryptoAmount,
          transactionReferenceId: response.data.transactions[0].referenceId,
          fiatAmount: response.data.fiatAmount
        })
        setAsset(ChainAsset[response.data.transactions[0].asset])
      }
      const contactId = response.data.contactId
      const bankingDetailsId = response.data.bankingDetailsId
      if (contactId && bankingDetailsId) {
        await fetchRecipient(contactId)
        await fetchRecipientBankDetails(bankingDetailsId)
      }
      setIsLoading(false)
    } catch {
      alertNotification('Order Not Found')
      setIsLoading(false)
    }
  }

  const fetchRecipient = async (contactId: string) => {
    try {
      const contactDetails = await getContactDetails(contactId)
      const recipient = contactDetails.data
      setRecipient(recipient)
    } catch {
      alertNotification('Contact Not Found')
    }
  }

  const fetchRecipientBankDetails = async (bankingDetailsId: string) => {
    try {
      const userBankDetails = await getUserBankDetails(bankingDetailsId)
      setRecipientBankDetails(userBankDetails.data)
      userBankDetails.data.user && setUser(userBankDetails.data.user)
    } catch {
      alertNotification('User Bank Details Not Found')
    }
  }

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      const response = await updateAdminSendOrder({
        orderId: values.orderId,
        orderStatus: statuses.overall,
        transferStatus: statuses.transfer,
        transferReferenceId: values.transferReferenceId,
        asset: asset,
        cryptoAmount: Number(values.cryptoAmount),
        fiatAmount: Number(values.fiatAmount),
        transactionReferenceId: values.transactionReferenceId,
        transactionStatus: statuses.transaction
      })
      setSuccessMessage(`Order ${response.data.id} updated successfully!`)
      setOrder(null)
      setSubmitting(false)
    } catch {
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValues({
      ...values,
      [event.target.name]: newValue
    })
  }

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget)
  }

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null)
  }

  const handleOpenTransferStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTransferStatus(event.currentTarget)
  }

  const handleCloseTransferStatusMenu = () => {
    setAnchorElTransferStatus(null)
  }

  const handleOpenTransactionStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTransactionStatus(event.currentTarget)
  }

  const handleCloseTransactionStatusMenu = () => {
    setAnchorElTransactionStatus(null)
  }

  const handleOpenAssetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAsset(event.currentTarget)
  }

  const handleCloseAssetMenu = () => {
    setAnchorElAsset(null)
  }

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <Typography variant="h5" fontWeight={600}>
          Update Send
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField
            name="orderId"
            placeholder="Order Number"
            onChange={handleChange}
            value={values.orderId}
            InputProps={{
              inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
            }}
          />
          <Box>
            <Button variant="contained" onClick={fetchOrder} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {order && (
          <>
            <Alert severity="warning">
              Updating the Order Status to PROCESSED will trigger an email notification to the user.
            </Alert>
            <Divider />
            <Typography variant="h6">Order Details</Typography>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenStatusMenu} size="small">
                      {statuses.overall}
                    </Button>
                  )
                },
                {
                  key: 'Action',
                  value: `${order.transactions[0].asset} ${order.type}`
                },
                {
                  key: 'Payment Method',
                  value: `${order.paymentMethod}`
                },
                {
                  key: 'Date & Time (UTC)',
                  value: `${formatDate(order.createdAt)} ${formatTime(order.createdAt)}`
                },
                {
                  key: 'Wallet',
                  value: (
                    <Link
                      component="a"
                      href={
                        order.transactions[0].chain != ChainName.STELLAR
                          ? `${EXPLORER[order.transactions[0].chain]}/address/${order.transactions[0].address}`
                          : `${EXPLORER[order.transactions[0].chain]}/account/${order.transactions[0].address}`
                      }
                      target="_blank"
                      rel="noopener"
                    >
                      {trimId(order.transactions[0].address)}
                    </Link>
                  )
                },
                {
                  key: 'Chain',
                  value: order.transactions[0].chain
                },
                {
                  key: `Amount (${order.transactions[0].asset}/${order.transfers[0].fiatCurrency})`,
                  value: `${formatNumber(order.cryptoAmount)} ${order.transactions[0].asset} / $${formatNumber(
                    order.fiatAmount
                  )} ${order.fiatCurrency}`
                },
                {
                  key: 'FX Rate',
                  value: String(order.fxRate)
                },
                {
                  key: 'FX Rate Timestamp',
                  value: `${formatDate(order.fxRateTimestamp)} ${formatTime(order.fxRateTimestamp)}`
                },
                {
                  key: 'Order Fee',
                  value: '$0.00'
                }
              ]}
            />
            <Divider />
            <Typography variant="h6">Payee Details</Typography>
            <Alert severity="info">User/Recipient Details. This section will show N/A for E-Transfers.</Alert>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Name',
                  value: recipient
                    ? `${recipient.firstName} ${recipient.lastName}`
                    : user
                    ? `${user?.firstName} ${user?.lastName}`
                    : 'N/A'
                },
                {
                  key: 'Email',
                  value: recipient?.email || user?.email || 'N/A'
                },
                {
                  key: 'Company Title',
                  value: recipient?.companyTitle || 'N/A'
                },
                {
                  key: 'Address',
                  value: recipient?.address || user?.address || 'N/A'
                },
                {
                  key: 'City',
                  value: recipient?.city || user?.city || 'N/A'
                },
                {
                  key: 'State/Province',
                  value: recipient?.stateProvince || user?.stateProvince || 'N/A'
                },
                {
                  key: 'Postal Code',
                  value: recipient?.postalCode || user?.postalCode || 'N/A'
                },
                {
                  key: 'Country Code',
                  value: recipient?.countryCode || user?.countryCode || 'N/A'
                }
              ]}
            />
            <Divider />
            <Typography variant="h6">Payee Bank Details</Typography>
            <Alert severity="info">User/Recipient Bank Details. This section will show N/A for E-Transfers.</Alert>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Account Holder Name',
                  value: recipientBankDetails?.accHolderName || 'N/A'
                },
                {
                  key: 'Bank Country',
                  value: recipientBankDetails?.country || 'N/A'
                },
                {
                  key: `Account Number`,
                  value: recipientBankDetails?.accountNum || 'N/A'
                },
                {
                  key: `Routing Number`,
                  value: recipientBankDetails?.routingNum || 'N/A'
                },
                {
                  key: `Ach Number`,
                  value: recipientBankDetails?.achCode || 'N/A'
                },
                {
                  key: `Swift Bic Code`,
                  value: recipientBankDetails?.swiftBicCode || 'N/A'
                }
              ]}
            />
            <Divider />
            <Typography variant="h6">Transfer Details</Typography>
            <Alert severity="info">Please set the Amount to 0.00 and Transfer ID to N/A for On-chain Transfers.</Alert>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: (
                    <Button
                      endIcon={<ExpandMore />}
                      color="primary"
                      onClick={handleOpenTransferStatusMenu}
                      size="small"
                    >
                      {statuses.transfer}
                    </Button>
                  )
                },
                {
                  key: 'Payment Method',
                  value: PaymentMethod[order.paymentMethod]
                },
                {
                  key: `Amount (${order.fiatCurrency})`,
                  value: (
                    <TextField
                      value={values.fiatAmount}
                      onChange={handleChange}
                      name="fiatAmount"
                      placeholder="0.00"
                      InputProps={{
                        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
                      }}
                    />
                  )
                },
                {
                  key: 'Transfer ID',
                  value: (
                    <TextField
                      value={values.transferReferenceId}
                      onChange={handleChange}
                      name="transferReferenceId"
                      placeholder="Transfer ID"
                    />
                  )
                }
              ]}
            />
            <Divider />
            <Typography variant="h6">Transaction Details</Typography>
            <Alert severity="info">
              If the blockchain transaction has been processed, please select CONFIRMED status.
            </Alert>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: (
                    <Button
                      endIcon={<ExpandMore />}
                      color="primary"
                      onClick={handleOpenTransactionStatusMenu}
                      size="small"
                    >
                      {statuses.transaction}
                    </Button>
                  )
                },
                {
                  key: `Amount (${order.transactions[0].asset})`,
                  value: (
                    <TextField
                      value={values.cryptoAmount}
                      onChange={handleChange}
                      name="cryptoAmount"
                      placeholder="0.00"
                      InputProps={{
                        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
                      }}
                    />
                  )
                },
                {
                  key: 'Asset',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenAssetMenu} size="small">
                      {asset}
                    </Button>
                  )
                },
                {
                  key: 'Transaction ID',
                  value: (
                    <TextField
                      value={values.transactionReferenceId}
                      onChange={handleChange}
                      name="transactionReferenceId"
                      placeholder="Transaction ID"
                    />
                  )
                }
              ]}
            />
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
              Update Order
            </LoadingButton>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-status-type"
              anchorEl={anchorElStatus}
              keepMounted
              open={Boolean(anchorElStatus)}
              onClose={handleCloseStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus('overall', status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-transfer-status-type"
              anchorEl={anchorElTransferStatus}
              keepMounted
              open={Boolean(anchorElTransferStatus)}
              onClose={handleCloseTransferStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus('transfer', status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-transaction-status-type"
              anchorEl={anchorElTransactionStatus}
              keepMounted
              open={Boolean(anchorElTransactionStatus)}
              onClose={handleCloseTransactionStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus('transaction', status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-asset"
              anchorEl={anchorElAsset}
              keepMounted
              open={Boolean(anchorElAsset)}
              onClose={handleCloseAssetMenu}
            >
              {Object.values(ChainAsset).map((asset) => (
                <MenuItem
                  key={asset}
                  onClick={() => chooseAsset(asset)}
                  disabled={asset === ChainAsset.QCAD && order.transactions[0].chain === ChainName.ALGORAND}
                >
                  <Typography>{asset}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Stack>
    </Container>
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<Record<string, unknown>>, CustomProps>((props, ref) => {
  const { onChange, name, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator={name !== 'orderId'}
      isNumericString
      allowNegative={false}
      decimalScale={name === 'orderId' ? 0 : 2}
      fixedDecimalScale
    />
  )
})

NumberFormatCustom.displayName = 'NumberFormatCustom'

export default UpdateSend
