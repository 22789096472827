import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'

export const kycFormInput: FormInput[] = [
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  },
  {
    formTitle: 'KYC ID',
    formId: 'kycId',
    formType: FormType.input,
    formValue: 'KYC ID'
  }
]
