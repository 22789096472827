import React, { useEffect, useState } from 'react'
import { BankInfo, BillPayInfo, BlockchainInfo } from '../../ApiMembers/types'
import FormGroup, { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { CreateOrgInput, OrgFullInfo } from '../types'
import { bankingDetails, billPayDetails, blockchainDetails } from '../../ApiMembers/Common/data'
import { Box, Grid } from '@mui/material'
import BankingDetailsForm from 'src/common/components/BankingDetailsForm'
import BillPayDetailsForm from 'src/common/components/BillPayDetailsForm'
import BlockchainDetailsForm from 'src/common/components/BlockchainDetailsForm'
import { adminCreateOrganization } from 'src/common/api/organizations'
import { alertNotification } from 'src/ui'
import { LoadingButton } from '@mui/lab'
import {
  validateBankDetails,
  validateBlockchainAddresses,
  validateOrgInformation,
  ValidationError
} from 'src/common/helpers'
import { ChainName } from 'src/common/static'

interface CreateOrgProps {
  entityInput: CreateOrgInput
}

interface CreateOrgState {
  info: OrgFullInfo
  banks: BankInfo[]
  billPays: BillPayInfo[]
  blockchains: BlockchainInfo[]
}

const CreateOrganization: React.FC<CreateOrgProps> = ({ entityInput }: CreateOrgProps) => {
  const entityInfo: FormInput[] = entityInput.orgInfoInput
  const entityAddress: FormInput[] = entityInput.orgAddressInput

  const emptyOrgInfo: OrgFullInfo = {
    orgId: '',
    orgName: '',
    email: '',
    address: '',
    postalCode: '',
    countryCode: '',
    city: '',
    stateProvince: '',
    topicARN: ''
  }

  const emptyOrgBank: BankInfo = {
    country: '',
    accHolderName: '',
    bankName: '',
    accountNum: '',
    name: '',
    institutionNum: '',
    transitNum: '',
    achCode: '',
    swiftBicCode: ''
  }
  const emptyOrgBillPay: BillPayInfo = {
    payeeName: '',
    payeeCode: '',
    payeeAccountNumber: ''
  }

  const emptyOrgBlockchain: BlockchainInfo = {
    name: '',
    chain: ChainName.ETHEREUM,
    address: '',
    externalMemo: ''
  }

  // store form information about member
  const [bankingDetailsArr, setBankingDetailsArr] = useState<FormInput[][]>([bankingDetails])
  const [billPayDetailsArr, setBillPayDetailsArr] = useState<FormInput[][]>([billPayDetails])
  const [blockchainDetailsArr, setBlockchainDetailsArr] = useState<FormInput[][]>([blockchainDetails])

  const [orgInfo, setOrgInfo] = useState<OrgFullInfo>(emptyOrgInfo)
  const [orgBank, setOrgBank] = useState<BankInfo[]>([emptyOrgBank])
  const [orgBillPay, setOrgBillPay] = useState<BillPayInfo[]>([emptyOrgBillPay])
  const [orgBlockchain, setOrgBlockchain] = useState<BlockchainInfo[]>([emptyOrgBlockchain])

  const [bankingFormKey, setBankingFormKey] = useState<number>(0)
  const [blockchainFormKey, setBlockchainFormKey] = useState<number>(0)
  const [billpayFormKey, setbillPayFormKey] = useState<number>(0)

  const emptyCreateOrgState: CreateOrgState = {
    info: emptyOrgInfo,
    banks: [],
    billPays: [],
    blockchains: []
  }

  const [orgState, setOrgState] = useState<CreateOrgState>(emptyCreateOrgState)

  const handleAddBank = (): void => {
    setBankingDetailsArr((prev) => [...prev, bankingDetails])
    setOrgBank((prev) => [...prev, emptyOrgBank])
  }

  const handleAddBillPay = (): void => {
    setBillPayDetailsArr((prev) => [...prev, billPayDetails])
    setOrgBillPay((prev) => [...prev, emptyOrgBillPay])
  }

  const handleAddBlockchain = (): void => {
    setBlockchainDetailsArr((prev) => [...prev, blockchainDetails])
    setOrgBlockchain((prev) => [...prev, emptyOrgBlockchain])
  }

  const [submitting, setSubmitting] = useState(false)

  const validate = () => {
    validateOrgInformation(orgState.info)
    blockchainDetails.length > 0 && validateBlockchainAddresses(orgState.blockchains)
    bankingDetails.length > 0 && validateBankDetails(orgState.banks)
  }

  const resetOrgState = () => {
    resetBlockchainFormState()
    resetBankFormState()
    resetBillpayState()
    setOrgInfo(emptyOrgInfo)
    setOrgBank([emptyOrgBank])
    setOrgBlockchain([emptyOrgBlockchain])
    setOrgBillPay([emptyOrgBillPay])
  }

  const resetBankFormState = () => {
    setOrgBank([emptyOrgBank])
    setBankingFormKey((prevKey) => prevKey + 1)
  }

  const resetBlockchainFormState = () => {
    setOrgBlockchain([emptyOrgBlockchain])
    setBlockchainFormKey((prevKey) => prevKey + 1)
  }

  const resetBillpayState = () => {
    setOrgBillPay([emptyOrgBillPay])
    setbillPayFormKey((prevKey) => prevKey + 1)
  }

  const handleRequest = async () => {
    try {
      validate()
      setSubmitting(true)
      const response = await adminCreateOrganization({
        orgId: orgInfo.orgId,
        orgName: orgInfo.orgName,
        email: orgInfo.email,
        address: orgInfo.address,
        postalCode: orgInfo.postalCode,
        countryCode: orgInfo.countryCode,
        city: orgInfo.city,
        stateProvince: orgInfo.stateProvince,
        createBankDetails: orgBank
          .map((b) => ({
            country: b.country,
            name: b.name,
            accHolderName: b.accHolderName,
            routingNum: `${b.institutionNum}-${b.transitNum}`,
            accountNum: b.accountNum,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
          .filter((b) => b.accountNum.length > 0),
        createBlockchainDetails: orgBlockchain.filter((b) => b.address.length > 0),
        createBillPayDetails: orgBillPay.filter((b) => b.payeeAccountNumber.length > 0),
        topicARN: orgInfo.topicARN
      })
      if (response) {
        alertNotification(`Organisation ${orgInfo.orgName} has been successfully created`, 'success')
        resetOrgState()
      }
      setSubmitting(false)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setOrgState({
      info: orgInfo,
      banks: orgBank,
      billPays: orgBillPay,
      blockchains: orgBlockchain
    })
  }, [orgBank, orgInfo, orgBillPay, orgBlockchain])

  return (
    <Box>
      <Grid container spacing={2} marginLeft={1} marginBottom={10}>
        <Grid item md={6}>
          <FormGroup header="Organization Information" formInputs={entityInfo} setInfo={setOrgInfo} info={orgInfo} />
        </Grid>
        {entityAddress && (
          <Grid item md={6}>
            <FormGroup header="Organization Address" formInputs={entityAddress} setInfo={setOrgInfo} info={orgInfo} />
          </Grid>
        )}
        <Grid item md={6}>
          <BankingDetailsForm
            key={bankingFormKey}
            bankingDetails={bankingDetailsArr}
            handleAddBank={handleAddBank}
            setBank={setOrgBank}
            info={orgBank}
          />
        </Grid>
        <Grid item md={6}>
          <BlockchainDetailsForm
            key={blockchainFormKey}
            blockchainDetails={blockchainDetailsArr}
            handleAddBlockchain={handleAddBlockchain}
            setBlockchain={setOrgBlockchain}
            info={orgBlockchain}
          />
        </Grid>
        <Grid item md={6}>
          <BillPayDetailsForm
            key={billpayFormKey}
            billPayDetails={billPayDetailsArr}
            handleAddBillPay={handleAddBillPay}
            setBillPay={setOrgBillPay}
            info={orgBillPay}
          />
        </Grid>
      </Grid>
      <LoadingButton variant="contained" onClick={handleRequest} loading={submitting} fullWidth>
        Create organization
      </LoadingButton>
    </Box>
  )
}

export default CreateOrganization
