import * as React from 'react'
import { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid'
import InputBase from '@mui/material/InputBase'
import { alertNotification, Container } from '../../ui'
import {
  getWhitelistPaymentDetails,
  UpdateWhitelistBankDetailsPayload,
  updateWhitelistPaymentDetails,
  WhitelistBankDetails
} from '../../common/api/whitelistPayment'
import { Alert, Box, Button, Typography } from '@mui/material'

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID' },
  { field: 'whitelistPaymentId', headerName: 'Whitelist Payment ID' },
  { field: 'memberId', headerName: 'Member ID' },
  { field: 'createdAt', headerName: 'Created At' },
  { field: 'updatedAt', headerName: 'Updated At' },
  {
    field: 'note',
    headerName: 'Note',
    editable: true
  },
  { field: 'bankDetailsId', headerName: 'Bank Details Id' },
  { field: 'bankDetailsCountry', headerName: 'Bank Country' },
  { field: 'bankDetailsAccHolderName', headerName: 'Account Holder Name' },
  {
    field: 'bankDetailsAccountNum',
    headerName: 'Account Number',
    editable: true
  },
  {
    field: 'isBankDetailsVerified',
    headerName: 'Bank Verified',
    editable: true
  }
]

export default function WhitelistBank() {
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [memberId, setMemberId] = useState<string>('')
  const [whitelistBankDetails, setWhitelistBankDetails] = useState<WhitelistBankDetails[] | null>(null)
  const updateDatabase = async (data: {
    whitelistPaymentId: number
    memberId: string
    bankDetailsId: string
    isBankDetailsVerified: string | null
    note: string | null
  }) => {
    try {
      const payload: UpdateWhitelistBankDetailsPayload = {
        memberId: data.memberId,
        whitelistBankDetailId: data.whitelistPaymentId,
        bankDetailsId: data.bankDetailsId,
        verifyBankDetails: data.isBankDetailsVerified === '1',
        note: data.note ? data.note : ''
      }
      await updateWhitelistPaymentDetails(payload)
      alertNotification('Row updated successfully', 'success')
      return data
    } catch (error) {
      alertNotification('Error updating row')
    }
  }

  const clearWhitelistState = () => {
    setWhitelistBankDetails(null)
  }

  const fetchWhitelistDetails = async () => {
    try {
      clearWhitelistState()
      const response = await getWhitelistPaymentDetails(memberId)
      if (response.data) {
        setWhitelistBankDetails(response.data)
        alertNotification('Whitelist details fetched successfully', 'success')
      }
    } catch (error) {
      alertNotification('Error fetching whitelist details')
    }
  }

  useEffect(() => {
    if (whitelistBankDetails) {
      const newRows = whitelistBankDetails.map((item, index) => ({
        id: index + 1, // Assuming unique IDs are not available, generating sequential IDs
        whitelistPaymentId: item.id,
        memberId: memberId, // Append memberId to each row
        isBankDetailsVerified: item.isBankDetailsVerified !== null ? item.isBankDetailsVerified : 'N/A',
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        note: item.note !== null ? item.note : 'N/A',
        bankDetailsId: item.bankDetails ? item.bankDetails.id : 'N/A',
        bankDetailsCountry: item.bankDetails ? item.bankDetails.country : 'N/A',
        bankDetailsAccHolderName: item.bankDetails ? item.bankDetails.accHolderName : 'N/A',
        bankDetailsAccountNum: item.bankDetails ? item.bankDetails.accountNum : 'N/A'
      }))
      setRows(newRows)
    }
  }, [whitelistBankDetails, memberId])

  const handleProcessRowUpdateError = (error: Error) => {
    alertNotification('Error updating row:' + error)
  }

  return (
    <Container maxWidth={1500}>
      <Box mb={2}>
        <Typography variant="h5" gutterBottom>
          Whitelist Bank Details
        </Typography>
        <Alert severity="info" sx={{ marginBottom: '15px' }}>
          {'Double click on the cells to edit the data and enter to save. Bank Verified and Note are editable.'}
        </Alert>
        <Alert severity="info" sx={{ marginBottom: '15px' }}>
          {'Note: To whitelist - 1 = true, 0 = false, N/A = Not Available'}
        </Alert>
        <InputBase placeholder="Enter Member ID" value={memberId} onChange={(e) => setMemberId(e.target.value)} />
        <Button variant="contained" onClick={fetchWhitelistDetails} sx={{ ml: 1 }}>
          Fetch Details
        </Button>
      </Box>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          autoPageSize
          autoHeight
          rows={rows}
          columns={columns}
          processRowUpdate={(updatedRow) => updateDatabase(updatedRow)}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </div>
    </Container>
  )
}
