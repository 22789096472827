import * as React from 'react'
import { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid'
import InputBase from '@mui/material/InputBase'
import { alertNotification } from '../../ui'
import { Alert, Box, Button, Typography } from '@mui/material'
import { getOrganizationOrderLimits, OrderLimit, updateOrganizationOrderLimit } from '../../common/api'

const columns: GridColDef[] = [
  {
    field: 'orgId',
    headerName: 'Organization ID',
    minWidth: 250
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 200
  },
  { field: 'updatedAt', headerName: 'Updated At', minWidth: 200 },
  {
    field: 'dailyBankingLimit',
    headerName: 'Daily Banking Limit',
    minWidth: 150,
    editable: true
  },
  {
    field: 'weeklyBankingLimit',
    headerName: 'Weekly Banking Limit',
    minWidth: 150,
    editable: true
  }
]

export default function GetUpdateOrderLimits() {
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [orgId, setOrgId] = useState<string>('')
  const [orgOrderLimits, setOrgOrderLimits] = useState<OrderLimit | null>(null)

  const updateOrderLimit = async (data: { orgId: string; dailyBankingLimit: string; weeklyBankingLimit: string }) => {
    try {
      const payload = {
        orgId: orgId,
        dailyBankingLimit: parseInt(data.dailyBankingLimit),
        weeklyBankingLimit: parseInt(data.weeklyBankingLimit)
      }
      await updateOrganizationOrderLimit(payload)
      alertNotification('Row updated successfully', 'success')
      return data
    } catch (error) {
      alertNotification('Error updating row')
    }
  }

  const clearOrgLimitsState = () => {
    setOrgOrderLimits(null)
  }

  const fetchOrderLimits = async () => {
    try {
      clearOrgLimitsState()
      const response = await getOrganizationOrderLimits(orgId)
      if (response.data) {
        setOrgOrderLimits(response.data)
        alertNotification('Order Limits fetched successfully', 'success')
      }
    } catch (error) {
      alertNotification('Error fetching order limits')
    }
  }

  useEffect(() => {
    const newRows = [
      {
        id: 1,
        orgId: orgId,
        createdAt: orgOrderLimits?.createdAt ? orgOrderLimits.createdAt : 'N/A',
        updatedAt: orgOrderLimits?.updatedAt ? orgOrderLimits.updatedAt : 'N/A',
        dailyBankingLimit: orgOrderLimits?.dailyBankingLimit ? orgOrderLimits.dailyBankingLimit : 'N/A',
        weeklyBankingLimit: orgOrderLimits?.weeklyBankingLimit ? orgOrderLimits.weeklyBankingLimit : 'N/A'
      }
    ]
    setRows(newRows)
  }, [orgOrderLimits])

  const handleProcessRowUpdateError = (error: Error) => {
    alertNotification('Error updating row:' + error.message)
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" gutterBottom>
          Get/Update Organization Order Limits
        </Typography>
        <Alert severity="info" sx={{ marginBottom: '15px' }}>
          {'Double click on the cells to edit the data and enter to save. Only Daily Banking Limit and ' +
            'Weekly Banking Limit can be updated.'}
        </Alert>
        <InputBase
          fullWidth
          placeholder="Enter Organization ID"
          value={orgId}
          onChange={(e) => setOrgId(e.target.value)}
        />
        <Button variant="contained" onClick={fetchOrderLimits} sx={{ mt: 1 }}>
          Fetch Details
        </Button>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataGrid
          autoPageSize
          autoHeight
          rows={rows}
          columns={columns}
          processRowUpdate={(updatedRow) => updateOrderLimit(updatedRow)}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </div>
    </Box>
  )
}
