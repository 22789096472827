import { Box, Button } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { AddBankIcon } from 'src/common/assets'
import { BankInfo } from 'src/pages/ApiMembers/types'
import FormGroup from '../FormGroup'
import { FormInput } from '../FormGroup/FormGroup'

interface BankingDetailsFormProps {
  bankingDetails: FormInput[][]
  handleAddBank: () => void
  setBank: Dispatch<SetStateAction<BankInfo[]>>
  info: BankInfo[]
}

const BankingDetailsForm: React.FC<BankingDetailsFormProps> = ({
  bankingDetails,
  handleAddBank,
  setBank,
  info
}: BankingDetailsFormProps) => {
  return (
    <Box>
      {bankingDetails.map((bankingDetail, index) => (
        <FormGroup
          key={index}
          header={index === 0 ? 'Banking Details' : ''}
          formInputs={bankingDetail.filter((field) => !field.condition || field.condition(info[index]?.country))}
          setInfo={setBank}
          info={info}
          index={index}
        />
      ))}
      <Button variant="outlined" startIcon={<AddBankIcon />} onClick={handleAddBank} sx={{ fontWeight: '500', mt: 2 }}>
        Add another Bank Account
      </Button>
    </Box>
  )
}

export default BankingDetailsForm
