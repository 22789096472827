import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid'

export const orderDetailsColumns: GridColDef[] = [
  { field: 'id', headerName: 'Order Id', minWidth: 300 },
  { field: 'type', headerName: 'Type' },
  { field: 'organizationId', headerName: 'Organization Id', minWidth: 250 },
  { field: 'fiatAmount', headerName: 'Fiat Amount' },
  { field: 'fiatCurrency', headerName: 'Fiat Currency' },
  { field: 'cryptoAmount', headerName: 'Crypto Amount', minWidth: 150 },
  { field: 'asset', headerName: 'Asset', minWidth: 150 },
  { field: 'chain', headerName: 'Chain', minWidth: 150 },
  { field: 'fxRate', headerName: 'FX Rate' },
  { field: 'fxRateTimestamp', headerName: 'FX Rate Timestamp', minWidth: 250 },
  { field: 'status', headerName: 'Status' },
  { field: 'paymentMethod', headerName: 'Payment Method', minWidth: 150 },
  { field: 'createdAt', headerName: 'Created At', minWidth: 200 },
  { field: 'updatedAt', headerName: 'Updated At', minWidth: 200 },
  { field: 'ipAddress', headerName: 'IP Address' },
  { field: 'bankingDetailsId', headerName: 'Banking Details Id', minWidth: 300 },
  { field: 'billPayDetailsId', headerName: 'Bill Pay Details Id', minWidth: 300 },
  { field: 'remark', headerName: 'Remark' },
  { field: 'memberId', headerName: 'Member Id', minWidth: 300 }
]

export const transferDetailsColumns: GridColDef[] = [
  {
    field: 'transfers[0]?.id',
    valueGetter: (value, row) => row.transfers[0]?.id,
    headerName: 'Transfer Id',
    minWidth: 300
  },
  {
    field: 'transfers[0]?.amount',
    valueGetter: (value, row) => row.transfers[0]?.amount,
    headerName: 'Transfer Amount',
    minWidth: 150
  },
  {
    field: 'transfers[0]?.status',
    valueGetter: (value, row) => row.transfers[0]?.status,
    headerName: 'Transfer Status',
    minWidth: 200
  },
  {
    field: 'transfers[0]?.fiatCurrency',
    valueGetter: (value, row) => row.transfers[0]?.fiatCurrency,
    headerName: 'Transfer Fiat Currency',
    minWidth: 200
  },
  {
    field: 'transfers[0]?.referenceId',
    valueGetter: (value, row) => row.transfers[0]?.referenceId,
    headerName: 'Transfer Reference Id',
    minWidth: 200,
    editable: true
  },
  {
    field: 'transfers[0]?.createdAt',
    valueGetter: (value, row) => row.transfers[0]?.createdAt,
    headerName: 'Transfer Created At',
    minWidth: 200
  },
  {
    field: 'transfers[0]?.updatedAt',
    valueGetter: (value, row) => row.transfers[0]?.updatedAt,
    headerName: 'Transfer Updated At',
    minWidth: 200
  }
]

export const transactionsDetailsColumns: GridColDef[] = [
  {
    field: 'transactions[0]?.id',
    valueGetter: (value, row) => row.transactions[0]?.id,
    headerName: 'Transaction Id',
    minWidth: 300
  },
  {
    field: 'transactions[0]?.amount',
    valueGetter: (value, row) => row.transactions[0]?.amount,
    headerName: 'Transaction Amount',
    minWidth: 200
  },
  {
    field: 'transactions[0]?.status',
    valueGetter: (value, row) => row.transactions[0]?.status,
    headerName: 'Transaction Status',
    minWidth: 200
  },
  {
    field: 'transactions[0]?.address',
    valueGetter: (value, row) => row.transactions[0]?.address,
    headerName: 'Transaction Address',
    minWidth: 200
  },
  {
    field: 'transactions[0]?.chain',
    valueGetter: (value, row) => row.transactions[0]?.chain,
    headerName: 'Transaction Chain',
    minWidth: 200
  },
  {
    field: 'transactions[0]?.referenceId',
    valueGetter: (value, row) => row.transactions[0]?.referenceId,
    headerName: 'Transaction Reference Id',
    minWidth: 200
  },
  {
    field: 'transactions[0]?.createdAt',
    valueGetter: (value, row) => row.transactions[0]?.createdAt,
    headerName: 'Transaction Created At',
    minWidth: 200
  },
  {
    field: 'transactions[0]?.updatedAt',
    valueGetter: (value, row) => row.transactions[0]?.updatedAt,
    headerName: 'Transaction Updated At',
    minWidth: 200
  }
]

export const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Order Details',
    description: '',
    children: orderDetailsColumns.map((column) => ({ field: column.field }))
  },
  {
    groupId: 'Transfer Details',
    children: transferDetailsColumns.map((column) => ({ field: column.field }))
  },
  {
    groupId: 'Transaction Details',
    children: transactionsDetailsColumns.map((column) => ({ field: column.field }))
  }
]
