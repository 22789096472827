import { Box, Button } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { BlockchainIcon } from 'src/common/assets/BlockchainIcon'
import { BlockchainInfo } from 'src/pages/ApiMembers/types'
import FormGroup from '../FormGroup'
import { FormInput } from '../FormGroup/FormGroup'

interface BlockchainDetailsFormProps {
  blockchainDetails: FormInput[][]
  handleAddBlockchain: () => void
  setBlockchain: Dispatch<SetStateAction<BlockchainInfo[]>>
  info: BlockchainInfo[]
}

const BlockchainDetailsForm: React.FC<BlockchainDetailsFormProps> = ({
  blockchainDetails,
  handleAddBlockchain,
  setBlockchain,
  info
}: BlockchainDetailsFormProps) => {
  return (
    <Box>
      {blockchainDetails.map((blockchainDetail, index) => (
        <FormGroup
          key={index}
          header={index === 0 ? 'Blockchain Details' : ''}
          formInputs={blockchainDetail.filter((field) => !field.condition || field.condition(info[index]?.chain))}
          setInfo={setBlockchain}
          info={info}
          index={index}
        ></FormGroup>
      ))}
      <Button
        startIcon={<BlockchainIcon />}
        variant="outlined"
        sx={{ fontWeight: '500', mt: 2 }}
        onClick={handleAddBlockchain}
      >
        Add another Blockchain Account
      </Button>
    </Box>
  )
}

export default BlockchainDetailsForm
