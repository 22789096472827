/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  BankDetailsModel,
  BillPayDetailsModel,
  BlockchainDetailsModel,
  MemberDetailsModel,
  MemberModel,
  adminGetAllMember
} from 'src/common/api/organizations'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { CardInput } from 'src/common/components/EntityCardGroup/EntityCardGroup'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { ValidationError } from 'src/common/helpers'
import { alertNotification } from 'src/ui'
import { memberCardHeaderInput } from '../Common/formInputs'
import MemberCard from '../MemberCard'
import { CrudActionContext } from '../context'
import { MemberBasicInfo, MemberCardInput } from '../types'

// Constants for default values

const EMPTY_STRING = ''
const DEFAULT_MEMBER_DETAILS: MemberModel = {
  accountId: EMPTY_STRING,
  id: EMPTY_STRING,
  orgId: EMPTY_STRING,
  firstName: EMPTY_STRING,
  middleName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  email: EMPTY_STRING,
  businessName: EMPTY_STRING,
  address: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  countryCode: EMPTY_STRING,
  city: EMPTY_STRING,
  stateProvince: EMPTY_STRING,
  accountType: EMPTY_STRING,
  createdAt: EMPTY_STRING,
  updatedAt: EMPTY_STRING
}

interface GetMemberByOrgIdProps {
  formInputs: FormInput[]
}

const GetMemberByOrgId: React.FC<GetMemberByOrgIdProps> = ({ formInputs }: GetMemberByOrgIdProps) => {
  const emptyMemberDetailsFormInfo: MemberBasicInfo = {
    orgId: ''
  }

  const emptyMemberDetails: MemberModel = DEFAULT_MEMBER_DETAILS

  const emptyFullMemberDetails: MemberDetailsModel = {
    memberDetails: emptyMemberDetails,
    bankDetails: [],
    blockchainDetails: [],
    billPayDetails: []
  }

  const [memberCardDataInput, setMemberCardDataInput] = useState<MemberCardInput[]>([
    {
      info: [
        {
          formTitle: 'Member Id',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Member Id'
        },
        {
          formTitle: 'Org Id',
          formId: 'orgId',
          formType: FormType.input,
          formValue: 'Org Id'
        },
        {
          formTitle: 'Business Name',
          formId: 'businessName',
          formType: FormType.input,
          formValue: 'Business Name'
        },
        {
          formTitle: 'First Name',
          formId: 'firstName',
          formType: FormType.input,
          formValue: 'First Name'
        },
        {
          formTitle: 'Last Name',
          formId: 'lastName',
          formType: FormType.input,
          formValue: 'Last Name'
        },
        {
          formTitle: 'Address',
          formId: 'address',
          formType: FormType.input,
          formValue: 'Address'
        },
        {
          formTitle: 'City',
          formId: 'city',
          formType: FormType.input,
          formValue: 'Toronto'
        },
        {
          formTitle: 'State/Province',
          formId: 'stateProvince',
          formType: FormType.input,
          formValue: 'State/Province'
        },
        {
          formTitle: 'Country',
          formId: 'countryCode',
          formType: FormType.input,
          formValue: 'Country'
        },
        {
          formTitle: 'Postal Code',
          formId: 'postalCode',
          formType: FormType.input,
          formValue: 'Postal Code'
        },
        {
          formTitle: 'Account Type',
          formId: 'accountType',
          formType: FormType.input,
          formValue: 'Account Type'
        },
        {
          formTitle: 'Account ID',
          formId: 'accountId',
          formType: FormType.input,
          formValue: 'Account ID'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: '2023-11-03T21:51:55.865Z'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: '2023-11-03T21:51:55.865Z'
        }
      ],
      bank: [
        [
          {
            formTitle: 'Bank Details ID',
            formId: 'id',
            formType: FormType.input,
            formValue: 'Bank Details ID'
          },
          {
            formTitle: 'Country',
            formId: 'country',
            formType: FormType.input,
            formValue: 'Country'
          },
          {
            formTitle: 'Bank Details Name',
            formId: 'name',
            formType: FormType.input,
            formValue: 'Bank Details Name'
          },
          {
            formTitle: 'Account Holder Name',
            formId: 'accHolderName',
            formType: FormType.input,
            formValue: 'Account Holder Name'
          },
          {
            formTitle: 'Routing Number',
            formId: 'routingNum',
            formType: FormType.input,
            formValue: 'routing Number'
          },
          {
            formTitle: 'Account Number',
            formId: 'accountNum',
            formType: FormType.input,
            formValue: 'Account Number'
          },
          {
            formTitle: 'ACH Code',
            formId: 'achCode',
            formType: FormType.input,
            formValue: 'ACH Code'
          },
          {
            formTitle: 'SWIFT/BIC Code',
            formId: 'swiftBicCode',
            formType: FormType.input,
            formValue: 'SWIFT/BIC Code'
          },
          {
            formTitle: 'Primary Bank',
            formId: 'isPrimary',
            formType: FormType.input,
            formValue: 'Primary Bank'
          },
          {
            formTitle: 'Created At',
            formId: 'createdAt',
            formType: FormType.input,
            formValue: 'Created At'
          },
          {
            formTitle: 'Updated At',
            formId: 'updatedAt',
            formType: FormType.input,
            formValue: 'Created At'
          }
        ]
      ],
      blockchain: [
        [
          {
            formTitle: 'Blockchain Details ID',
            formId: 'id',
            formType: FormType.input,
            formValue: 'Blockchain Details ID'
          },
          {
            formTitle: 'Blockchain Details NAme',
            formId: 'name',
            formType: FormType.input,
            formValue: 'Blockchain Details Name'
          },
          {
            formTitle: 'Address',
            formId: 'address',
            formType: FormType.input,
            formValue: 'Address'
          },
          {
            formTitle: 'Chain',
            formId: 'chain',
            formType: FormType.input,
            formValue: 'Chain'
          },
          {
            formTitle: 'Type',
            formId: 'type',
            formType: FormType.input,
            formValue: 'Type'
          },
          {
            formTitle: 'External Memo',
            formId: 'externalMemo',
            formType: FormType.input,
            formValue: 'External Memo'
          },
          {
            formTitle: 'Created At',
            formId: 'createdAt',
            formType: FormType.input,
            formValue: 'Created At'
          },
          {
            formTitle: 'Updated At',
            formId: 'updatedAt',
            formType: FormType.input,
            formValue: 'Updated At'
          }
        ]
      ],
      billPay: [
        [
          {
            formTitle: 'Billpay ID',
            formId: 'id',
            formType: FormType.input,
            formValue: 'ID'
          },
          {
            formTitle: 'Payee Name',
            formId: 'payeeName',
            formType: FormType.input,
            formValue: 'Payee Name'
          },
          {
            formTitle: 'Payee Code',
            formId: 'payeeCode',
            formType: FormType.input,
            formValue: 'Payee Code'
          },
          {
            formTitle: 'Payee Account Number',
            formId: 'payeeAccountNumber',
            formType: FormType.input,
            formValue: 'Payee Account Number'
          },
          {
            formTitle: 'Created At',
            formId: 'createdAt',
            formType: FormType.input,
            formValue: 'Createad At'
          },
          {
            formTitle: 'Updated At',
            formId: 'updatedAt',
            formType: FormType.input,
            formValue: 'Updated At'
          }
        ]
      ]
    }
  ])

  const [memberDetailsFormInfo, setMemberDetailsFormInfo] = useState<MemberBasicInfo>(emptyMemberDetailsFormInfo)
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const [memberFullDetails, setFullMemberDetails] = useState<MemberDetailsModel[]>([emptyFullMemberDetails])

  const handleGetMemberbyOrgIdRequest = async () => {
    try {
      const response = await adminGetAllMember(memberDetailsFormInfo.orgId)
      if (response) {
        alertNotification(
          `Details of Members in Organization ${memberDetailsFormInfo.orgId} have been fetched successfully`,
          'success'
        )
        const responseArr = Object.values(response.data)
        setFullMemberDetails(responseArr)
      }
      setIsDataFetched(true)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  const updateMemberDetailsCardInput = (member: MemberDetailsModel, i: number) => {
    const { memberDetails } = member

    let updatedMemberCardDetailsInput = [
      {
        formTitle: 'Member Id',
        formId: 'id',
        formType: FormType.input,
        formValue: 'Member Id'
      },
      {
        formTitle: 'Org Id',
        formId: 'orgId',
        formType: FormType.input,
        formValue: 'Org Id'
      },
      {
        formTitle: 'Business Name',
        formId: 'businessName',
        formType: FormType.input,
        formValue: 'Business Name'
      },
      {
        formTitle: 'First Name',
        formId: 'firstName',
        formType: FormType.input,
        formValue: 'First Name'
      },
      {
        formTitle: 'Last Name',
        formId: 'lastName',
        formType: FormType.input,
        formValue: 'Last Name'
      },
      {
        formTitle: 'Address',
        formId: 'address',
        formType: FormType.input,
        formValue: 'Address'
      },
      {
        formTitle: 'City',
        formId: 'city',
        formType: FormType.input,
        formValue: 'Toronto'
      },
      {
        formTitle: 'State/Province',
        formId: 'stateProvince',
        formType: FormType.input,
        formValue: 'State/Province'
      },
      {
        formTitle: 'Country',
        formId: 'countryCode',
        formType: FormType.input,
        formValue: 'Country'
      },
      {
        formTitle: 'Postal Code',
        formId: 'postalCode',
        formType: FormType.input,
        formValue: 'Postal Code'
      },
      {
        formTitle: 'Account Type',
        formId: 'accountType',
        formType: FormType.input,
        formValue: 'Account Type'
      },
      {
        formTitle: 'Account ID',
        formId: 'accountId',
        formType: FormType.input,
        formValue: 'Account ID'
      },
      {
        formTitle: 'Created At',
        formId: 'createdAt',
        formType: FormType.input,
        formValue: '2023-11-03T21:51:55.865Z'
      },
      {
        formTitle: 'Updated At',
        formId: 'updatedAt',
        formType: FormType.input,
        formValue: '2023-11-03T21:51:55.865Z'
      }
    ]

    updatedMemberCardDetailsInput = updatedMemberCardDetailsInput.map((input) => {
      const formId = input.formId as keyof MemberModel

      if (formId in memberDetails) {
        const key: keyof MemberModel = formId
        const value: string = memberDetails[key] as string

        if (value !== undefined) {
          return {
            ...input,
            formValue: value
          }
        }
      }

      return input
    })

    const orgIdElement = updatedMemberCardDetailsInput.find((element) => element.formId === 'orgId')
    if (orgIdElement) {
      orgIdElement.formValue = memberDetailsFormInfo.orgId
    }
    setMemberCardDataInput((prev) =>
      prev.map((item, index) => (index === i ? { ...item, info: updatedMemberCardDetailsInput } : item))
    )
  }

  const updateMemberBankDetailsCardInput = (member: MemberDetailsModel, i: number) => {
    const { bankDetails } = member

    const bankDataArrLen = bankDetails.length

    let updatedMemberCardBankInput: CardInput[][] = []
    for (let i = 0; i < bankDataArrLen; i++) {
      updatedMemberCardBankInput.push([
        {
          formTitle: 'Bank Details ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Bank Details ID'
        },
        {
          formTitle: 'Country',
          formId: 'country',
          formType: FormType.input,
          formValue: 'Country'
        },
        {
          formTitle: 'Bank Details Name',
          formId: 'name',
          formType: FormType.input,
          formValue: 'Bank Details Name'
        },
        {
          formTitle: 'Account Holder Name',
          formId: 'accHolderName',
          formType: FormType.input,
          formValue: 'Account Holder Name'
        },
        {
          formTitle: 'Routing Number',
          formId: 'routingNum',
          formType: FormType.input,
          formValue: 'routing Number'
        },
        {
          formTitle: 'Account Number',
          formId: 'accountNum',
          formType: FormType.input,
          formValue: 'Account Number'
        },
        {
          formTitle: 'ACH Code',
          formId: 'achCode',
          formType: FormType.input,
          formValue: 'ACH Code'
        },
        {
          formTitle: 'SWIFT/BIC Code',
          formId: 'swiftBicCode',
          formType: FormType.input,
          formValue: 'SWIFT/BIC Code'
        },
        {
          formTitle: 'Primary Bank',
          formId: 'isPrimary',
          formType: FormType.input,
          formValue: 'Primary Bank'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Created At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Created At'
        }
      ])
    }

    updatedMemberCardBankInput = updatedMemberCardBankInput.map((inputArray, j) => {
      return inputArray.map((input) => {
        const formId = input.formId as keyof BankDetailsModel

        if (formId in bankDetails[j]) {
          const key: keyof BankDetailsModel = formId
          const value = bankDetails[j][key]

          if (value !== undefined) {
            return {
              ...input,
              formValue: value.toString()
            }
          }
        }

        return input
      })
    })

    setMemberCardDataInput((prev) =>
      prev.map((item, index) => (index === i ? { ...item, bank: updatedMemberCardBankInput } : item))
    )
  }

  const updateMemberBlockchainDetailsCardInput = (member: MemberDetailsModel, i: number) => {
    const { blockchainDetails } = member

    const blockchainDataArrLen = blockchainDetails.length

    let updatedMemberCardBlockchainInput: CardInput[][] = []
    for (let i = 0; i < blockchainDataArrLen; i++) {
      updatedMemberCardBlockchainInput.push([
        {
          formTitle: 'Blockchain Details ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Blockchain Details ID'
        },
        {
          formTitle: 'Blockchain Details Name',
          formId: 'name',
          formType: FormType.input,
          formValue: 'Blockchain Details Name'
        },
        {
          formTitle: 'Address',
          formId: 'address',
          formType: FormType.input,
          formValue: 'Address'
        },
        {
          formTitle: 'Chain',
          formId: 'chain',
          formType: FormType.input,
          formValue: 'Chain'
        },
        {
          formTitle: 'Type',
          formId: 'type',
          formType: FormType.input,
          formValue: 'Type'
        },
        {
          formTitle: 'External Memo',
          formId: 'externalMemo',
          formType: FormType.input,
          formValue: 'External Memo'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Created At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Updated At'
        }
      ])
    }

    updatedMemberCardBlockchainInput = updatedMemberCardBlockchainInput.map((inputArray, j) => {
      return inputArray.map((input) => {
        const formId = input.formId as keyof BlockchainDetailsModel

        if (formId in blockchainDetails[j]) {
          const key: keyof BlockchainDetailsModel = formId
          const value = blockchainDetails[j][key]

          if (value !== undefined) {
            return {
              ...input,
              formValue: value.toString()
            }
          }
        }

        return input
      })
    })

    setMemberCardDataInput((prev) =>
      prev.map((item, index) => (index === i ? { ...item, blockchain: updatedMemberCardBlockchainInput } : item))
    )
  }

  const updateMemberBillPayDetailsCardInput = (member: MemberDetailsModel, i: number) => {
    const { billPayDetails } = member

    let updatedMemberCardBillPayInput: CardInput[][] = []
    for (let i = 0; i < billPayDetails.length; i++) {
      updatedMemberCardBillPayInput.push([
        {
          formTitle: 'Billpay ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'ID'
        },
        {
          formTitle: 'Payee Name',
          formId: 'payeeName',
          formType: FormType.input,
          formValue: 'Payee Name'
        },
        {
          formTitle: 'Payee Code',
          formId: 'payeeCode',
          formType: FormType.input,
          formValue: 'Payee Code'
        },
        {
          formTitle: 'Payee Account Number',
          formId: 'payeeAccountNumber',
          formType: FormType.input,
          formValue: 'Payee Account Number'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Createad At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Updated At'
        }
      ])
    }

    updatedMemberCardBillPayInput = updatedMemberCardBillPayInput.map((inputArray, j) => {
      return inputArray.map((input) => {
        const formId = input.formId as keyof BillPayDetailsModel

        if (formId in billPayDetails[j]) {
          const key: keyof BillPayDetailsModel = formId
          const value = billPayDetails[j][key]

          if (value !== undefined) {
            return {
              ...input,
              formValue: value.toString()
            }
          }
        }

        return input
      })
    })

    setMemberCardDataInput((prev) =>
      prev.map((item, index) => (index === i ? { ...item, billPay: updatedMemberCardBillPayInput } : item))
    )
  }

  useEffect(() => {
    for (let i = 0; i < memberFullDetails.length - 1; i++) {
      setMemberCardDataInput((prev) => [
        ...prev,
        {
          info: [
            {
              formTitle: 'Member Id',
              formId: 'id',
              formType: FormType.input,
              formValue: 'Member Id'
            },
            {
              formTitle: 'Business Name',
              formId: 'businessName',
              formType: FormType.input,
              formValue: 'Stablecorp'
            },
            {
              formTitle: 'First Name',
              formId: 'firstName',
              formType: FormType.input,
              formValue: 'First Name'
            },
            {
              formTitle: 'Last Name',
              formId: 'lastName',
              formType: FormType.input,
              formValue: 'Last Name'
            },
            {
              formTitle: 'Address',
              formId: 'address',
              formType: FormType.input,
              formValue: 'Address'
            },
            {
              formTitle: 'City',
              formId: 'city',
              formType: FormType.input,
              formValue: 'Toronto'
            },
            {
              formTitle: 'State/Province',
              formId: 'stateProvince',
              formType: FormType.input,
              formValue: 'State/Province'
            },
            {
              formTitle: 'Country',
              formId: 'countryCode',
              formType: FormType.input,
              formValue: 'Country'
            },
            {
              formTitle: 'Postal Code',
              formId: 'postalCode',
              formType: FormType.input,
              formValue: 'Postal Code'
            },
            {
              formTitle: 'Account Type',
              formId: 'accountType',
              formType: FormType.input,
              formValue: 'Account Type'
            },
            {
              formTitle: 'Account ID',
              formId: 'accountId',
              formType: FormType.input,
              formValue: 'Account ID'
            },
            {
              formTitle: 'Created At',
              formId: 'createdAt',
              formType: FormType.input,
              formValue: '2023-11-03T21:51:55.865Z'
            },
            {
              formTitle: 'Updated At',
              formId: 'updatedAt',
              formType: FormType.input,
              formValue: '2023-11-03T21:51:55.865Z'
            }
          ],
          bank: [
            [
              {
                formTitle: 'Bank Details ID',
                formId: 'id',
                formType: FormType.input,
                formValue: 'Bank Details ID'
              },
              {
                formTitle: 'Country',
                formId: 'country',
                formType: FormType.input,
                formValue: 'Country'
              },
              {
                formTitle: 'Bank Details Name',
                formId: 'name',
                formType: FormType.input,
                formValue: 'Bank Details Name'
              },
              {
                formTitle: 'Account Holder Name',
                formId: 'accHolderName',
                formType: FormType.input,
                formValue: 'Account Holder Name'
              },
              {
                formTitle: 'Routing Number',
                formId: 'routingNum',
                formType: FormType.input,
                formValue: 'routing Number'
              },
              {
                formTitle: 'Account Number',
                formId: 'accountNum',
                formType: FormType.input,
                formValue: 'Account Number'
              },
              {
                formTitle: 'ACH Code',
                formId: 'achCode',
                formType: FormType.input,
                formValue: 'ACH Code'
              },
              {
                formTitle: 'SWIFT/BIC Code',
                formId: 'swiftBicCode',
                formType: FormType.input,
                formValue: 'SWIFT/BIC Code'
              },
              {
                formTitle: 'Primary Bank',
                formId: 'isPrimary',
                formType: FormType.input,
                formValue: 'Primary Bank'
              },
              {
                formTitle: 'Created At',
                formId: 'createdAt',
                formType: FormType.input,
                formValue: 'Created At'
              },
              {
                formTitle: 'Updated At',
                formId: 'updatedAt',
                formType: FormType.input,
                formValue: 'Created At'
              }
            ]
          ],
          blockchain: [
            [
              {
                formTitle: 'Blockchain Details ID',
                formId: 'id',
                formType: FormType.input,
                formValue: 'Blockchain Details ID'
              },
              {
                formTitle: 'Blockchain Details NAme',
                formId: 'name',
                formType: FormType.input,
                formValue: 'Blockchain Details Name'
              },
              {
                formTitle: 'Address',
                formId: 'address',
                formType: FormType.input,
                formValue: 'Address'
              },
              {
                formTitle: 'Chain',
                formId: 'chain',
                formType: FormType.input,
                formValue: 'Chain'
              },
              {
                formTitle: 'Type',
                formId: 'type',
                formType: FormType.input,
                formValue: 'Type'
              },
              {
                formTitle: 'External Memo',
                formId: 'externalMemo',
                formType: FormType.input,
                formValue: 'External Memo'
              },
              {
                formTitle: 'Created At',
                formId: 'createdAt',
                formType: FormType.input,
                formValue: 'Created At'
              },
              {
                formTitle: 'Updated At',
                formId: 'updatedAt',
                formType: FormType.input,
                formValue: 'Updated At'
              }
            ]
          ],
          billPay: [
            [
              {
                formTitle: 'Billpay ID',
                formId: 'id',
                formType: FormType.input,
                formValue: 'ID'
              },
              {
                formTitle: 'Payee Name',
                formId: 'payeeName',
                formType: FormType.input,
                formValue: 'Payee Name'
              },
              {
                formTitle: 'Payee Code',
                formId: 'payeeCode',
                formType: FormType.input,
                formValue: 'Payee Code'
              },
              {
                formTitle: 'Payee Account Number',
                formId: 'payeeAccountNumber',
                formType: FormType.input,
                formValue: 'Payee Account Number'
              },
              {
                formTitle: 'Created At',
                formId: 'createdAt',
                formType: FormType.input,
                formValue: 'Createad At'
              },
              {
                formTitle: 'Updated At',
                formId: 'updatedAt',
                formType: FormType.input,
                formValue: 'Updated At'
              }
            ]
          ]
        }
      ])
    }

    memberFullDetails.map((member: MemberDetailsModel, index: number) => {
      updateMemberDetailsCardInput(member, index)
      updateMemberBankDetailsCardInput(member, index)
      updateMemberBlockchainDetailsCardInput(member, index)
      updateMemberBillPayDetailsCardInput(member, index)
    })
  }, [memberFullDetails])

  return (
    <Box>
      <CrudActionContext.Provider value={handleGetMemberbyOrgIdRequest}>
        {!isDataFetched && (
          <CrudActionCard
            crudType={CrudTypes.Get}
            formInputs={formInputs}
            setInfo={setMemberDetailsFormInfo}
            info={memberDetailsFormInfo}
          />
        )}
        {isDataFetched && (
          <>
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            {memberCardDataInput.map((member, index) => (
              <MemberCard
                entityId={member.info[0].formValue.toString()}
                key={index}
                entityCardInput={member}
                headers={memberCardHeaderInput}
              />
            ))}
          </>
        )}
      </CrudActionContext.Provider>
    </Box>
  )
}

export default GetMemberByOrgId
