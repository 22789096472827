import React from 'react'
import { Dialog, DialogProps, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import * as S from './styles'

interface ModalProps extends DialogProps {
  title?: string
  padding?: number | string
}

const Modal: React.FC<ModalProps> = ({ title, children, sx, onClose, padding, ...props }: ModalProps) => {
  return (
    <Dialog {...props} sx={{ borderRadius: 24, ...sx }} PaperProps={{ variant: 'card' }} fullWidth scroll="body">
      <S.Container padding={padding}>
        {title && (
          <S.Head>
            <Typography variant="h6" fontWeight={600}>
              {title}
            </Typography>
            {onClose && (
              <IconButton aria-label="close" onClick={onClose as React.MouseEventHandler<HTMLButtonElement>}>
                <Close />
              </IconButton>
            )}
          </S.Head>
        )}
        {children}
      </S.Container>
    </Dialog>
  )
}

export default Modal
