import React from 'react'
import { Box } from '@mui/material'
import * as S from './styles'
import { Container } from 'src/ui'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-mui'
import useLogin, { initialValues, validate } from './Login.State'
import LoadingButton from '@mui/lab/LoadingButton'
import SetupMfa from '../Mfa/SetupMfa'

const Login: React.FC = () => {
  const { state, onSubmit } = useLogin()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box height="100vh" display="flex" alignItems="center">
              <Container
                title="Admin Login"
                error={state.error && 'Invalid email or password.'}
                marginTop="0"
                marginBottom="0"
              >
                <Box mb={3}>
                  <S.InputLabel>Email *</S.InputLabel>
                  <Field component={TextField} name="email" placeholder="Email" fullWidth />
                </Box>
                <Box mb={5}>
                  <S.InputLabel>Password *</S.InputLabel>
                  <Field component={TextField} name="password" type="password" placeholder="Password" fullWidth />
                </Box>
                <LoadingButton variant="contained" loading={isSubmitting} onClick={submitForm} fullWidth size="large">
                  Log In
                </LoadingButton>
              </Container>
            </Box>
          </Form>
        )}
      </Formik>
      {state.preferredMfa === 'NOMFA' && <SetupMfa open={state.preferredMfa === 'NOMFA'} />}
    </>
  )
}

export default Login
