import { Box } from '@mui/material'
import { useState } from 'react'
import KYActionCard from 'src/common/components/KYActionCard'
import { CrudActionContext } from '../ApiMembers/context'
import React from 'react'
import { kycFormInput } from './data'
import { alertNotification } from 'src/ui'
import { ValidationError } from 'ajv'
import { adminUpdateMemberKyCId } from 'src/common/api/organizations'

export interface ApiKYCInfo {
  kycId: string
  memberId: string
}

const ApiKYC: React.FC = () => {
  const emptyKYCInfo: ApiKYCInfo = {
    memberId: '',
    kycId: ''
  }

  const [kycInfo, setKycInfo] = useState<ApiKYCInfo>(emptyKYCInfo)

  const handleRequest = async () => {
    try {
      const response = await adminUpdateMemberKyCId({
        memberId: kycInfo.memberId,
        kycId: kycInfo.kycId
      })
      if (response) {
        alertNotification(`Member ${kycInfo.memberId}'s KYC has been updated successfully`, 'success')
        setKycInfo(emptyKYCInfo)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <Box bgcolor="#BC19FF" color="white" p={2} textAlign="center" fontSize="24px" fontWeight="bold">
        Note: API Operations
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={10}>
        <CrudActionContext.Provider value={handleRequest}>
          <KYActionCard headers="Update Member's KYC" formInput={kycFormInput} setInfo={setKycInfo} info={kycInfo} />
        </CrudActionContext.Provider>
      </Box>
    </Box>
  )
}

export default ApiKYC
