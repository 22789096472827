import { Box, styled } from '@mui/system'

interface DashboardContainerProps {
  notification: boolean
}

export const DashboardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'notification'
})<DashboardContainerProps>({
  display: 'flex',
  alignItems: 'stretch'
})

export const LeftSection = styled('div')({
  flex: '1 1 75%',
  flexGrow: 1,
  overflowY: 'auto'
})

export const SummaryContainer = styled('div')({
  maxWidth: '1000px',
  marginRight: 'auto',
  marginLeft: 'auto'
})
