import React, { useState } from 'react'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { DeleteMemberBankDetailsInfo } from './types'
import { adminDeleteMemberBankDetails } from 'src/common/api/organizations'
import { alertNotification } from 'src/ui'
import { CrudActionContext } from '../context'
import { Box } from '@mui/material'
import { ValidationError } from 'src/common/helpers'

interface DeleteMemberProps {
  formInputs: FormInput[]
}

const DeleteMemberBankDetails: React.FC<DeleteMemberProps> = ({ formInputs }: DeleteMemberProps) => {
  const emptyMemberBankDetailsInfo: DeleteMemberBankDetailsInfo = {
    memberId: '',
    bankingDetailsId: ''
  }

  const [deleteMemberBankDetails, setDeleteMemberBankDetails] =
    useState<DeleteMemberBankDetailsInfo>(emptyMemberBankDetailsInfo)

  const handleDeleteMemberBankDetailsRequest = async () => {
    try {
      const response = await adminDeleteMemberBankDetails(
        deleteMemberBankDetails.memberId,
        deleteMemberBankDetails.bankingDetailsId
      )
      if (response) {
        alertNotification(
          `Member ${deleteMemberBankDetails.memberId}'s bank details ${deleteMemberBankDetails.bankingDetailsId} has been deleted successfully`,
          'success'
        )
        setDeleteMemberBankDetails(emptyMemberBankDetailsInfo)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <CrudActionContext.Provider value={handleDeleteMemberBankDetailsRequest}>
        <CrudActionCard
          crudType={CrudTypes.Delete}
          formInputs={formInputs}
          setInfo={setDeleteMemberBankDetails}
          info={deleteMemberBankDetails}
        />
      </CrudActionContext.Provider>
    </Box>
  )
}

export default DeleteMemberBankDetails
