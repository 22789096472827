import { BankDetailsModel, NewBankModel } from '../api/contacts'
import { BankingCountry } from '../static'
import { SUPPORTED_ASSET } from '../../pages/AnalyticsDashboard/FireblocksHoldingsOverview/constants/supportedAssets'

export const hexToRgba = (hex: string, alpha: number): string => {
  let c = hex.substring(1).split('')
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]]
  }
  const rgb = Number('0x' + c.join(''))
  return 'rgba(' + [(rgb >> 16) & 255, (rgb >> 8) & 255, rgb & 255].join(',') + `,${alpha})`
}

export const formatNumber = (num: number | string, minFractionDigits?: number, maxFractionDigits?: number): string => {
  if (typeof num === 'string') {
    num = Number(num)
  }
  return num.toLocaleString('EG', {
    minimumFractionDigits: minFractionDigits || (maxFractionDigits ? undefined : 2),
    maximumFractionDigits: maxFractionDigits || (minFractionDigits ? undefined : 2)
  })
}

export const formatDate = (ISOdate: string): string => {
  const date = new Date(ISOdate)
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
}

export const formatTime = (ISOdate: string): string => {
  const date = new Date(ISOdate)
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
}

export const trimId = (address: string | null | undefined) =>
  address?.slice(0, 6).concat('...' + address.slice(address.length - 4, address.length)) || null

export const calculateFxRate = (fiatCurrency: string, asset: string, fiatAmount: number, cryptoAmount: number) => {
  if ((fiatCurrency === 'USD' && asset === 'USDC') || (fiatCurrency === 'CAD' && asset === 'QCAD')) {
    return 1.0
  } else if (fiatCurrency === 'USD' && asset === 'QCAD') {
    return cryptoAmount / fiatAmount
  } else if (fiatCurrency === 'CAD' && asset === 'USDC') {
    return fiatAmount / cryptoAmount
  }
}

export const checkValidCanadianBank = (bank: BankDetailsModel | NewBankModel) => {
  return (
    bank.country === BankingCountry.CAN &&
    (bank.name || bank.institutionNum || bank.transitNum || bank.accountNum || bank.accHolderName) &&
    (!bank.name || !bank.institutionNum || !bank.transitNum || !bank.accountNum || !bank.accHolderName)
  )
}

export const checkValidUsBank = (bank: BankDetailsModel | NewBankModel) => {
  return (
    bank.country === BankingCountry.US &&
    (bank.name || bank.achCode || bank.swiftBicCode || bank.accountNum || bank.accHolderName) &&
    (!bank.name || !bank.achCode || !bank.swiftBicCode || !bank.accountNum || !bank.accHolderName)
  )
}

export const getAssetName = (assetId: keyof typeof SUPPORTED_ASSET): string => {
  return SUPPORTED_ASSET[assetId] ? SUPPORTED_ASSET[assetId] : assetId
}
