export enum OrderStatus {
  NEW = 'NEW',
  INITIATED = 'INITIATED',
  PROCESSED = 'PROCESSED',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  INCORRECT_RECIPIENT = 'INCORRECT_RECIPIENT',
  PENDING_SWAP = 'PENDING_SWAP',
  RESOLVED = 'RESOLVED'
}

export enum TransactionStatus {
  INITIATED = 'INITIATED',
  PROCESSED = 'PROCESSED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  DECLINED = 'DECLINED'
}

export enum ORDER_SORT {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  HIGHEST = 'HIGHEST',
  LOWEST = 'LOWEST'
}
