import { Alert, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormInput } from 'src/common/components/CrudActionCard/CrudActionCard'
import FormGroup from 'src/common/components/FormGroup'
import { BlockchainInfo, MemberDetailsInfo } from '../types'
import { alertNotification } from 'src/ui'
import { LoadingButton } from '@mui/lab'
import BlockchainDetailsForm from 'src/common/components/BlockchainDetailsForm'
import { adminCreateMemberBlockchainDetails } from 'src/common/api/organizations'
import { blockchainDetails } from '../Common/data'
import { ValidationError, validateBlockchainAddresses } from 'src/common/helpers'
import { ChainName } from 'src/common/static'

interface CreateMemberBlockchainProps {
  entityData: FormInput[][]
}

interface CreateMemberBlockchainState {
  detailsInfo: MemberDetailsInfo
  blockchainDetails: BlockchainInfo[]
}

const CreateBlockchainDetails: React.FC<CreateMemberBlockchainProps> = ({
  entityData
}: CreateMemberBlockchainProps) => {
  const entityInfo: FormInput[] = entityData[0]
  const entityBlockchainDetails: FormInput[] = entityData[1]

  const emptyMemberDetailsInfo: MemberDetailsInfo = {
    orgId: '',
    memberId: ''
  }

  const emptyBlockchainDetails: BlockchainInfo = {
    name: '',
    chain: ChainName.ETHEREUM,
    address: '',
    externalMemo: ''
  }

  // store form information about member
  const [blockchainDetailsArr, setBlockchainDetailsArr] = useState<FormInput[][]>([entityBlockchainDetails])

  // store payload for create req
  const [memberDetailsInfo, setMemberDetailsInfo] = useState<MemberDetailsInfo>(emptyMemberDetailsInfo)
  const [memberBlockchain, setMemberBlockchain] = useState<BlockchainInfo[]>([emptyBlockchainDetails])

  const emptyCreateMemberBlockchainState: CreateMemberBlockchainState = {
    detailsInfo: emptyMemberDetailsInfo,
    blockchainDetails: [emptyBlockchainDetails]
  }

  const [memberState, setMemberState] = useState<CreateMemberBlockchainState>(emptyCreateMemberBlockchainState)
  const [loading, setLoading] = useState<boolean>(false)

  const handleAddBlockchain = (): void => {
    setBlockchainDetailsArr((prev) => [...prev, blockchainDetails])

    setMemberBlockchain((prev) => [...prev, emptyBlockchainDetails])
  }

  const handleCreateMemberBlockchainDetailsRequest = async () => {
    try {
      validateBlockchainAddresses(memberState.blockchainDetails)
      setLoading(true)
      const response = await adminCreateMemberBlockchainDetails({
        orgId: memberState.detailsInfo.orgId,
        memberId: memberState.detailsInfo.memberId,
        blockchainDetails: memberState.blockchainDetails
      })
      if (response) {
        alertNotification(
          `Member ${memberState.detailsInfo.memberId}'s blockchain details has been created successfully`,
          'success'
        )
        setMemberDetailsInfo(emptyMemberDetailsInfo)
        setMemberBlockchain([emptyBlockchainDetails])
        setBlockchainDetailsArr(blockchainDetailsArr)
        setLoading(false)
      }
      setFormKey((prevKey) => prevKey + 1)
    } catch (e) {
      setLoading(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }
  const [formKey, setFormKey] = useState<number>(0)

  useEffect(() => {
    setMemberState({
      detailsInfo: memberDetailsInfo,
      blockchainDetails: memberBlockchain
    })
  }, [memberDetailsInfo, memberBlockchain, blockchainDetailsArr])

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <Grid container spacing={2} marginBottom={10}>
        <Grid item md={6}>
          <FormGroup
            header="Member Information"
            formInputs={entityInfo}
            setInfo={setMemberDetailsInfo}
            info={memberDetailsInfo}
          />
        </Grid>
        <Grid item md={6}>
          <BlockchainDetailsForm
            key={formKey}
            blockchainDetails={blockchainDetailsArr}
            handleAddBlockchain={handleAddBlockchain}
            setBlockchain={setMemberBlockchain}
            info={memberBlockchain}
          />
        </Grid>
      </Grid>
      <LoadingButton
        loading={loading}
        onClick={handleCreateMemberBlockchainDetailsRequest}
        variant="contained"
        fullWidth
      >
        Create blockchain details
      </LoadingButton>
    </Box>
  )
}

export default CreateBlockchainDetails
