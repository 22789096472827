import { LoadingButton } from '@mui/lab'
import { Alert, Box } from '@mui/material'
import { ValidationError } from 'ajv'
import React, { useState } from 'react'
import { adminProcessPurchaseOrder } from 'src/common/api/apiOrders'
import FormGroup, { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { alertNotification } from 'src/ui'
import { GetPurchaseOrderInput } from './types'

interface UpdatePurchaseOrderProps {
  formInputs: FormInput[]
  orderId?: string
}

const ApiUpdatePurchaseOrder: React.FC<UpdatePurchaseOrderProps> = ({
  formInputs,
  orderId
}: UpdatePurchaseOrderProps) => {
  const emptyPurchaseOrder: GetPurchaseOrderInput = orderId
    ? {
        orderId: orderId,
        refId: ''
      }
    : {
        orderId: '',
        refId: ''
      }

  const [purchaseOrderInfo, setPurchaseOrderInfo] = useState<GetPurchaseOrderInput>(emptyPurchaseOrder)
  const [loading, setLoading] = useState<boolean>(false)

  const handleProcessAdminPurchaseOrder = async () => {
    try {
      setLoading(true)
      const response = await adminProcessPurchaseOrder({
        orderId: purchaseOrderInfo.orderId,
        transferReferenceId: purchaseOrderInfo.refId
      })
      if (response) {
        alertNotification(`Purchase Order ${purchaseOrderInfo.orderId} has been processed successfully`, 'success')
        setPurchaseOrderInfo(emptyPurchaseOrder)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Order not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <FormGroup formInputs={formInputs} setInfo={setPurchaseOrderInfo} info={purchaseOrderInfo} />
      <LoadingButton loading={loading} onClick={handleProcessAdminPurchaseOrder} variant="contained" fullWidth>
        Process
      </LoadingButton>
    </Box>
  )
}

export default ApiUpdatePurchaseOrder
