import { ExpandMore } from '@mui/icons-material'
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  TextField,
  Box,
  InputBaseComponentProps,
  Divider,
  Alert
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ChainAsset, ChainName, FiatCurrency, OrderStatus, PaymentMethod } from 'src/common/static'
import { Container, SummaryTable } from 'src/ui'
import NumberFormat from 'react-number-format'
import {
  getUserDetails,
  createAdminSendOrder,
  fetchWallets,
  GetWalletsModel,
  getUserContactsDetails
} from 'src/common/api'
import { BankDetails, ContactDetails, ContactModel, UserModel } from 'src/common/api/users/models'
import LoadingButton from '@mui/lab/LoadingButton'
import { alertNotification } from 'src/ui'
import { trimId } from 'src/common/helpers'

const CreateSend: React.FC = () => {
  const [values, setValues] = useState({
    userEmail: '',
    fxRate: '1.0000',
    cryptoAmount: '',
    address: '',
    contactId: '',
    recipientBankDetailsId: '',
    userId: ''
  })

  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setIsLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fiatAmount, setFiatAmount] = useState('0.00')

  const [orderStatus, setOrderStatus] = useState(OrderStatus.NEW)
  const [asset, setAsset] = useState(ChainAsset.USDC)
  const [user, setUser] = useState<UserModel | null>(null)
  const [chain, setChain] = useState(ChainName.ETHEREUM)
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.FIAT_PAYOUT)
  const [fiatCurrency, setFiatCurrency] = useState(FiatCurrency.CAD)

  const [userWallets, setUserWallets] = useState<GetWalletsModel[] | null>(null)
  const [userSelectedWallet, setuserSelectedWallet] = useState<GetWalletsModel | string>('Select wallet')
  const [userContacts, setUserContacts] = useState<ContactModel[] | null>(null)
  const [recipient, setRecipient] = useState<ContactDetails | string>('Select recipient')
  const [recipientBankDetails, setRecipientBankDetails] = useState<BankDetails[] | null>(null)
  const [selectedRecipientBankDetails, setSelectedRecipientBankDetails] = useState<BankDetails | string>(
    'Select bank details'
  )

  const [anchorElStatus, setAnchorElStatus] = useState<null | HTMLElement>(null)
  const [anchorElAsset, setAnchorElAsset] = useState<null | HTMLElement>(null)
  const [anchorElChain, setAnchorElChain] = useState<null | HTMLElement>(null)
  const [anchorElPayment, setAnchorElPayment] = useState<null | HTMLElement>(null)
  const [anchorElFiatCurrency, setAnchorElFiatCurrency] = useState<null | HTMLElement>(null)
  const [anchorElRecipient, setAnchorElRecipient] = useState<null | HTMLElement>(null)
  const [anchorElRecipientBankDetails, setAnchorElRecipientBankDetails] = useState<null | HTMLElement>(null)
  const [anchorElUserWallet, setAnchorElUserWallet] = useState<null | HTMLElement>(null)

  const chooseStatus = (status: OrderStatus) => {
    setOrderStatus(status)
    handleCloseStatusMenu()
  }

  const chooseAsset = (asset: ChainAsset) => {
    setAsset(asset)
    setValues({ ...values, cryptoAmount: '', fxRate: '1.0000' })
    handleCloseAssetMenu()
  }

  const chooseChain = (chain: ChainName) => {
    setChain(chain)
    handleCloseChainMenu()
  }

  const choosePayment = (paymentMethod: PaymentMethod) => {
    setPaymentMethod(paymentMethod)
    setValues({ ...values, fxRate: '1.0000', recipientBankDetailsId: '' })
    handleClosePaymentMenu()
  }

  const chooseFiatCurrency = (fiatCurrency: FiatCurrency) => {
    setFiatCurrency(fiatCurrency)
    setValues({ ...values, cryptoAmount: '', fxRate: '1.0000' })
    handleCloseFiatCurrencyMenu()
  }

  const chooseRecipient = (contactDetails: ContactDetails) => {
    setRecipient(contactDetails)
    setValues({ ...values, contactId: contactDetails.id })
    clearRecipientState()
    clearRecipientBankDetailsState()
    selectRecipientBankDetails(contactDetails.id)
    handleCloseRecipientMenu()
  }

  const chooseRecipientBank = (bankDetails: BankDetails) => {
    setSelectedRecipientBankDetails(bankDetails)
    setValues({ ...values, recipientBankDetailsId: bankDetails.id })
    handleCloseRecipientBankDetailsMenu()
  }

  const chooseUserWallet = (getWalletModel: GetWalletsModel) => {
    setuserSelectedWallet(getWalletModel)
    setValues({ ...values, address: getWalletModel.address })
    handleCloseUserWalletMenu()
  }

  const clearRecipientState = () => {
    setValues({ ...values, recipientBankDetailsId: '' })
    setSelectedRecipientBankDetails('Select bank details')
    setRecipientBankDetails(null)
  }

  const clearRecipientBankDetailsState = () => {
    setValues({ ...values, recipientBankDetailsId: '' })
    setSelectedRecipientBankDetails('Select bank details')
  }

  const clearOrderState = () => {
    setUser(null)
    setIsLoading(true)
  }

  const fetchUser = async () => {
    try {
      clearOrderState()
      const response = await getUserDetails(values.userEmail)
      if (response.data) {
        fetchUserContacts(response.data.id)
        setUser(response.data)
        const userWalletResponse = await fetchWallets({ userId: response.data.id })
        setUserWallets(userWalletResponse.data)
      }
      setIsLoading(false)
    } catch {
      alertNotification('User Not Found')
      setIsLoading(false)
    }
  }

  const fetchUserContacts = async (userId: string) => {
    try {
      const response = await getUserContactsDetails(userId)
      if (response.data) {
        setUserContacts(response.data)
      }
      setIsLoading(false)
    } catch {
      alertNotification('User Contacts Not Found')
      setIsLoading(false)
    }
  }

  const selectRecipientBankDetails = async (contactId: string) => {
    try {
      if (userContacts) {
        for (const contact of userContacts) {
          if (contact.contactDetails.id === contactId) {
            setRecipientBankDetails(contact.bankDetails)
          }
        }
      }
      setIsLoading(false)
    } catch {
      alertNotification('User Contacts Bank Details Not Found')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (typeof recipient !== 'string') {
      values.contactId = recipient.id
    }
  }, [recipient])

  useEffect(() => {
    if (typeof selectedRecipientBankDetails !== 'string') {
      values.recipientBankDetailsId = selectedRecipientBankDetails.id
    }
  }, [selectedRecipientBankDetails])

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      const response = await createAdminSendOrder({
        userId: user?.id as string,
        fxRate: Number(values.fxRate),
        cryptoAmount: Number(values.cryptoAmount),
        asset: asset,
        fiatCurrency: fiatCurrency,
        orderStatus: orderStatus,
        address: values.address,
        chain: chain,
        paymentMethod:
          Object.keys(PaymentMethod)[Object.values(PaymentMethod).indexOf(paymentMethod as unknown as PaymentMethod)],
        contactId: values.contactId,
        bankingDetailsId: values.recipientBankDetailsId
      })
      setSuccessMessage(`Order ${response.data.id} created successfully!`)
      setUser(null)
      setSubmitting(false)
    } catch {
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValues({
      ...values,
      [event.target.name]: newValue
    })
  }

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget)
  }

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null)
  }

  const handleOpenAssetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAsset(event.currentTarget)
  }

  const handleCloseAssetMenu = () => {
    setAnchorElAsset(null)
  }

  const handleOpenChainMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElChain(event.currentTarget)
  }

  const handleCloseChainMenu = () => {
    setAnchorElChain(null)
  }

  const handleOpenPaymentMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPayment(event.currentTarget)
  }

  const handleClosePaymentMenu = () => {
    setAnchorElPayment(null)
  }

  const handleOpenFiatCurrencyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFiatCurrency(event.currentTarget)
  }

  const handleCloseFiatCurrencyMenu = () => {
    setAnchorElFiatCurrency(null)
  }

  const handleOpenRecipientMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRecipient(event.currentTarget)
  }

  const handleCloseRecipientMenu = () => {
    setAnchorElRecipient(null)
  }

  const handleOpenRecipientBankDetailsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRecipientBankDetails(event.currentTarget)
  }

  const handleCloseRecipientBankDetailsMenu = () => {
    setAnchorElRecipientBankDetails(null)
  }

  const handleOpenUserWalletMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUserWallet(event.currentTarget)
  }

  const handleCloseUserWalletMenu = () => {
    setAnchorElUserWallet(null)
  }

  useEffect(() => {
    const updateFiatAmount = () => {
      let newFiatAmount: number
      if (fiatCurrency === FiatCurrency.USD) {
        newFiatAmount = Number(values.cryptoAmount) / Number(values.fxRate)
      } else {
        newFiatAmount = Number(values.cryptoAmount) * Number(values.fxRate)
      }
      setFiatAmount(newFiatAmount.toFixed(2))
    }
    updateFiatAmount()
  }, [values.fxRate, values.cryptoAmount])

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <Typography variant="h5" fontWeight={600}>
          Create Send
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="userEmail" placeholder="User Email" onChange={handleChange} value={values.userEmail} />
          <Box>
            <Button variant="contained" onClick={fetchUser} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {user && (
          <>
            <Alert severity="warning">
              Updating the Order Status to INITIATED will trigger an email notification to the user.
            </Alert>
            <Divider />
            <Typography variant="h6">Create new Send Order</Typography>
            <Alert severity="info">
              Please enter the Custom FX Rate and Recipient Bank Account ID only for Fiat Payout methods. Please leave
              it blank for On-chain Transfers.
            </Alert>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenStatusMenu} size="small">
                      {orderStatus}
                    </Button>
                  )
                },
                {
                  key: 'Chain',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenChainMenu} size="small">
                      {chain}
                    </Button>
                  )
                },
                {
                  key: 'User Wallet',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenUserWalletMenu} size="small">
                      {typeof userSelectedWallet !== 'string'
                        ? `${trimId(userSelectedWallet.address)}`
                        : userSelectedWallet}
                    </Button>
                  )
                },
                {
                  key: 'Asset',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenAssetMenu} size="small">
                      {asset}
                    </Button>
                  )
                },
                {
                  key: 'Payment Method',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenPaymentMenu} size="small">
                      {paymentMethod}
                    </Button>
                  )
                },
                {
                  key: 'Currency',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenFiatCurrencyMenu} size="small">
                      {fiatCurrency}
                    </Button>
                  )
                },
                {
                  key: 'Custom FX Rate (for USDC/CAD and QCAD/USD only) (format: 1 USD = ______ CAD)',
                  value: (
                    <TextField
                      value={values.fxRate}
                      onChange={handleChange}
                      name="fxRate"
                      placeholder="1.0000"
                      disabled={paymentMethod === PaymentMethod.CRYPTO_TRANSFER}
                      InputProps={{
                        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
                      }}
                    />
                  )
                },
                {
                  key: `Amount (${asset})`,
                  value: (
                    <TextField
                      value={values.cryptoAmount}
                      onChange={handleChange}
                      name="cryptoAmount"
                      placeholder="0.00"
                      InputProps={{
                        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
                      }}
                    />
                  )
                },
                {
                  key: `Amount (${fiatCurrency})`,
                  value: `${fiatAmount}`
                },
                {
                  key: 'Recipient',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenRecipientMenu} size="small">
                      {typeof recipient !== 'string' ? `${recipient.firstName} ${recipient.lastName}` : recipient}
                    </Button>
                  )
                },
                {
                  key: 'Recipient Bank Details (Bank Name (country)): Acc Num)',
                  value: (
                    <Button
                      endIcon={<ExpandMore />}
                      color="primary"
                      onClick={handleOpenRecipientBankDetailsMenu}
                      size="small"
                    >
                      {typeof selectedRecipientBankDetails !== 'string'
                        ? `${selectedRecipientBankDetails.name} (${
                            selectedRecipientBankDetails.country
                          }): ****${selectedRecipientBankDetails.accountNum?.substring(
                            selectedRecipientBankDetails.accountNum.length - 4
                          )}`
                        : selectedRecipientBankDetails}
                    </Button>
                  )
                }
              ]}
            />
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
              Create Order
            </LoadingButton>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-status-type"
              anchorEl={anchorElStatus}
              keepMounted
              open={Boolean(anchorElStatus)}
              onClose={handleCloseStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus(status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-asset"
              anchorEl={anchorElAsset}
              keepMounted
              open={Boolean(anchorElAsset)}
              onClose={handleCloseAssetMenu}
            >
              {Object.values(ChainAsset).map((asset) => (
                <MenuItem
                  key={asset}
                  onClick={() => chooseAsset(asset)}
                  disabled={asset === ChainAsset.QCAD && chain === ChainName.ALGORAND}
                >
                  <Typography>{asset}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-chain"
              anchorEl={anchorElChain}
              keepMounted
              open={Boolean(anchorElChain)}
              onClose={handleCloseChainMenu}
            >
              {Object.values(ChainName).map((chain) => (
                <MenuItem key={chain} onClick={() => chooseChain(chain)} disabled={chain === ChainName.ALGORAND}>
                  <Typography>{chain}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-user-wallet"
              anchorEl={anchorElUserWallet}
              keepMounted
              open={Boolean(anchorElUserWallet)}
              onClose={handleCloseUserWalletMenu}
            >
              {userWallets
                ?.filter((wallet) => wallet.chain === chain)
                .map((wallet) => (
                  <MenuItem key={wallet.id} onClick={() => chooseUserWallet(wallet)}>
                    <Typography>{`${wallet.address}`}</Typography>
                  </MenuItem>
                ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-payment"
              anchorEl={anchorElPayment}
              keepMounted
              open={Boolean(anchorElPayment)}
              onClose={handleClosePaymentMenu}
            >
              {Object.values(PaymentMethod).map((payment) => (
                <MenuItem
                  key={payment}
                  onClick={() => choosePayment(payment)}
                  disabled={payment === PaymentMethod.EFT || payment === PaymentMethod.BILL_PAY}
                >
                  <Typography>{payment}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-fiat-currency"
              anchorEl={anchorElFiatCurrency}
              keepMounted
              open={Boolean(anchorElFiatCurrency)}
              onClose={handleCloseFiatCurrencyMenu}
            >
              {Object.values(FiatCurrency).map((fiatCurrency) => (
                <MenuItem key={fiatCurrency} onClick={() => chooseFiatCurrency(fiatCurrency)}>
                  <Typography>{fiatCurrency}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-recipient"
              anchorEl={anchorElRecipient}
              keepMounted
              open={Boolean(anchorElRecipient)}
              onClose={handleCloseRecipientMenu}
            >
              {userContacts?.map((contact) => (
                <MenuItem
                  key={contact.contactDetails.firstName}
                  onClick={() => chooseRecipient(contact.contactDetails)}
                >
                  <Typography>{`${contact.contactDetails.firstName} ${contact.contactDetails.lastName}`}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-recipient-bank-details"
              anchorEl={anchorElRecipientBankDetails}
              keepMounted
              open={Boolean(anchorElRecipientBankDetails)}
              onClose={handleCloseRecipientBankDetailsMenu}
            >
              {recipientBankDetails == null ? (
                <MenuItem>
                  <Typography>recipientBankDetails</Typography>{' '}
                </MenuItem>
              ) : recipientBankDetails.length > 0 ? (
                recipientBankDetails.map((bank) => (
                  <MenuItem key={bank.accHolderName} onClick={() => chooseRecipientBank(bank)}>
                    <Typography>{`${bank.name} (${bank.country}): ****${bank.accountNum?.substring(
                      bank.accountNum.length - 4
                    )}`}</Typography>
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <Typography>No Bank Details</Typography>{' '}
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </Stack>
    </Container>
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<Record<string, unknown>>, CustomProps>((props, ref) => {
  const { onChange, name, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
      allowNegative={false}
      decimalScale={name === 'fxRate' ? 4 : 2}
      fixedDecimalScale
    />
  )
})

NumberFormatCustom.displayName = 'NumberFormatCustom'

export default CreateSend
