import { Alert, AlertColor, AlertTitle, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useRef, useState } from 'react'
import { alertNotification, Container } from 'src/ui'
import { getFeatureStates, patchFeatureStates } from 'src/common/api/flagsmith'
import JoditEditor from 'jodit-react'

interface AlertFormState {
  selectedFlag: 'display-add-funds-alert' | 'display-dashboard-alert' | 'display-send-alert'
  enable: 'true' | 'false'
  alertType: AlertColor
  alertTitle: string
  alertMessage: string
}

const GrapesAlert: React.FC = () => {
  const [alertFormState, setAlertFormState] = useState<AlertFormState>({
    selectedFlag: 'display-add-funds-alert',
    enable: 'true',
    alertType: 'error',
    alertTitle: '',
    alertMessage: ''
  })
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const featureName = alertFormState.selectedFlag

  const config = {
    readonly: false,
    height: 300
  }
  const editor = useRef(null)
  const [content, setContent] = useState('')

  useEffect(() => {
    setAlertFormState((prevState) => ({
      ...prevState,
      alertMessage: content
    }))
  }, [content])

  useEffect(() => {
    // Fetch the existing feature state and prefill the form
    const fetchFeatureState = async () => {
      try {
        const getFeatureStatesResponse = await getFeatureStates(featureName)
        const featureState = getFeatureStatesResponse.data.results[0]
        const featureStateValue = JSON.parse(getFeatureStatesResponse.data.results[0].feature_state_value)

        // Update the alertFormState with fetched data
        setAlertFormState((prevState) => ({
          ...prevState,
          enable: featureState.enabled.toString(),
          alertType: featureStateValue.alertType,
          alertTitle: featureStateValue.alertTitle,
          alertMessage: featureStateValue.alertMessage
        }))
        setContent(featureStateValue.alertMessage)
      } catch (error) {
        console.error('Error fetching feature state:', error)
      }
    }

    fetchFeatureState()
  }, [alertFormState.selectedFlag])

  const handleRequest = async () => {
    try {
      setSubmitting(true)

      // Get the existing feature state id based on the feature name
      const getFeatureStatesResponse = await getFeatureStates(featureName)
      const featureStateId = getFeatureStatesResponse.data.results[0].id

      // Construct the payload using the form data
      const payload = {
        id: featureStateId,
        feature_state_value: JSON.stringify({
          alertType: alertFormState.alertType,
          alertTitle: alertFormState.alertTitle,
          alertMessage: alertFormState.alertMessage
        }),
        enabled: alertFormState.enable === 'true'
      }

      // Update the feature state
      const updateFeatureStateResponse = await patchFeatureStates(featureStateId, payload)

      // Handle successful update
      console.log('Feature state updated successfully:', updateFeatureStateResponse.data)

      // Update success message and reset the form state
      setSuccessMessage('Alert updated successfully!')
      setSubmitting(false)
    } catch (error) {
      // Handle error case
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setAlertFormState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setAlertFormState((prevState) => ({
      ...prevState,
      [name]: value as string
    }))
  }

  const { selectedFlag: selectedFlag, enable, alertType, alertTitle, alertMessage } = alertFormState

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <>
          <Typography variant="h6">Grapes Alerts</Typography>
          <Alert severity="info">
            Note: By selecting an alert, form will prefill and display existing alert in <strong>Alert Preview</strong>
          </Alert>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Select Alert'}
            <TextField
              name="selectedFlag"
              select
              placeholder="Select Flag"
              onChange={handleSelectChange}
              value={selectedFlag}
              fullWidth
            >
              <MenuItem value="display-add-funds-alert">Display Add Funds Alert</MenuItem>
              <MenuItem value="display-dashboard-alert">Display Dashboard Alert</MenuItem>
              <MenuItem value="display-send-alert">Display Send Alert</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Enable Alert'}
            <TextField
              name="enable"
              select
              placeholder="Select Flag"
              onChange={handleSelectChange}
              value={enable}
              fullWidth
            >
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Alert Type'}
            <TextField
              name="alertType"
              select
              placeholder="Alert Type"
              onChange={handleSelectChange}
              value={alertType}
              fullWidth
            >
              <MenuItem value="error">Error</MenuItem>
              <MenuItem value="warning">Warning</MenuItem>
              <MenuItem value="info">Info</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Alert Title (Optional)'}
            <TextField
              name="alertTitle"
              placeholder="Alert Title"
              onChange={handleChange}
              value={alertTitle}
              fullWidth
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Alert Message'}
            <JoditEditor ref={editor} value={content} config={config} onBlur={(newContent) => setContent(newContent)} />
          </Stack>
          <Divider />
          <Typography variant="h6">Alert Preview</Typography>
          <Alert severity={alertType}>
            <AlertTitle>{alertTitle}</AlertTitle>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: alertMessage }} />
          </Alert>
          <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
            Update Alert
          </LoadingButton>
        </>
      </Stack>
    </Container>
  )
}

export default GrapesAlert
