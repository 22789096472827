import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { bankingDetails, billPayDetails, blockchainDetails } from '../Common/data'

export const createMemberData: FormInput[][] = [
  [
    {
      formTitle: 'Organization ID',
      formId: 'orgId',
      formType: FormType.input,
      formValue: 'Organization ID'
    },
    {
      formTitle: 'Business Name',
      formId: 'businessName',
      formType: FormType.input,
      formValue: 'Business Name'
    },
    {
      formTitle: 'First Name',
      formId: 'firstName',
      formType: FormType.input,
      formValue: 'First Name'
    },
    {
      formTitle: 'Last Name',
      formId: 'lastName',
      formType: FormType.input,
      formValue: 'Last Name'
    },
    {
      formTitle: 'Email',
      formId: 'email',
      formType: FormType.input,
      formValue: 'Email'
    },
    {
      formTitle: 'Interac Email',
      formId: 'interacEmail',
      formType: FormType.input,
      formValue: 'Interac Email'
    }
  ],
  [
    {
      formTitle: 'Address',
      formId: 'address',
      formType: FormType.input,
      formValue: 'Address'
    },
    {
      formTitle: 'Postal Code',
      formId: 'postalCode',
      formType: FormType.input,
      formValue: 'Postal Code'
    },
    {
      formTitle: 'Country',
      formId: 'countryCode',
      formType: FormType.input,
      formValue: 'Country'
    },
    {
      formTitle: 'City',
      formId: 'city',
      formType: FormType.input,
      formValue: 'City'
    },
    {
      formTitle: 'State/Province',
      formId: 'stateProvince',
      formType: FormType.input,
      formValue: 'State/Province'
    }
  ]
]

export const createMemberBillpayData: FormInput[][] = [
  [
    {
      formTitle: 'Member ID',
      formId: 'memberId',
      formType: FormType.input,
      formValue: 'Member ID'
    },
    {
      formTitle: 'Organization ID',
      formId: 'orgId',
      formType: FormType.input,
      formValue: 'Organization ID'
    }
  ],
  billPayDetails
]

export const createMemberBlockchainDetailsData = [
  [
    {
      formTitle: 'Organization ID',
      formId: 'orgId',
      formType: FormType.input,
      formValue: 'Organization ID'
    },
    {
      formTitle: 'Member ID',
      formId: 'memberId',
      formType: FormType.input,
      formValue: 'Member ID'
    }
  ],
  blockchainDetails
]

export const createMemberBankDetailsData = [
  [
    {
      formTitle: 'Organization ID',
      formId: 'orgId',
      formType: FormType.input,
      formValue: 'Organization ID'
    },
    {
      formTitle: 'Member ID',
      formId: 'memberId',
      formType: FormType.input,
      formValue: 'Member ID'
    }
  ],
  bankingDetails
]
