import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import { formatNumber } from 'src/common/helpers'
import { ChainName } from 'src/common/static'
import { QCADIcon, USDCIcon } from 'src/common/assets'
import { WalletAnalytics } from 'src/common/api'
import { isSdcProject } from '../../../common/helpers/isSdcProject'

interface TotalHoldingsOverviewProps {
  firstName?: string
  walletAnalytics: WalletAnalytics[]
}

const TotalHoldingsOverview: React.FC<TotalHoldingsOverviewProps> = ({
  firstName,
  walletAnalytics
}: TotalHoldingsOverviewProps) => {
  const composition = walletAnalytics.reduce(
    (acc, wallet) => {
      const { chain, balance } = wallet
      if (chain === ChainName.ALGORAND) {
        return { ...acc, algoQCAD: acc.algoQCAD + (balance?.qcad ?? 0), algoUSDC: acc.algoUSDC + (balance?.usdc ?? 0) }
      }
      if (chain === ChainName.ETHEREUM) {
        return { ...acc, ethQCAD: acc.ethQCAD + (balance?.qcad ?? 0), ethUSDC: acc.ethUSDC + (balance?.usdc ?? 0) }
      }
      if (chain === ChainName.STELLAR) {
        return {
          ...acc,
          xlmQCAD: acc.xlmQCAD + (balance?.qcad_xlm ?? 0),
          xlmUSDC: acc.xlmUSDC + (balance?.usdc_xlm ?? 0)
        }
      }
      return { ...acc }
    },
    { algoQCAD: 0, algoUSDC: 0, ethQCAD: 0, ethUSDC: 0, xlmQCAD: 0, xlmUSDC: 0 }
  )

  const data = [
    {
      id: 'ALGO / QCAD',
      label: 'Algorand',
      value: composition.algoQCAD,
      color: '#c947ff'
    },
    {
      id: 'ALGO / USDC',
      label: 'Algorand',
      value: composition.algoUSDC,
      color: '#d87cff'
    },
    {
      id: 'ETH / QCAD',
      label: 'Ethereum',
      value: composition.ethQCAD,
      color: '#e5a8ff'
    },
    {
      id: 'ETH / USDC',
      label: 'Ethereum',
      value: composition.ethUSDC,
      color: '#f1d1ff'
    },
    {
      id: 'XML / QCAD',
      label: 'Stellar',
      value: composition.xlmQCAD,
      color: '#ce97e5'
    },
    {
      id: 'XML / USDC',
      label: 'Stellar',
      value: composition.xlmUSDC,
      color: '#b987ce'
    }
  ]

  const filteredData = data.filter((item) => Boolean(item.value))

  const totalQCAD = data[0].value + data[2].value + data[4].value
  const totalUSDC = data[1].value + data[3].value + data[5].value

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Stack justifyContent="space-around" alignSelf="stretch" flex="1 1 50%">
        <Typography variant="h5" fontWeight={500}>
          {isSdcProject() ? 'Welcome SDC Admin~' : `Welcome admin, ${firstName}!`}
        </Typography>
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight={400}>
            Total Grapes Holdings
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <QCADIcon fontSize="large" />
            <Typography variant="h4" fontWeight={600}>
              {formatNumber(totalQCAD)}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <USDCIcon fontSize="large" />
            <Typography variant="h4" fontWeight={600}>
              {formatNumber(totalUSDC)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box height={250} flex="1 1 50%">
        <ResponsivePie
          data={filteredData.length ? filteredData : []}
          valueFormat={(value) => value.toString()}
          arcLabel="none"
          margin={{ top: 10, bottom: 10 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          enableArcLinkLabels={false}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={3}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsDiagonalLength={6}
          arcLinkLabelsStraightLength={6}
          arcLinkLabelsSkipAngle={23}
          startAngle={-90}
          motionConfig="default"
          defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 3,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'ALGO / USDC'
              },
              id: 'lines'
            },
            {
              match: {
                id: 'ETH / USDC'
              },
              id: 'lines'
            }
          ]}
        />
      </Box>
    </Box>
  )
}

export default TotalHoldingsOverview
