import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { adminGetOrganizationByEmail, OrganizationDetailsModel, OrganizationModel } from 'src/common/api/organizations'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { ValidationError } from 'src/common/helpers'
import { alertNotification } from 'src/ui'
import { CrudActionContext } from '../../ApiMembers/context'
import {
  orgCardBankInput,
  orgCardBillPayInput,
  orgCardBlockchainInput,
  orgCardHeaderInput,
  orgCardInfoInput
} from '../Common/formInputs'
import { OrgBasicInfo, OrgCardInput } from '../types'
import { CardInput } from 'src/common/components/EntityCardGroup/EntityCardGroup'
import OrgCard from './OrgCard'

interface GetOrgProps {
  formInputs: FormInput[]
}

const NULL_STRING = 'NULL'
const EMPTY_STRING = ''
const DEFAULT_ORGANIZATION_DETAILS: OrganizationModel = {
  id: EMPTY_STRING,
  email: EMPTY_STRING,
  address: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  countryCode: EMPTY_STRING,
  city: EMPTY_STRING,
  stateProvince: EMPTY_STRING,
  createdAt: EMPTY_STRING,
  updatedAt: EMPTY_STRING,
  orgName: EMPTY_STRING,
  topicARN: EMPTY_STRING
}

const GetOrganization: React.FC<GetOrgProps> = ({ formInputs }: GetOrgProps) => {
  const emptyOrgBasicInfo: OrgBasicInfo = {
    email: ''
  }

  const emptyOrganizationDetails: OrganizationModel = DEFAULT_ORGANIZATION_DETAILS

  const emptyFullOrganizationDetails: OrganizationDetailsModel = {
    organizationDetails: emptyOrganizationDetails,
    bankDetails: [],
    blockchainDetails: [],
    billPayDetails: []
  }

  const [organizationCardDataInput, setOrganizationCardDataInput] = useState<OrgCardInput>({
    info: orgCardInfoInput,
    bank: orgCardBankInput,
    blockchain: orgCardBlockchainInput,
    billPay: orgCardBillPayInput
  })

  const [organizationDetailsFormInfo, setOrganizationDetailsFormInfo] = useState<OrgBasicInfo>(emptyOrgBasicInfo)
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const [organizationFullDetails, setFullOrganizationDetails] =
    useState<OrganizationDetailsModel>(emptyFullOrganizationDetails)

  const handleGetOrganizationByEmailRequest = async () => {
    try {
      const response = await adminGetOrganizationByEmail(organizationDetailsFormInfo.email)
      if (response) {
        if (typeof response.data === 'string') {
          // throw error
          throw new Error('404')
        } else {
          alertNotification(
            `Organization ${organizationDetailsFormInfo.email}'s details have been fetched successfully`,
            'success'
          )
          const res: OrganizationDetailsModel = response.data
          setOrganizationDetailsFormInfo(emptyOrgBasicInfo)
          setFullOrganizationDetails(res)
          setIsDataFetched(true)
        }
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Organization not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  const updateOrganizationDetailsCardInput = () => {
    const { organizationDetails } = organizationFullDetails
    // update the member details here

    orgCardInfoInput.forEach((input) => {
      const { formId } = input
      const value = (organizationDetails as unknown as Record<string, string>)[formId] || EMPTY_STRING
      if (formId === 'firstName' || formId === 'lastName') {
        input.formValue = value !== EMPTY_STRING ? value : NULL_STRING
      } else {
        input.formValue = value
      }
    })
    setOrganizationCardDataInput((prev) => ({
      ...prev,
      info: orgCardInfoInput
    }))
  }

  const updateOrganizationBankDetailsCardInput = () => {
    const { bankDetails } = organizationFullDetails

    const bankDataArrLen = bankDetails.length

    const updatedOrganizationCardBankInput: CardInput[][] = []
    for (let i = 0; i < bankDataArrLen; i++) {
      updatedOrganizationCardBankInput.push([
        {
          formTitle: 'Bank Details ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Bank Details ID'
        },
        {
          formTitle: 'Country',
          formId: 'country',
          formType: FormType.input,
          formValue: 'Country'
        },
        {
          formTitle: 'Bank Details Name',
          formId: 'name',
          formType: FormType.input,
          formValue: 'Bank Details Name'
        },
        {
          formTitle: 'Account Holder Name',
          formId: 'accHolderName',
          formType: FormType.input,
          formValue: 'Account Holder Name'
        },
        {
          formTitle: 'Routing Number',
          formId: 'routingNum',
          formType: FormType.input,
          formValue: 'routing Number'
        },
        {
          formTitle: 'Account Number',
          formId: 'accountNum',
          formType: FormType.input,
          formValue: 'Account Number'
        },
        {
          formTitle: 'ACH Code',
          formId: 'achCode',
          formType: FormType.input,
          formValue: 'ACH Code'
        },
        {
          formTitle: 'SWIFT/BIC Code',
          formId: 'swiftBicCode',
          formType: FormType.input,
          formValue: 'SWIFT/BIC Code'
        },
        {
          formTitle: 'Primary Bank',
          formId: 'isPrimary',
          formType: FormType.input,
          formValue: 'Primary Bank'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Created At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Created At'
        }
      ])
    }

    updatedOrganizationCardBankInput.map((input, index) => {
      input.forEach((bank) => {
        const { formId } = bank
        const matchingBankDetail = (bankDetails[index] as unknown as Record<string, string>) || EMPTY_STRING
        if (matchingBankDetail) {
          bank.formValue = matchingBankDetail[formId] !== null ? matchingBankDetail[formId].toString() : NULL_STRING
        }
      })
    })
    setOrganizationCardDataInput((prev) => ({
      ...prev,
      bank: updatedOrganizationCardBankInput
    }))
  }

  const updateOrganizationBlockchainDetailsCardInput = () => {
    const { blockchainDetails } = organizationFullDetails

    const blockchainDataArrLen = blockchainDetails.length

    const updatedOrganizationCardBlockchainInput: CardInput[][] = []
    for (let i = 0; i < blockchainDataArrLen; i++) {
      updatedOrganizationCardBlockchainInput.push([
        {
          formTitle: 'Blockchain Details ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'Blockchain Details ID'
        },
        {
          formTitle: 'Blockchain Details Name',
          formId: 'name',
          formType: FormType.input,
          formValue: 'Blockchain Details Name'
        },
        {
          formTitle: 'Address',
          formId: 'address',
          formType: FormType.input,
          formValue: 'Address'
        },
        {
          formTitle: 'Chain',
          formId: 'chain',
          formType: FormType.input,
          formValue: 'Chain'
        },
        {
          formTitle: 'Type',
          formId: 'type',
          formType: FormType.input,
          formValue: 'Type'
        },
        {
          formTitle: 'External Memo',
          formId: 'externalMemo',
          formType: FormType.input,
          formValue: 'External Memo'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Created At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Updated At'
        }
      ])
    }

    updatedOrganizationCardBlockchainInput.map((input, index) => {
      input.forEach((bc) => {
        const { formId } = bc
        const matchingBlockchainDetail = (blockchainDetails[index] as unknown as Record<string, string>) || EMPTY_STRING
        if (matchingBlockchainDetail) {
          if (formId === 'address') {
            bc.formValue = matchingBlockchainDetail[formId] !== null ? matchingBlockchainDetail[formId] : NULL_STRING
          } else {
            bc.formValue = matchingBlockchainDetail[formId] !== null ? matchingBlockchainDetail[formId] : NULL_STRING
          }
        }
      })
    })
    setOrganizationCardDataInput((prev) => ({
      ...prev,
      blockchain: updatedOrganizationCardBlockchainInput
    }))
  }

  const updateOrganizationBillPayDetailsCardInput = () => {
    const { billPayDetails } = organizationFullDetails

    const billPayDataArrLen = billPayDetails.length

    const updatedOrganizationCardBillPayInput: CardInput[][] = []
    for (let i = 0; i < billPayDataArrLen; i++) {
      updatedOrganizationCardBillPayInput.push([
        {
          formTitle: 'Billpay ID',
          formId: 'id',
          formType: FormType.input,
          formValue: 'ID'
        },
        {
          formTitle: 'Payee Name',
          formId: 'payeeName',
          formType: FormType.input,
          formValue: 'Payee Name'
        },
        {
          formTitle: 'Payee Code',
          formId: 'payeeCode',
          formType: FormType.input,
          formValue: 'Payee Code'
        },
        {
          formTitle: 'Payee Account Number',
          formId: 'payeeAccountNumber',
          formType: FormType.input,
          formValue: 'Payee Account Number'
        },
        {
          formTitle: 'Created At',
          formId: 'createdAt',
          formType: FormType.input,
          formValue: 'Createad At'
        },
        {
          formTitle: 'Updated At',
          formId: 'updatedAt',
          formType: FormType.input,
          formValue: 'Updated At'
        }
      ])
    }

    updatedOrganizationCardBillPayInput.map((input, index) => {
      input.forEach((bank) => {
        const { formId } = bank
        const matchingBankDetail = (billPayDetails[index] as unknown as Record<string, string>) || EMPTY_STRING
        if (matchingBankDetail) {
          bank.formValue = matchingBankDetail[formId] !== null ? matchingBankDetail[formId] : NULL_STRING
        }
      })
    })
    setOrganizationCardDataInput((prev) => ({
      ...prev,
      billPay: updatedOrganizationCardBillPayInput
    }))
  }

  useEffect(() => {
    updateOrganizationDetailsCardInput()
    updateOrganizationBankDetailsCardInput()
    updateOrganizationBlockchainDetailsCardInput()
    updateOrganizationBillPayDetailsCardInput()
  }, [organizationFullDetails])

  return (
    <Box>
      <CrudActionContext.Provider value={handleGetOrganizationByEmailRequest}>
        {!isDataFetched && (
          <CrudActionCard
            crudType={CrudTypes.Get}
            formInputs={formInputs}
            setInfo={setOrganizationDetailsFormInfo}
            info={organizationDetailsFormInfo}
          />
        )}
        {isDataFetched && (
          <Box display="flex" justifyContent="center">
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <OrgCard
              entityId={`Organization ID: ${organizationFullDetails.organizationDetails.id.toString()}`}
              entityCardInput={organizationCardDataInput}
              headers={orgCardHeaderInput}
            />
          </Box>
        )}
      </CrudActionContext.Provider>
    </Box>
  )
}

export default GetOrganization
