import { LoadingButton } from '@mui/lab'
import { Alert, Box } from '@mui/material'
import React, { useState } from 'react'
import { adminProcessRedeemOrder } from 'src/common/api/apiOrders'
import FormGroup, { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { ValidationError } from 'src/common/helpers'
import { alertNotification } from 'src/ui'
import { GetRedeemOrderInput } from './types'

interface UpdateRedeemOrderProps {
  formInputs: FormInput[]
  orderId?: string
}

const ApiUpdateRedeemOrder: React.FC<UpdateRedeemOrderProps> = ({ formInputs, orderId }: UpdateRedeemOrderProps) => {
  const emptyRedeemOrder: GetRedeemOrderInput = orderId
    ? {
        orderId: orderId,
        refId: '',
        orderStatus: '',
        transferStatus: ''
      }
    : {
        orderId: '',
        refId: '',
        orderStatus: '',
        transferStatus: ''
      }

  const [redeemOrderInfo, setRedeemOrderInfo] = useState<GetRedeemOrderInput>(emptyRedeemOrder)
  const [loading, setLoading] = useState<boolean>(false)

  const handleProcessAdminRedeemOrder = async () => {
    try {
      setLoading(true)
      const response = await adminProcessRedeemOrder({
        orderId: redeemOrderInfo.orderId,
        orderStatus: redeemOrderInfo.orderStatus,
        transferReferenceId: redeemOrderInfo.refId,
        transferStatus: redeemOrderInfo.transferStatus
      })
      if (response) {
        alertNotification(`Redeem Order ${redeemOrderInfo.orderId} has been processed successfully`, 'success')
        setRedeemOrderInfo(emptyRedeemOrder)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Order not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <FormGroup formInputs={formInputs} setInfo={setRedeemOrderInfo} info={redeemOrderInfo} />
      <LoadingButton loading={loading} onClick={handleProcessAdminRedeemOrder} variant="contained" fullWidth>
        Process
      </LoadingButton>
    </Box>
  )
}

export default ApiUpdateRedeemOrder
