import { Box, Typography } from '@mui/material'
import React from 'react'
import CrudButtons from '../../../common/components/CrudButtons'
import { CrudTypes } from '../../../common/components/CrudButtons/constants/CrudTypes'
import { DropDownTypes } from '../../../common/components/dropDown/constants/DropDownTypes'
import { isSdcProject } from '../../../common/helpers/isSdcProject'

interface ActionCardProps {
  crud: {
    create: { isPresent: boolean; isDropdown: boolean }
    update: { isPresent: boolean; isDropdown: boolean }
  }
}

const OrdersActionCard: React.FC<ActionCardProps> = ({ crud }: ActionCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'space-between',
        marginBottom: '16px',
        gap: '24px'
      }}
    >
      <Typography display={'flex'} variant="h5" fontWeight={600}>
        {`${isSdcProject() ? 'SDC' : 'Grapes'} API Orders`}
      </Typography>
      <Box style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        {crud.create.isPresent &&
          (crud.create.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Create} dropdownType={DropDownTypes.Create} />
          ) : (
            <CrudButtons crudType={CrudTypes.Create} />
          ))}
        {crud.update.isPresent &&
          (crud.update.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Update} dropdownType={DropDownTypes.Update} />
          ) : (
            <CrudButtons crudType={CrudTypes.Update} />
          ))}
      </Box>
    </Box>
  )
}

export default OrdersActionCard
