import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { getUserDetails, updateUserKYCByAdmin } from 'src/common/api'
import { UserModel } from 'src/common/api/users/models'
import { alertNotification, Container } from 'src/ui'

const KYC: React.FC = () => {
  const [value, setValue] = useState('')
  const [user, setUser] = useState<UserModel | null>(null)
  const [kycId, setKYCID] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const clearOrderState = () => {
    setUser(null)
    setLoading(true)
  }

  const fetchUser = async () => {
    try {
      clearOrderState()
      const response = await getUserDetails(value)
      if (response.data) {
        setUser(response.data)
        setKYCID(response.data.kycId)
      }
      setLoading(false)
    } catch {
      alertNotification('User Not Found')
      setLoading(false)
    }
  }

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      await updateUserKYCByAdmin({ userId: user?.id as string, kycId: kycId })
      setSuccessMessage(`KYC ID updated successfully!`)
      setUser(null)
      setSubmitting(false)
    } catch {
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)
  }

  const handleKYCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setKYCID(newValue)
  }

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="email" placeholder="User Email" onChange={handleChange} value={value} />
          <Box>
            <Button variant="contained" onClick={fetchUser} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {user && (
          <>
            <Divider />
            <Typography variant="h6">KYC ID</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <TextField name="kycId" placeholder="KYC ID" onChange={handleKYCChange} value={kycId} fullWidth />
            </Stack>
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
              Update KYC ID
            </LoadingButton>
          </>
        )}
      </Stack>
    </Container>
  )
}

export default KYC
