import httpClient from 'src/common/axios/config'
import { AdminCreateOrderDTO, AdminProcessPurchaseOrderDTO, AdminProcessRedeemOrderDTO } from './dto'
import { ApiOrderModel, ExportedOrders, OrderDetailsModel, PaginatedOrders } from './models'

export const adminCreateOrder = (params: AdminCreateOrderDTO) =>
  httpClient.post<ApiOrderModel>('/orders/admin/purchase/member', params)

export const adminProcessRedeemOrder = (params: AdminProcessRedeemOrderDTO) =>
  httpClient.put<ApiOrderModel>('/orders/admin/redeem/process', params)

export const adminProcessPurchaseOrder = (params: AdminProcessPurchaseOrderDTO) =>
  httpClient.put<ApiOrderModel>('/orders/admin/purchase/process', params)

export const adminGetApiOrderByStatus = (status: string, sortBy: string, page: number, limit: number) =>
  httpClient.get<PaginatedOrders<OrderDetailsModel>>(
    `/orders/api-orders?status=${status}&sortBy=${sortBy}&page=${page}&limit=${limit}`
  )

export const adminGetAppOrderByStatus = (status: string, sortBy: string, page: number, limit: number) =>
  httpClient.get<PaginatedOrders<OrderDetailsModel>>(
    `/orders/app-orders?status=${status}&sortBy=${sortBy}&page=${page}&limit=${limit}`
  )

export const adminExportOrderData = (status: string, startDate: string, endDate: string) =>
  httpClient.get<ExportedOrders<OrderDetailsModel>>(
    `/orders/export-orders-data?status=${status}&startDate=${startDate}&endDate=${endDate}`
  )
