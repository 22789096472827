import { Table, TableBody, TableCell } from '@mui/material'
import React from 'react'
import * as S from './styles'
import { Row } from 'src/pages/Mfa/styles'

export interface Row {
  key: string | React.ReactNode
  value: string | React.ReactNode
}

export interface SummaryTableProps {
  data: Row[]
  padding?: number | string
}

const SummaryTable: React.FC<SummaryTableProps> = ({ data, padding }: SummaryTableProps) => {
  return (
    <Table size="small">
      <TableBody>
        {data.map((row, i) => (
          <S.StyledTableRow key={i} padding={padding}>
            <TableCell>{row.key}</TableCell>
            <TableCell align="right">{row.value}</TableCell>
          </S.StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SummaryTable
