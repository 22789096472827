import { ExpandMore } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Divider,
  InputBaseComponentProps,
  Link,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { ChainName, EXPLORER, OrderStatus, PaymentMethod } from 'src/common/static'
import { Container, SummaryTable } from 'src/ui'
import NumberFormat from 'react-number-format'
import useRequest from './Request.State'
import { calculateFxRate, formatDate, formatNumber, formatTime, trimId } from 'src/common/helpers'
import LoadingButton from '@mui/lab/LoadingButton'
import { isSdcProject } from '../../helpers/isSdcProject'

interface RequestProps {
  type: 'purchase' | 'redeem'
}

const Request: React.FC<RequestProps> = ({ type }: RequestProps) => {
  const isPurchase = type === 'purchase'
  const isRedeem = type === 'redeem'
  const {
    order,
    recipient,
    recipientBankDetails,
    user,
    statuses,
    values,
    anchorElStatus,
    anchorElTransferStatus,
    anchorElTransactionStatus,
    successMessage,
    loading,
    submitting,
    fetchOrder,
    handleChange,
    chooseStatus,
    handleOpenStatusMenu,
    handleCloseStatusMenu,
    handleOpenTransferStatusMenu,
    handleCloseTransferStatusMenu,
    handleOpenTransactionStatusMenu,
    handleCloseTransactionStatusMenu,
    handleRequest
  } = useRequest(type)

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <Typography variant="h5" fontWeight={600}>
          {isPurchase && 'Purchase'}
          {isRedeem && 'Redeem'}
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="orderId" placeholder="Order ID" onChange={handleChange} value={values.orderId} />
          <Box>
            <Button variant="contained" onClick={fetchOrder} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {order && (
          <>
            {isSdcProject() ? (
              <Alert severity="warning">
                Updating the Order Status to PROCESSED will trigger an webhook notification to the organization.
              </Alert>
            ) : (
              <Alert severity="warning">
                Updating the Order Status to PROCESSED will trigger an email notification to the user.
              </Alert>
            )}
            <Divider />
            <Typography variant="h6">Order Details</Typography>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: (
                    <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenStatusMenu} size="small">
                      {statuses.overall}
                    </Button>
                  )
                },
                {
                  key: 'Action',
                  value: `${order.transactions[0].asset} ${order.type}`
                },
                {
                  key: 'Asset',
                  value: `${order.transactions[0].asset}`
                },
                {
                  key: 'Payment Method',
                  value: `${order.paymentMethod}`
                },
                {
                  key: 'Date & Time (UTC)',
                  value: `${formatDate(order.createdAt)} ${formatTime(order.createdAt)}`
                },
                {
                  key: 'User IP Address',
                  value: `${order.ipAddress}`
                },
                {
                  key: 'Wallet Address',
                  value: (
                    <Link
                      component="a"
                      href={
                        order.transactions[0].chain != ChainName.STELLAR
                          ? `${EXPLORER[order.transactions[0].chain]}/address/${order.transactions[0].address}`
                          : `${EXPLORER[order.transactions[0].chain]}/account/${order.transactions[0].address}`
                      }
                      target="_blank"
                      rel="noopener"
                    >
                      {trimId(order.transactions[0].address)}
                    </Link>
                  )
                },
                {
                  key: 'Chain',
                  value: order.transactions[0].chain
                },
                {
                  key: `Amount (${order.transactions[0].asset}/${order.transfers[0].fiatCurrency})`,
                  value: `${formatNumber(order.cryptoAmount)} ${order.transactions[0].asset} / $${formatNumber(
                    order.fiatAmount
                  )} ${order.fiatCurrency}`
                },
                {
                  key: 'FX Rate',
                  value: order.fxRate
                    ? String(order.fxRate)
                    : formatNumber(
                        Number(
                          calculateFxRate(
                            order.fiatCurrency,
                            order.transactions[0].asset,
                            Number(order.fiatAmount),
                            Number(order.cryptoAmount)
                          )
                        ),
                        undefined,
                        4
                      )
                },
                {
                  key: 'FX Rate Timestamp',
                  value: order.fxRateTimestamp
                    ? `${formatDate(order.fxRateTimestamp)} ${formatTime(order.fxRateTimestamp)}`
                    : 'N/A'
                },
                {
                  key: 'Order Fee',
                  value: '$0.00'
                },
                {
                  key: 'Remark',
                  value: order.remark ? order.remark : 'N/A'
                }
              ]}
            />
            <Divider />
            {!isSdcProject() && (
              <div>
                <Typography variant="h6">Payee Details</Typography>
                <Alert severity="info">User/Recipient Details. This section will show N/A for E-Transfers.</Alert>
                <SummaryTable
                  padding={7}
                  data={[
                    {
                      key: 'Name',
                      value: recipient
                        ? `${recipient.firstName} ${recipient.lastName}`
                        : user
                        ? `${user?.firstName} ${user?.lastName}`
                        : 'N/A'
                    },
                    {
                      key: 'Email',
                      value: recipient?.email || user?.email || 'N/A'
                    },
                    {
                      key: 'Company Title',
                      value: recipient?.companyTitle || 'N/A'
                    },
                    {
                      key: 'Address',
                      value: recipient?.address || user?.address || 'N/A'
                    },
                    {
                      key: 'City',
                      value: recipient?.city || user?.city || 'N/A'
                    },
                    {
                      key: 'State/Province',
                      value: recipient?.stateProvince || user?.stateProvince || 'N/A'
                    },
                    {
                      key: 'Postal Code',
                      value: recipient?.postalCode || user?.postalCode || 'N/A'
                    },
                    {
                      key: 'Country Code',
                      value: recipient?.countryCode || user?.countryCode || 'N/A'
                    }
                  ]}
                />
                <Divider />
                <Typography variant="h6">Payee Bank Details</Typography>
                <Alert severity="info">User/Recipient Bank Details. This section will show N/A for E-Transfers.</Alert>
                <SummaryTable
                  padding={7}
                  data={[
                    {
                      key: 'Account Holder Name',
                      value: recipientBankDetails?.accHolderName || 'N/A'
                    },
                    {
                      key: 'Bank Country',
                      value: recipientBankDetails?.country || 'N/A'
                    },
                    {
                      key: `Account Number`,
                      value: recipientBankDetails?.accountNum || 'N/A'
                    },
                    {
                      key: `Routing Number`,
                      value: recipientBankDetails?.routingNum || 'N/A'
                    },
                    {
                      key: `Ach Number`,
                      value: recipientBankDetails?.achCode || 'N/A'
                    },
                    {
                      key: `Swift Bic Code`,
                      value: recipientBankDetails?.swiftBicCode || 'N/A'
                    }
                  ]}
                />
                <Divider />
              </div>
            )}
            {isSdcProject() && (
              <div>
                <Typography variant="h6">Member Details</Typography>
                <SummaryTable
                  padding={7}
                  data={[
                    {
                      key: 'Organization Id',
                      value: order?.organizationId
                    },
                    {
                      key: 'Member Id',
                      value: order?.memberId
                    },
                    {
                      key: 'Business Name',
                      value: order?.member?.businessName ? order?.member?.businessName : 'N/A'
                    },
                    {
                      key: 'Banking Details ID ',
                      value: order?.bankingDetailsId ? order?.bankingDetailsId : 'N/A'
                    },
                    {
                      key: 'BillPay Details ID ',
                      value: order?.billPayDetailsId ? order?.billPayDetailsId : 'N/A'
                    }
                  ]}
                />
                <Divider />
              </div>
            )}
            <Typography variant="h6">Transfer Details</Typography>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: (
                    <Button
                      endIcon={<ExpandMore />}
                      color="primary"
                      onClick={handleOpenTransferStatusMenu}
                      size="small"
                    >
                      {statuses.transfer}
                    </Button>
                  )
                },
                {
                  key: 'Payment Method',
                  value: PaymentMethod[order.paymentMethod]
                },
                {
                  key: `Amount (${order.fiatCurrency})`,
                  value: `$${formatNumber(order.fiatAmount)} ${order.fiatCurrency}`
                },
                {
                  key: 'Transfer ID',
                  value: (
                    <TextField
                      value={values.transferReferenceId}
                      onChange={handleChange}
                      name="transferReferenceId"
                      placeholder="Transfer ID"
                    />
                  )
                }
              ]}
            />
            <Divider />
            <Typography variant="h6">Transaction Details</Typography>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Status',
                  value: isPurchase ? (
                    <Button
                      endIcon={<ExpandMore />}
                      color="primary"
                      onClick={handleOpenTransactionStatusMenu}
                      size="small"
                    >
                      {statuses.transaction}
                    </Button>
                  ) : (
                    order.transactions[0].status
                  )
                },
                {
                  key: `Amount (${order.transactions[0].asset})`,
                  value: isPurchase ? (
                    <TextField
                      value={values.cryptoAmount}
                      onChange={handleChange}
                      name="cryptoAmount"
                      placeholder="0.00"
                      InputProps={{
                        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
                      }}
                    />
                  ) : (
                    `${formatNumber(order.cryptoAmount)} ${order.transactions[0].asset}`
                  )
                },
                {
                  key: 'Transaction ID',
                  value: isPurchase ? (
                    <TextField
                      value={values.transactionReferenceId}
                      onChange={handleChange}
                      name="transactionReferenceId"
                      placeholder="Transaction ID"
                    />
                  ) : (
                    <Link
                      component="a"
                      href={`${EXPLORER[order.transactions[0].chain]}/tx/${order.transactions[0].referenceId}`}
                      target="_blank"
                      rel="noopener"
                    >
                      {trimId(order.transactions[0].referenceId)}
                    </Link>
                  )
                }
              ]}
            />
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
              Update Order
            </LoadingButton>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-status-type"
              anchorEl={anchorElStatus}
              keepMounted
              open={Boolean(anchorElStatus)}
              onClose={handleCloseStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus('overall', status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-transfer-status-type"
              anchorEl={anchorElTransferStatus}
              keepMounted
              open={Boolean(anchorElTransferStatus)}
              onClose={handleCloseTransferStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus('transfer', status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '7px' }}
              id="menu-transaction-status-type"
              anchorEl={anchorElTransactionStatus}
              keepMounted
              open={Boolean(anchorElTransactionStatus)}
              onClose={handleCloseTransactionStatusMenu}
            >
              {Object.values(OrderStatus).map((status) => (
                <MenuItem key={status} onClick={() => chooseStatus('transaction', status)}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Stack>
    </Container>
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<Record<string, unknown>>, CustomProps>((props, ref) => {
  const { onChange, name, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator={name !== 'orderId'}
      isNumericString
      allowNegative={false}
      decimalScale={name === 'orderId' ? 0 : 2}
      fixedDecimalScale
    />
  )
})

NumberFormatCustom.displayName = 'NumberFormatCustom'

export default Request
