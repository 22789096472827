import { Box, Typography } from '@mui/material'
import React from 'react'
import CrudButtons from '../CrudButtons'
import { CrudTypes } from '../CrudButtons/constants/CrudTypes'
import { DropDownTypes } from '../dropDown/constants/DropDownTypes'

interface ActionCardProps {
  headers: string
  crud: {
    create: { isPresent: boolean; isDropdown: boolean }
    get: { isPresent: boolean; isDropdown: boolean }
    update: { isPresent: boolean; isDropdown: boolean }
    delete: { isPresent: boolean; isDropdown: boolean }
  }
}

const ActionCard: React.FC<ActionCardProps> = ({ headers, crud }: ActionCardProps) => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        width: '70%',
        backgroundColor: 'white',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' // Add shadow here
      }}
    >
      <Typography marginBottom={2} variant="h5" fontWeight="semi-bold">
        {headers}
      </Typography>
      <Box style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        {crud.create.isPresent &&
          (crud.create.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Create} dropdownType={DropDownTypes.Create} />
          ) : (
            <CrudButtons crudType={CrudTypes.Create} />
          ))}
        {crud.get.isPresent &&
          (crud.get.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Get} dropdownType={DropDownTypes.Get} />
          ) : (
            <CrudButtons crudType={CrudTypes.Get} />
          ))}
        {crud.update.isPresent &&
          (crud.update.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Update} dropdownType={DropDownTypes.Update} />
          ) : (
            <CrudButtons crudType={CrudTypes.Update} />
          ))}
        {crud.delete.isPresent &&
          (crud.delete.isDropdown ? (
            <CrudButtons crudType={CrudTypes.Delete} dropdownType={DropDownTypes.Delete} />
          ) : (
            <CrudButtons crudType={CrudTypes.Delete} />
          ))}
      </Box>
    </Box>
  )
}

export default ActionCard
