import * as React from 'react'
import { Container } from '../../ui'
import { Stack } from '@mui/material'
import GetUpdateOrderLimits from './GetUpdateOrderLimits'

export default function OrderLimits() {
  return (
    <Container maxWidth={1500}>
      <Stack direction="column" spacing={8}>
        <GetUpdateOrderLimits />
      </Stack>
    </Container>
  )
}
