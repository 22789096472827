import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { adminGetAllOrganizations } from '../../common/api/organizations'
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid'
import * as S from './styles'
import { createOrganizationInput, getOrgByEmail, orgActionCardCrud } from './Common/data'
import { Modal } from '../../ui'
import { CrudActionContext } from '../ApiMembers/context'
import CreateOrganization from './Components/CreateOrganization'
import GetOrganization from './Components/GetOrganization'
import OrganizationActionCard from '../../common/components/OrganizationActionCard'

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Org Id', minWidth: 300 },
  { field: 'orgName', headerName: 'Org Name' },
  { field: 'email', headerName: 'Email' },
  { field: 'address', headerName: 'Address' },
  { field: 'city', headerName: 'City' },
  { field: 'stateProvince', headerName: 'State/Province' },
  { field: 'countryCode', headerName: 'Country Code' },
  { field: 'postalCode', headerName: 'Postal Code' },
  { field: 'createdAt', headerName: 'Create At' },
  { field: 'updatedAt', headerName: 'Updated At' },
  { field: 'topicARN', headerName: 'Topic ARN' }
]

const Organizations: React.FC = () => {
  const [modals, setModals] = useState({
    createOrg: false,
    getOrg: false
  })

  // Tracks the active modal
  const [active, setActive] = useState<string>('')
  const [initState, setInitState] = useState<boolean>(true)
  // Forces component remount
  const [formKey, setFormKey] = useState<number>(0)

  const handleOpen = (modal: string) => {
    setInitState(false)
    setModals((s) => ({ ...s, [modal]: true }))
    setActive(modal)
  }

  const handleClose = (modal: string) => {
    setInitState(true)
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleButtonClick = (crudType: CrudTypes) => {
    if (crudType === CrudTypes.Create) {
      handleOpen('createOrg')
    } else if (crudType === CrudTypes.Get) {
      handleOpen('getOrg')
    }
  }

  // make the request here
  const handleCrudClick = () => {
    handleClose(active)
  }

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1)
  }, [initState])

  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    const fetchAllOrganization = async () => {
      try {
        setLoading(true)
        const response = await adminGetAllOrganizations()
        setRows(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchAllOrganization()
  }, [])
  return (
    <Stack gap={10} display={'flex'}>
      <Box>
        <Box bgcolor="#BC19FF" color="white" p={2} textAlign="center" fontSize="24px" fontWeight="bold">
          Note: API Operations
        </Box>
      </Box>
      <S.StyledContainer
        maxWidth="xl"
        fixed
        sx={{
          p: 2,
          borderRadius: 1,
          width: '70%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Stack>
          <CrudActionContext.Provider value={handleButtonClick}>
            <Box display="flex" alignItems="center" marginBottom="12px">
              <OrganizationActionCard crud={orgActionCardCrud} />
            </Box>
            <Modal
              open={modals.createOrg}
              onClose={() => handleClose('createOrg')}
              title="Creating an Organization"
              maxWidth="lg"
              fullWidth
              keepMounted
            >
              <CrudActionContext.Provider value={handleCrudClick}>
                <CreateOrganization key={formKey} entityInput={createOrganizationInput} />
              </CrudActionContext.Provider>
            </Modal>
            <Modal
              open={modals.getOrg}
              onClose={() => handleClose('getOrg')}
              title="Get Organization by email"
              maxWidth="lg"
              fullWidth
              keepMounted
            >
              <CrudActionContext.Provider value={handleCrudClick}>
                <GetOrganization key={formKey} formInputs={getOrgByEmail} />
              </CrudActionContext.Provider>
            </Modal>
          </CrudActionContext.Provider>
        </Stack>
        <div>
          <DataGrid
            loading={loading}
            autoHeight
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }} // Hide the print button
          />
        </div>
      </S.StyledContainer>
    </Stack>
  )
}

export default Organizations
