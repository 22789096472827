import { CardInput } from 'src/common/components/EntityCardGroup/EntityCardGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { MemberCardHeaderInput, MemberCardInput } from '../types'

export const memberCardInfoInput: CardInput[] = [
  {
    formTitle: 'Member Id',
    formId: 'id',
    formType: FormType.input,
    formValue: 'Member Id'
  },
  {
    formTitle: 'Organization Id',
    formId: 'orgId',
    formType: FormType.input,
    formValue: 'Organization Id'
  },
  {
    formTitle: 'Business Name',
    formId: 'businessName',
    formType: FormType.input,
    formValue: 'Business Name'
  },
  {
    formTitle: 'Email',
    formId: 'email',
    formType: FormType.input,
    formValue: 'Email'
  },
  {
    formTitle: 'First Name',
    formId: 'firstName',
    formType: FormType.input,
    formValue: 'First Name'
  },
  {
    formTitle: 'Last Name',
    formId: 'lastName',
    formType: FormType.input,
    formValue: 'Last Name'
  },
  {
    formTitle: 'Address',
    formId: 'address',
    formType: FormType.input,
    formValue: 'Address'
  },
  {
    formTitle: 'City',
    formId: 'city',
    formType: FormType.input,
    formValue: 'Toronto'
  },
  {
    formTitle: 'State/Province',
    formId: 'stateProvince',
    formType: FormType.input,
    formValue: 'State/Province'
  },
  {
    formTitle: 'Country',
    formId: 'countryCode',
    formType: FormType.input,
    formValue: 'Country'
  },
  {
    formTitle: 'Postal Code',
    formId: 'postalCode',
    formType: FormType.input,
    formValue: 'Postal Code'
  },
  {
    formTitle: 'Account Type',
    formId: 'accountType',
    formType: FormType.input,
    formValue: 'Account Type'
  },
  {
    formTitle: 'Account ID',
    formId: 'accountId',
    formType: FormType.input,
    formValue: 'Account ID'
  },
  {
    formTitle: 'Created At',
    formId: 'createdAt',
    formType: FormType.input,
    formValue: '2023-11-03T21:51:55.865Z'
  },
  {
    formTitle: 'Updated At',
    formId: 'updatedAt',
    formType: FormType.input,
    formValue: '2023-11-03T21:51:55.865Z'
  }
]

export const memberCardBankInput: CardInput[][] = [
  [
    {
      formTitle: 'Bank Details ID',
      formId: 'id',
      formType: FormType.input,
      formValue: 'Bank Details ID'
    },
    {
      formTitle: 'Country',
      formId: 'country',
      formType: FormType.input,
      formValue: 'Country'
    },
    {
      formTitle: 'Bank Details Name',
      formId: 'name',
      formType: FormType.input,
      formValue: 'Bank Details Name'
    },
    {
      formTitle: 'Account Holder Name',
      formId: 'accHolderName',
      formType: FormType.input,
      formValue: 'Account Holder Name'
    },
    {
      formTitle: 'Routing Number',
      formId: 'routingNum',
      formType: FormType.input,
      formValue: 'routing Number'
    },
    {
      formTitle: 'Account Number',
      formId: 'accountNum',
      formType: FormType.input,
      formValue: 'Account Number'
    },
    {
      formTitle: 'ACH Code',
      formId: 'achCode',
      formType: FormType.input,
      formValue: 'ACH Code'
    },
    {
      formTitle: 'SWIFT/BIC Code',
      formId: 'swiftBicCode',
      formType: FormType.input,
      formValue: 'SWIFT/BIC Code'
    },
    {
      formTitle: 'Primary Bank',
      formId: 'isPrimary',
      formType: FormType.input,
      formValue: 'Primary Bank'
    },
    {
      formTitle: 'Created At',
      formId: 'createdAt',
      formType: FormType.input,
      formValue: 'Created At'
    },
    {
      formTitle: 'Updated At',
      formId: 'updatedAt',
      formType: FormType.input,
      formValue: 'Created At'
    }
  ]
]

export const memberCardBlockchainInput: CardInput[][] = [
  [
    {
      formTitle: 'Blockchain Details ID',
      formId: 'id',
      formType: FormType.input,
      formValue: 'Blockchain Details ID'
    },
    {
      formTitle: 'Blockchain Details NAme',
      formId: 'name',
      formType: FormType.input,
      formValue: 'Blockchain Details Name'
    },
    {
      formTitle: 'Address',
      formId: 'address',
      formType: FormType.input,
      formValue: 'Address'
    },
    {
      formTitle: 'Chain',
      formId: 'chain',
      formType: FormType.input,
      formValue: 'Chain'
    },
    {
      formTitle: 'Type',
      formId: 'type',
      formType: FormType.input,
      formValue: 'Type'
    },
    {
      formTitle: 'External Memo',
      formId: 'externalMemo',
      formType: FormType.input,
      formValue: 'External Memo'
    },
    {
      formTitle: 'Created At',
      formId: 'createdAt',
      formType: FormType.input,
      formValue: 'Created At'
    },
    {
      formTitle: 'Updated At',
      formId: 'updatedAt',
      formType: FormType.input,
      formValue: 'Updated At'
    }
  ]
]

export const memberCardBillPayInput: CardInput[][] = [
  [
    {
      formTitle: 'Billpay ID',
      formId: 'id',
      formType: FormType.input,
      formValue: 'ID'
    },
    {
      formTitle: 'Payee Name',
      formId: 'payeeName',
      formType: FormType.input,
      formValue: 'Payee Name'
    },
    {
      formTitle: 'Payee Code',
      formId: 'payeeCode',
      formType: FormType.input,
      formValue: 'Payee Code'
    },
    {
      formTitle: 'Payee Account Number',
      formId: 'payeeAccountNumber',
      formType: FormType.input,
      formValue: 'Payee Account Number'
    },
    {
      formTitle: 'Created At',
      formId: 'createdAt',
      formType: FormType.input,
      formValue: 'Createad At'
    },
    {
      formTitle: 'Updated At',
      formId: 'updatedAt',
      formType: FormType.input,
      formValue: 'Updated At'
    }
  ]
]

export const memberCardInput: MemberCardInput = {
  info: memberCardInfoInput,
  bank: memberCardBankInput,
  blockchain: memberCardBlockchainInput,
  billPay: memberCardBillPayInput
}

export const memberCardHeaderInput: MemberCardHeaderInput = {
  infoHeader: 'Member Details',
  bankHeader: 'Bank Details',
  blockchainHeader: 'Blockchain Details',
  billPayHeader: 'Bill Pay Details'
}
