import * as React from 'react'
import { useState } from 'react'
import {
  DataGrid,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbar
} from '@mui/x-data-grid'
import {
  adminGetApiOrderByStatus,
  adminProcessPurchaseOrder,
  AdminProcessPurchaseOrderDTO,
  adminProcessRedeemOrder,
  AdminProcessRedeemOrderDTO,
  OrderDetailsModel,
  OrderDetailsRowModel
} from '../../common/api/apiOrders'
import { ORDER_SORT, OrderStatus } from '../../common/static'
import { Alert, Box, MenuItem, Select, Typography } from '@mui/material'
import { alertNotification } from '../../ui'
import {
  columnGroupingModel,
  orderDetailsColumns,
  transactionsDetailsColumns,
  transferDetailsColumns
} from './Table/orderDetailsStructure'
import axios from 'axios'

const GetOrder: React.FC = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100
  })
  const [data, setData] = React.useState<OrderDetailsModel[]>([])
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [loading, setLoading] = React.useState(false)
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
  const [orderType, setOrderType] = useState(OrderStatus.INITIATED as string)
  const [sortBy, setSortBy] = useState(ORDER_SORT.NEWEST as string)
  const [rowLen, setRowLen] = useState(0)

  const handleOrderTypeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setOrderType(event.target.value)
  }
  const handleSortByChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSortBy(event.target.value)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const processOrder = async (updatedRow: OrderDetailsRowModel) => {
    try {
      setLoading(true)
      if (updatedRow.type === 'REDEEM') {
        const processRedeemOrderData: AdminProcessRedeemOrderDTO = {
          orderId: updatedRow.id,
          transferReferenceId: updatedRow['transfers[0].referenceId']
        }
        await adminProcessRedeemOrder(processRedeemOrderData)
        alertNotification(`Purchase Order ${updatedRow.id} updated successfully`, 'success')
      } else if (updatedRow.type === 'PURCHASE') {
        const processPurchaseOrderData: AdminProcessPurchaseOrderDTO = {
          orderId: updatedRow.id,
          transferReferenceId: updatedRow['transfers[0].referenceId']
        }
        await adminProcessPurchaseOrder(processPurchaseOrderData)
        alertNotification(`Redeem Order ${updatedRow.id} updated successfully`, 'success')
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
        alertNotification(`Error updating Order ${updatedRow.id}: ${error.response.data.message}`)
      } else {
        alertNotification(`Error updating Order ${updatedRow.id}`)
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    let active = true

    const fetchOrderData = async () => {
      setLoading(true)
      try {
        const response = await adminGetApiOrderByStatus(
          orderType,
          sortBy,
          paginationModel.page + 1,
          paginationModel.pageSize
        )
        setData(response.data.data)
        setRowLen(response.data.totalCount)
        if (active) {
          setRows(response.data.data)
        }
      } catch (error) {
        alertNotification('Error fetching orders')
      } finally {
        setLoading(false)
      }
    }

    fetchOrderData()

    return () => {
      active = false
    }
  }, [orderType, paginationModel.page, sortBy])

  const handleProcessRowUpdateError = (error: Error) => {
    alertNotification('Error updating row:' + error)
  }

  return (
    <Box style={{ minHeight: '100%', width: '100%' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: '100%',
          width: '100%'
        }}
      >
        <div style={{ marginRight: 10 }}>
          <Typography variant="subtitle1" gutterBottom>
            Select Order Type:
          </Typography>
          <Select value={orderType} onChange={handleOrderTypeChange} style={{ marginBottom: 10 }}>
            {Object.values(OrderStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Select Sort By:
          </Typography>
          <Select value={sortBy} onChange={handleSortByChange} style={{ marginBottom: 10 }}>
            {Object.values(ORDER_SORT).map((sortBy) => (
              <MenuItem key={sortBy} value={sortBy}>
                {sortBy}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Box>
      <Alert severity="info" sx={{ marginBottom: '15px' }}>
        {'Double click on the cells to edit the data and enter to save.'}
      </Alert>
      <Alert severity="warning" sx={{ marginBottom: '15px' }}>
        {
          'For Redeem Orders: Only update order status, transfer status, transfer reference id. For Purchase Orders: Only update transfer reference id.'
        }
      </Alert>
      <DataGrid
        columns={[...orderDetailsColumns, ...transferDetailsColumns, ...transactionsDetailsColumns]}
        {...data}
        rows={rows}
        autoHeight
        pagination
        checkboxSelection
        paginationModel={paginationModel}
        rowCount={rowLen}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
        loading={loading}
        columnGroupingModel={columnGroupingModel}
        slots={{ toolbar: GridToolbar }}
        editMode="row"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={(updatedRow) => processOrder(updatedRow)}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </Box>
  )
}

export default GetOrder
