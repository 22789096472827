import React from 'react'
import ReactDOM from 'react-dom'
import AppRoutes from 'src/routes/AppRoutes'
import { Amplify } from 'aws-amplify'
import { isSdcProject } from './common/helpers/isSdcProject'

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  }
})

// Display favicon based on the project
const projectFavicon = isSdcProject() ? 'sdc-favicon.svg' : 'stablecorp-favicon.svg'
const link: HTMLLinkElement = document.createElement('link')
link.rel = 'icon'
link.href = `public/${projectFavicon}`
document.head.appendChild(link)

// Display title based on the project
const title: HTMLTitleElement = document.createElement('title')
title.text = isSdcProject() ? 'SDC' : 'Grapes'
document.head.appendChild(title)

ReactDOM.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
  document.getElementById('root')
)
