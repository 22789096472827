import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { ValidationError } from 'ajv'
import React, { useEffect, useState } from 'react'
import {
  adminUpdateMember,
  AdminUpdateMemberDTO,
  BankDetailsModel,
  BillPayDetailsModel,
  BlockchainDetailsModel,
  MemberModel,
  UpdateBankDetailsDTO,
  UpdateBillPayDetailsDTO,
  UpdateBlockchainDetailsDTO
} from 'src/common/api/organizations'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import EntityCardGroup, { CardInput } from 'src/common/components/EntityCardGroup/EntityCardGroup'
import { alertNotification } from 'src/ui'
import { ReactComponent as EditIcon } from '../../common/assets/EditIcon.svg'
import { MemberCardHeaderInput, MemberCardInput } from './types'

interface MemberCardProps {
  entityId: string
  entityCardInput: MemberCardInput
  headers: MemberCardHeaderInput
  onButtonClick?: (crudType: CrudTypes, dropDownType?: string) => void
}

interface UpdateMemberState {
  info: MemberModel
  banks: BankDetailsModel[]
  billPays: BillPayDetailsModel[]
  blockchains: BlockchainDetailsModel[]
}

type DetailsModel = MemberModel | BankDetailsModel | BlockchainDetailsModel | BillPayDetailsModel

const MemberCard: React.FC<MemberCardProps> = ({ entityId, entityCardInput, headers }: MemberCardProps) => {
  const memberInputInfo: CardInput[] = entityCardInput.info
  const bankCardDetails: CardInput[][] = entityCardInput.bank
  const blockchainCardDetails: CardInput[][] = entityCardInput.blockchain // this is not being updated
  const billPayCardDetails: CardInput[][] = entityCardInput.billPay
  const memberHeader: string = headers.infoHeader
  const bankHeader: string = headers.bankHeader
  const blockchainHeader: string = headers.blockchainHeader
  const billPayHeader: string = headers.billPayHeader

  const convertFormInput = (model: DetailsModel, form: CardInput[]): DetailsModel => {
    const updatedModel = { ...model } // Create a copy of the original model
    form.forEach((data) => {
      const { formId, formValue } = data
      const requiredFields: string[] = ['id', 'orgId']
      if (formId && Object.prototype.hasOwnProperty.call(updatedModel, formId) && requiredFields.includes(formId)) {
        updatedModel[formId as keyof DetailsModel] = String(formValue)
      }
    })
    return updatedModel // Return the updated model
  }

  // empty models for response data
  const memberModel: MemberModel = {
    id: '',
    orgId: '',
    accountId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    businessName: '',
    address: '',
    postalCode: '',
    countryCode: '',
    city: '',
    stateProvince: '',
    accountType: '',
    createdAt: '',
    updatedAt: ''
  }

  const bankDetailsModel: BankDetailsModel[] = bankCardDetails.map(() => ({
    id: 0,
    country: '',
    name: '',
    routingNum: '',
    institutionNum: '',
    transitNum: '',
    accountNum: '',
    accHolderName: '',
    isPrimary: true,
    achCode: '',
    swiftBicCode: ''
  }))

  const billPayDetailsModel: BillPayDetailsModel[] = billPayCardDetails.map(() => ({
    id: 0,
    payeeName: '',
    payeeCode: '',
    payeeAccountNumber: ''
  }))

  const blockchainDetailsModel: BlockchainDetailsModel[] = blockchainCardDetails.map(() => ({
    id: 0,
    name: '',
    chain: '',
    address: '',
    externalMemo: ''
  }))

  // stores card data in payload form for [PUT]
  const [memberInfo, setMemberInfo] = useState<MemberModel>(memberModel)
  const [memberBank, setMemberBank] = useState<BankDetailsModel[]>(bankDetailsModel)
  const [memberBillPay, setMemberBillPay] = useState<BillPayDetailsModel[]>(billPayDetailsModel)
  const [memberBlockchain, setMemberBlockchain] = useState<BlockchainDetailsModel[]>(blockchainDetailsModel)

  const [edit, setEdit] = useState<boolean>(false)

  const emptyUpdateMemberState: UpdateMemberState = {
    info: memberModel,
    banks: bankDetailsModel,
    billPays: billPayDetailsModel,
    blockchains: blockchainDetailsModel
  }

  // consolidated payload data for [PUT]
  const [memberState, setMemberState] = useState<UpdateMemberState>(emptyUpdateMemberState)

  const [submitting, setSubmitting] = useState(false)

  const removeEmptyStringProperties = (memberState: UpdateMemberState): AdminUpdateMemberDTO => {
    // res is of type AdminUpdateMemberDTO excl bank, billpay and blockchain
    const res: AdminUpdateMemberDTO = {
      orgId: memberState.info.orgId,
      memberId: memberState.info.id
    }
    for (const key in memberState.info) {
      if (memberState.info[key as keyof MemberModel] !== '') {
        res[key as keyof AdminUpdateMemberDTO] = memberState.info[key as keyof MemberModel]
      }
    }

    const isBankChanged = isChanged(memberState.banks, ['id', 'isPrimary'])
    const bankRes = memberState.banks
      .filter((_bank, i) => isBankChanged[i])
      .map((bank) => {
        const bankObj: UpdateBankDetailsDTO = {
          id: 0,
          country: '',
          name: '',
          accHolderName: '',
          routingNum: '',
          accountNum: '',
          achCode: '',
          swiftBicCode: ''
        }
        Object.keys(bank).forEach((key: string) => {
          const value = bank[key as keyof BankDetailsModel]
          if (value !== '' && value !== undefined) {
            bankObj[key] = key === 'id' ? Number(value) : value
          }
        })
        return bankObj
      })
    res.updateBankDetails = bankRes

    const isBillPayChanged = isChanged(memberState.billPays, ['id'])
    const billPayRes = memberState.billPays
      .filter((_billPay, i) => isBillPayChanged[i])
      .map((billPay) => {
        const billPayObj: UpdateBillPayDetailsDTO = {
          id: 0,
          payeeName: '',
          payeeCode: '',
          payeeAccountNumber: ''
        }
        Object.keys(billPay).forEach((key: string) => {
          const value = billPay[key as keyof BillPayDetailsModel]
          if (value !== '' && value !== undefined) {
            billPayObj[key] = key === 'id' ? Number(value) : value
          }
        })
        return billPayObj
      })
    res.updateBillPayDetails = billPayRes

    const isBlockchainChanged = isChanged(memberState.blockchains, ['id'])
    const blockchainRes = memberState.blockchains
      .filter((_blockchain, i) => isBlockchainChanged[i])
      .map((blockchain) => {
        const blockchainObj: UpdateBlockchainDetailsDTO = {
          id: 0,
          name: '',
          address: '',
          chain: ''
        }
        Object.keys(blockchain).forEach((key: string) => {
          const value = blockchain[key as keyof BlockchainDetailsModel]
          if (value !== '' && value !== undefined) {
            blockchainObj[key] = key === 'id' ? Number(value) : value
          }
        })
        return blockchainObj
      })
    res.updateBlockchainDetails = blockchainRes

    return res
  }

  function isChanged(obj: DetailsModel[], exceptions: string[]): boolean[] {
    return obj.map((element) => {
      return Object.keys(element).some(
        (key) => !exceptions.includes(key) && String(element[key as keyof DetailsModel]) !== ''
      )
    })
  }

  const handleUpdateMemberDetailsRequest = async () => {
    try {
      const updatedInfo: AdminUpdateMemberDTO = removeEmptyStringProperties(memberState)
      setSubmitting(true)
      const response = await adminUpdateMember(updatedInfo)
      if (response) {
        alertNotification(`Member has been updated successfully`, 'success')
      }
      setSubmitting(false)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
      setSubmitting(false)
    }
  }

  const toggleEdit = () => {
    setEdit(!edit)
  }

  useEffect(() => {
    convertFormInput(memberModel, memberInputInfo)
    setMemberInfo(memberModel)
    bankCardDetails.map((bankCardDetail, index) => convertFormInput(bankDetailsModel[index], bankCardDetail))
    setMemberBank(bankDetailsModel)
    blockchainCardDetails.map((blockchainCardDetail, index) =>
      convertFormInput(blockchainDetailsModel[index], blockchainCardDetail)
    )
    setMemberBlockchain(blockchainDetailsModel)
    billPayCardDetails.map((billPayCardDetail, index) =>
      convertFormInput(billPayDetailsModel[index], billPayCardDetail)
    )
    setMemberBillPay(billPayDetailsModel)
  }, [edit])

  useEffect(() => {
    setMemberState({
      info: memberInfo,
      banks: memberBank,
      billPays: memberBillPay,
      blockchains: memberBlockchain
    })
  }, [memberBank, memberInfo, memberBillPay, memberBlockchain])

  return (
    <Box sx={{ p: 2, border: '1px solid grey', borderRadius: 1, marginBottom: 2, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          {entityId}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={() => toggleEdit()}>
            <EditIcon />
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <EntityCardGroup
            header={memberHeader}
            cardInputs={memberInputInfo}
            isEdit={edit}
            setInfo={setMemberInfo}
            info={memberInfo}
          />
        </Grid>
        <Grid item md={6}>
          {bankCardDetails.map((bankDetail, index) => (
            <>
              <EntityCardGroup
                key={index}
                header={bankHeader}
                cardInputs={bankDetail}
                isEdit={edit}
                setInfo={setMemberBank}
                info={memberBank}
                index={index}
              />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            </>
          ))}
        </Grid>
        <Grid item md={6}>
          {blockchainCardDetails.map((blockchainDetail, index) => (
            <>
              <EntityCardGroup
                key={index}
                header={blockchainHeader}
                cardInputs={blockchainDetail}
                isEdit={edit}
                setInfo={setMemberBlockchain}
                info={memberBlockchain}
                index={index}
              />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            </>
          ))}
        </Grid>
        <Grid item md={6}>
          {billPayCardDetails.map((billPayDetail, index) => (
            <>
              <EntityCardGroup
                key={index}
                header={billPayHeader}
                cardInputs={billPayDetail}
                isEdit={edit}
                setInfo={setMemberBillPay}
                info={memberBillPay}
                index={index}
              />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            </>
          ))}
        </Grid>
      </Grid>
      {edit && (
        <LoadingButton loading={submitting} variant="contained" onClick={handleUpdateMemberDetailsRequest} fullWidth>
          Update member
        </LoadingButton>
      )}
    </Box>
  )
}

export default MemberCard
