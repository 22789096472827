export enum DropDownTypes {
  Chain = 'Chain',
  Crypto = 'Crypto',
  PaymentMethod = 'Payment Method',
  KybStatus = 'Kyb Status',
  TxStatus = 'Tx Status',
  OrderStatus = 'Order Status',
  Country = 'Country',
  Create = 'Create',
  Get = 'Get',
  Update = 'Update',
  Delete = 'Delete',
  Currency = 'Currency'
}
