export const orgInfoSchema = {
  type: 'object',
  properties: {
    orgId: { type: 'string' },
    orgName: { type: 'string' },
    email: { type: 'string', format: 'email' },
    address: { type: 'string' },
    postalCode: { type: 'string' },
    countryCode: { type: 'string' },
    city: { type: 'string' },
    stateProvince: { type: 'string' }
  },
  required: ['orgId', 'orgName', 'email', 'address', 'postalCode', 'countryCode', 'city', 'stateProvince']
}

export const blockchainInfoSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      address: { type: 'string' },
      chain: { type: 'string', enum: ['ETHEREUM', 'ALGORAND', 'STELLAR'] }
    },
    required: ['address', 'chain']
  }
}

export const memberInfoSchema = {
  type: 'object',
  properties: {
    businessName: { type: 'string' },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    email: { type: 'string', format: 'email' },
    address: { type: 'string' },
    postalCode: { type: 'string' },
    countryCode: { type: 'string' },
    city: { type: 'string' },
    stateProvince: { type: 'string' }
  },
  required: ['orgId', 'email', 'address', 'postalCode', 'countryCode', 'city', 'stateProvince']
}
