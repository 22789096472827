import { LoadingButton } from '@mui/lab'
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { createFintracReport, deleteFintracReport, updateFintracReport } from 'src/common/api/fintracReport/data'
import { DeleteFintracReportDTO } from 'src/common/api/fintracReport/dto'
import { Container, alertNotification } from 'src/ui'
import { GetReportCode } from './GetReportCode'

interface ActionFormState {
  action: 'send-report' | 'update-report' | 'delete-report' | ''
  sendParams?: {
    reportType?: string
    file?: File
  }
  updateParams?: {
    file?: File
  }
  deleteParams?: {
    json?: string
  }
}

const Fintrac: React.FC = () => {
  const [actionFormState, setActionFormState] = useState<ActionFormState>({
    action: '',
    sendParams: undefined,
    updateParams: undefined,
    deleteParams: {
      json: `{
        "reportDetails": {
          "reportTypeCode": 14,
          "submitTypeCode": 5,
          "reportingEntityNumber": #add number here,
          "submittingReportingEntityNumber": #add number here,
          "reportingEntityReportReference": "#add string here",
          "reportSubmitReasonCode": 3
        }
      }`
    }
  })
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const { action } = actionFormState

  const handleExecute = async () => {
    try {
      setSubmitting(true)
      let res
      if (action === 'send-report') {
        res = await createFintracReport(actionFormState.sendParams?.reportType, actionFormState.sendParams?.file)
      } else if (action === 'update-report') {
        res = await updateFintracReport(actionFormState.updateParams?.file)
      } else {
        res = await deleteFintracReport(actionFormState.deleteParams?.json as unknown as DeleteFintracReportDTO)
      }
      setSubmitting(false)
      if (!res) throw new Error('Something went wrong')
      if (actionFormState.action !== 'delete-report') {
        alertNotification(JSON.stringify(res.data), 'success', false)
        setSuccessMessage(`Report sent successful!y`)
      } else {
        setSuccessMessage(`Delete Report successful!`)
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error, 'error')
        const axiosError = error as AxiosError
        const errorMsg = (axiosError.response?.data as { message?: string })?.message
        alertNotification(`An error occurred, ${JSON.stringify(errorMsg)}`, 'error', false)
      }
      setSubmitting(false)
    }
  }

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setActionFormState({
      action: value as '' | 'send-report' | 'update-report' | 'delete-report',
      sendParams: undefined,
      updateParams: undefined,
      deleteParams: {
        json: `{
          "reportDetails": {
            "reportTypeCode": 14,
            "submitTypeCode": 5,
            "reportingEntityNumber": #add number here,
            "submittingReportingEntityNumber": #add number here,
            "reportingEntityReportReference": "#add string here",
            "reportSubmitReasonCode": 3
          }
        }`
      }
    })
  }

  const handleParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const reportCode = GetReportCode(value)
    setActionFormState((prevState) => {
      if (prevState.action === 'send-report') {
        return {
          ...prevState,
          sendParams: {
            ...prevState.sendParams,
            reportType: reportCode
          }
        }
      } else if (prevState.action === 'update-report') {
        return {
          ...prevState,
          updateParams: {
            ...prevState.updateParams
          }
        }
      } else if (prevState.action === 'delete-report') {
        return {
          ...prevState,
          deleteParams: {
            ...prevState.deleteParams,
            json: value
          }
        }
      } else {
        return {
          ...prevState,
          [name]: reportCode,
          sendParams:
            name === 'action' && value === 'send-report' ? { reportType: '0', file: undefined } : prevState.sendParams,
          updateParams: name === 'action' && value === 'update-report' ? { file: undefined } : prevState.updateParams,
          deleteParams: name === 'action' && value === 'delete-report' ? { json: '' } : prevState.deleteParams
        }
      }
    })
  }

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files ? event.target.files[0] : undefined
    setActionFormState((prevState) => {
      const newState = { ...prevState }
      switch (prevState.action) {
        case 'send-report':
          newState.sendParams = {
            ...prevState.sendParams,
            file: file
          }
          break
        case 'update-report':
          newState.updateParams = {
            ...prevState.updateParams,
            file: file
          }
          break
        default:
          break
      }
      alertNotification('File uploaded sucessfully', 'success')
      return newState
    })
  }

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <>
          <Typography variant="h6">Fintrac Reports</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {'Select Action'}
            <TextField
              name="action"
              select
              placeholder="Select Flag"
              onChange={handleActionChange}
              value={action}
              fullWidth
            >
              <MenuItem value="send-report">Send Report</MenuItem>
              <MenuItem value="update-report">Update Report</MenuItem>
              <MenuItem value="delete-report">Delete Report</MenuItem>
            </TextField>
          </Stack>
          {actionFormState.action === 'send-report' && (
            <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                {'Report Type'}
                <TextField
                  name="reportType"
                  select
                  placeholder="Select Flag"
                  onChange={handleParamsChange}
                  value={actionFormState.sendParams?.reportType}
                  fullWidth
                >
                  <MenuItem value="lvctr">Large Virtual Currency Transaction Report (LVCTR)</MenuItem>
                  <MenuItem value="str">Suspicious Transaction Report (STR)</MenuItem>
                  <MenuItem value="lctr">Large Cash Transaction Report (LCTR)</MenuItem>
                  <MenuItem value="eftr">Electronic Funds Transfer Report (EFTR)</MenuItem>
                  <MenuItem value="cdr">Casino Disbursement Report (CDR)</MenuItem>
                </TextField>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
                {'Upload File'}
                <Button variant={`${actionFormState.sendParams?.file ? 'contained' : 'outlined'}`} component="label">
                  Upload File
                  <input type="file" hidden onChange={handleFileUpload} />
                </Button>
              </Stack>
            </Box>
          )}
          {actionFormState.action === 'update-report' && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
              {'Upload File'}
              <Button variant={`${actionFormState.updateParams?.file ? 'contained' : 'outlined'}`} component="label">
                Upload File
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
            </Stack>
          )}
          {actionFormState.action === 'delete-report' && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {'JSON'}
              <TextField
                name="json"
                placeholder="Enter JSON"
                onChange={handleParamsChange}
                value={actionFormState.deleteParams?.json}
                fullWidth
                multiline
                sx={{ width: '87%' }}
              />
            </Stack>
          )}
          <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleExecute}>
            Execute
          </LoadingButton>
        </>
      </Stack>
    </Container>
  )
}

export default Fintrac
