import { Box, styled } from '@mui/system'

interface DashboardContainerProps {
  notification: boolean
}

export const DashboardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'notification'
})<DashboardContainerProps>({
  display: 'flex',
  alignItems: 'stretch',
  height: '100Vh'
})

export const SideBar = styled('div')(({ theme }) => ({
  maxWidth: '250px',
  backgroundColor: theme.palette.background.paper,
  overflowY: 'auto',
  padding: '40px 0',
  boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.04), 0px 5px 12px rgba(0, 0, 0, 0.04), 0px 7px 12px rgba(0, 0, 0, 0.04)'
}))

export const RightSection = styled('div')({
  flexGrow: 1,
  overflowY: 'auto'
})
