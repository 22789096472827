import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { FormType } from 'src/common/components/dropDown/constants/FormType'

export const deleteMemberData: FormInput[] = [
  {
    formTitle: 'Organization ID',
    formId: 'orgId',
    formType: FormType.input,
    formValue: 'Organization ID'
  },
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  }
]

export const deleteMemberBlockchainData: FormInput[] = [
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  },
  {
    formTitle: 'Blockchain Details ID',
    formId: 'blockchainDetailsId',
    formType: FormType.input,
    formValue: 'Blockchain Details ID'
  }
]

export const deleteMemberBankData: FormInput[] = [
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  },
  {
    formTitle: 'Bank Details ID',
    formId: 'bankingDetailsId',
    formType: FormType.input,
    formValue: 'Bank Details ID'
  }
]

export const deleteMemberBillpayData: FormInput[] = [
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  },
  {
    formTitle: 'Billpay Details ID',
    formId: 'billPayDetailsId',
    formType: FormType.input,
    formValue: 'Billpay Details ID'
  }
]
