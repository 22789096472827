import httpClient from 'src/common/axios/config'
import { GetWalletsModel, WalletModel, WalletAnalytics } from './models'

interface CreateCustodialWalletDTO {
  userId: string
  address: string
  name: string
  chain: string
  externalId: string
}

interface GetWalletsParams {
  userId: string
}

export const createCustodialWallet = (params: CreateCustodialWalletDTO) =>
  httpClient.post<WalletModel>('/wallets/admin/custodial', params)

export const fetchWallets = (params: GetWalletsParams) =>
  httpClient.get<GetWalletsModel[]>('/wallets/admin', { params })

export const fetchWalletAnalytics = () => httpClient.get<WalletAnalytics[]>('/wallets/admin/analytics')
