import { BankingCountry, ChainName } from 'src/common/static'
import { DropDownTypes } from './DropDownTypes'

export const ChainSelection = [ChainName.ALGORAND, ChainName.ETHEREUM, ChainName.STELLAR]

export const CryptoSelection = ['QCAD', 'USDC']

export const CurrencySelection = ['USD', 'CAD']

export const KybStatusSelection = ['UNINITIATED', 'PENDING', 'APPROVED', 'REJECTED', 'ESCALATED']

export const PaymentMethodSelection = ['E_TRANSFER', 'EFT', 'BILL_PAY', 'FIAT_PAYOUT', 'FIAT_OTHER', 'CRYPTO_TRANSFER']

export const TxStatusSelection = ['INITIATED', 'PROCESSED', 'CONFIRMED', 'CANCELLED', 'EXPIRED', 'FAILED', 'DECLINED']

export const OrderStatusSelection = [
  'NEW',
  'INITIATED',
  'PROCESSED',
  'CANCELLED',
  'QUEUED',
  'EXPIRED',
  'DECLINED',
  'FAILED',
  'INCORRECT_RECIPIENT'
]

export const CountrySelection = [BankingCountry.CAN, BankingCountry.US]

export const CreateMemberSelection = ['Member', 'Bank Details', 'Blockchain Details', 'Bill Pay Details']

export const GetMemberSelection = ['Member ID', 'Org ID']

export const UpdateOrderSelection = ['Process Redeem', 'Process Purchase']

export const DeleteMemberSelection = ['Member', 'Bank Details', 'Blockchain Details', 'Bill Pay Details']

export const getDropDownSelections = (type: DropDownTypes): string[] => {
  switch (type) {
    case DropDownTypes.Chain:
      return ChainSelection
    case DropDownTypes.TxStatus:
      return TxStatusSelection
    case DropDownTypes.OrderStatus:
      return OrderStatusSelection
    case DropDownTypes.PaymentMethod:
      return PaymentMethodSelection
    case DropDownTypes.Crypto:
      return CryptoSelection
    case DropDownTypes.Create:
      return CreateMemberSelection
    case DropDownTypes.Get:
      return GetMemberSelection
    case DropDownTypes.Update:
      return UpdateOrderSelection
    case DropDownTypes.Delete:
      return DeleteMemberSelection
    case DropDownTypes.Country:
      return CountrySelection
    case DropDownTypes.KybStatus:
      return KybStatusSelection
    case DropDownTypes.Currency:
      return CurrencySelection
    default:
      return []
  }
}
