import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ActionCard from 'src/common/components/ActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { getMemberByMemberIdData, getMemberByOrgIdData, membersActionCardCrud } from 'src/pages/ApiMembers/Common/data'
import { Modal } from 'src/ui'
import {
  createMemberBankDetailsData,
  createMemberBillpayData,
  createMemberBlockchainDetailsData,
  createMemberData
} from './Create'
import CreateMemberBankDetails from './Create/CreateBankDetails'
import CreateMemberBillPayDetails from './Create/CreateBillPayDetails'
import CreateMemberBlockchainDetails from './Create/CreateBlockchainDetails'
import CreateMember from './Create/CreateMember'
import { deleteMemberBankData, deleteMemberBillpayData, deleteMemberBlockchainData, deleteMemberData } from './Delete'
import DeleteMember from './Delete/DeleteMember'
import DeleteMemberBankDetails from './Delete/DeleteMemberBankDetails'
import DeleteMemberBillPayDetails from './Delete/DeleteMemberBillPayDetails'
import DeleteMemberBlockchainDetails from './Delete/DeleteMemberBlockchainDetails'
import GetMember from './Get/GetMember'
import GetMemberByOrgId from './Get/GetMemberByOrg'
import { CrudActionContext } from './context'

const Members: React.FC = () => {
  // Stores state of active modal
  const [modals, setModals] = useState({
    createMember: false,
    createBankDetails: false,
    createBlockchainDetails: false,
    createBillPayDetails: false,
    getByMemberId: false,
    getByOrgId: false,
    updateMember: false,
    deleteMember: false,
    deleteBankDetails: false,
    deleteBlockchainDetails: false,
    deleteBillPayDetails: false
  })
  // Tracks the active modal
  const [active, setActive] = useState<string>('')
  const [initState, setInitState] = useState<boolean>(true)
  // Forces component remount
  const [formKey, setFormKey] = useState<number>(0)

  const handleOpen = (modal: string) => {
    setInitState(false)
    setModals((s) => ({ ...s, [modal]: true }))
    setActive(modal)
  }

  const handleClose = (modal: string) => {
    setInitState(true)
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleButtonClick = (crudType: CrudTypes, dropdownType?: string) => {
    if (crudType === CrudTypes.Create) {
      if (dropdownType === 'Member') {
        handleOpen('createMember')
      } else if (dropdownType === 'Bank Details') {
        handleOpen('createBankDetails')
      } else if (dropdownType === 'Blockchain Details') {
        handleOpen('createBlockchainDetails')
      } else if (dropdownType === 'Bill Pay Details') {
        handleOpen('createBillPayDetails')
      }
    } else if (crudType === CrudTypes.Get) {
      if (dropdownType === 'Member ID') {
        handleOpen('getByMemberId')
      } else if (dropdownType === 'Org ID') {
        handleOpen('getByOrgId')
      } else {
        handleOpen('getByMemberId')
      }
    } else if (crudType === CrudTypes.Update) {
      handleOpen('updateMember')
    } else if (crudType === CrudTypes.Delete) {
      if (dropdownType === 'Member') {
        handleOpen('deleteMember')
      } else if (dropdownType === 'Bank Details') {
        handleOpen('deleteBankDetails')
      } else if (dropdownType === 'Blockchain Details') {
        handleOpen('deleteBlockchainDetails')
      } else if (dropdownType === 'Bill Pay Details') {
        handleOpen('deleteBillPayDetails')
      }
    }
  }

  const handleGetClick = () => {
    handleClose(active)
  }

  const handleCrudClick = () => {
    handleClose(active)
  }

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1)
  }, [initState])

  return (
    <Box>
      <Box bgcolor="#BC19FF" color="white" p={2} textAlign="center" fontSize="24px" fontWeight="bold">
        Note: API Operations
      </Box>
      <CrudActionContext.Provider value={handleButtonClick}>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={10}>
          <ActionCard headers="Members" crud={membersActionCardCrud} />
        </Box>
        <Modal
          open={modals.createMember}
          onClose={() => handleClose('createMember')}
          title="Creating a Member"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <CreateMember key={formKey} entityData={createMemberData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.createBankDetails}
          onClose={() => handleClose('createBankDetails')}
          title="Creating a member bank details"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <CreateMemberBankDetails key={formKey} entityData={createMemberBankDetailsData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.createBlockchainDetails}
          onClose={() => handleClose('createBlockchainDetails')}
          title="Creating a member blockchain details"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <CreateMemberBlockchainDetails key={formKey} entityData={createMemberBlockchainDetailsData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.createBillPayDetails}
          onClose={() => handleClose('createBillPayDetails')}
          title="Creating a member billpay details"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <CreateMemberBillPayDetails key={formKey} entityData={createMemberBillpayData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.getByMemberId}
          onClose={() => handleClose('getByMemberId')}
          title="Member details by Member ID"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleGetClick}>
            <GetMember key={formKey} formInputs={getMemberByMemberIdData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.getByOrgId}
          onClose={() => handleClose('getByOrgId')}
          title="Get a member details by Org ID"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleGetClick}>
            <GetMemberByOrgId key={formKey} formInputs={getMemberByOrgIdData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.deleteMember}
          onClose={() => handleClose('deleteMember')}
          title="Delete a Member"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <DeleteMember key={formKey} formInputs={deleteMemberData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.deleteBankDetails}
          onClose={() => handleClose('deleteBankDetails')}
          title="Delete Member Bank Details"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <DeleteMemberBankDetails key={formKey} formInputs={deleteMemberBankData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.deleteBillPayDetails}
          onClose={() => handleClose('deleteBillPayDetails')}
          title="Delete Member Bill Pay Details"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <DeleteMemberBillPayDetails key={formKey} formInputs={deleteMemberBillpayData} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.deleteBlockchainDetails}
          onClose={() => handleClose('deleteBlockchainDetails')}
          title="Delete Member Blockchain Details"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <DeleteMemberBlockchainDetails key={formKey} formInputs={deleteMemberBlockchainData} />
          </CrudActionContext.Provider>
        </Modal>
      </CrudActionContext.Provider>
    </Box>
  )
}

export default Members
