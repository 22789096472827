export enum SUPPORTED_ASSET {
  // ALGORAND
  KCAD_ALGO_TEST = 'KCAD_TEST',
  ALGO_USDC_2V6G = 'USDC_TEST (ALGO)',
  QCAD_ALGO_TEST = 'QCAD_TEST (ALGO)',
  KCAD_ALGO = 'KCAD',
  QCAD = 'QCAD', //TODO: [[GRAP-951] placeholderfor asset id. change when available
  ALGO_USDC_UV4I = 'USDC',

  // ETHEREUM
  QCAD_ETH_TEST6_XWGB = 'QCAD_TEST (ETH)',
  USDC_ETH_TEST6_8BIK = 'USDC_TEST (ETH)',

  // STELLAR
  QCAD_XLM_TEST = 'QCAD_TEST (STELLAR)',
  XLM_USDC_T_CEKS = 'USDC_TEST (STELLAR)',
  FCAD_XLM = 'FCAD',
  FCAD_TEST = 'FCAD_XLM_TEST',

  // BASE
  BASECHAIN_ETH_TEST5 = 'BASECHAIN_ETH',
  QCAD_BASECHAIN_ETH_TEST5_NWEO = 'QCAD_TEST (BASE)'
}
