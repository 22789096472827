import { Box, Button } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { BillPayInfo } from 'src/pages/ApiMembers/types'
import { ReactComponent as AddBillPayIcon } from '../../assets/AddBillPayIcon.svg'
import FormGroup from '../FormGroup'
import { FormInput } from '../FormGroup/FormGroup'

interface BillPayDetailsFormProps {
  billPayDetails: FormInput[][]
  handleAddBillPay: () => void
  setBillPay: Dispatch<SetStateAction<BillPayInfo[]>>
  info: BillPayInfo[]
}

const BillPayDetailsForm: React.FC<BillPayDetailsFormProps> = ({
  billPayDetails,
  handleAddBillPay,
  setBillPay,
  info
}: BillPayDetailsFormProps) => {
  return (
    <Box>
      {billPayDetails.map((billPayDetail, index) => (
        <FormGroup
          key={index}
          header={index === 0 ? 'Bill Pay Details' : ''}
          formInputs={billPayDetail}
          setInfo={setBillPay}
          info={info}
          index={index}
        />
      ))}
      <Button
        variant="outlined"
        startIcon={<AddBillPayIcon />}
        onClick={handleAddBillPay}
        sx={{ fontWeight: '500', mt: 2 }}
      >
        Add another Bill Payee
      </Button>
    </Box>
  )
}

export default BillPayDetailsForm
