import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { Modal } from 'src/ui'
import { CrudActionContext } from '../ApiMembers/context'
import ApiCreateOrder from './ApiCreateOrder'
import ApiUpdatePurchaseOrder from './ApiUpdatePurchaseOrder'
import ApiUpdateRedeemOrder from './ApiUpdatedRedeemOrder'
import { createOrderInput, orderActionCardCrud, updatePurchaseOrderInput, updateRedeemOrderInput } from './data'
import * as S from './styles'
import GetOrder from './GetOrder'
import OrdersActionCard from './Common/OrdersActionCard'

const ApiOrders: React.FC = () => {
  const [modals, setModals] = useState({
    createOrder: false,
    getOrderByStatus: false,
    updateRedeemOrder: false,
    updatePurchaseOrder: false,
    updatePurchaseRefIdOrder: false,
    updateRedeemRefStatusesOrder: false
  })

  const [active, setActive] = useState<string>('')
  const [initState, setInitState] = useState<boolean>(true)

  const [formKey, setFormKey] = useState<number>(0)

  const handleOpen = (modal: string) => {
    setInitState(false)
    setModals((s) => ({ ...s, [modal]: true }))
    setActive(modal)
  }
  const handleClose = (modal: string) => {
    setInitState(true)
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleButtonClick = (crudType: CrudTypes, dropdownType?: string) => {
    if (crudType === CrudTypes.Create) {
      handleOpen('createOrder')
    } else if (crudType === CrudTypes.Update) {
      if (dropdownType === 'Process Redeem') {
        handleOpen('updateRedeemOrder')
      } else if (dropdownType === 'Process Purchase') {
        handleOpen('updatePurchaseOrder')
      }
    }
  }

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1)
  }, [initState])

  const handleCrudClick = () => {
    handleClose(active)
  }

  return (
    <Box>
      <Box bgcolor="#BC19FF" color="white" p={2} textAlign="center" fontSize="24px" fontWeight="bold">
        Note: API Operations
      </Box>
      <S.StyledContainer
        maxWidth="xl"
        fixed
        sx={{
          p: 2,
          borderRadius: 1,
          width: '70%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <CrudActionContext.Provider value={handleButtonClick}>
          <Box display="flex" alignItems="center" marginBottom="12px">
            <OrdersActionCard crud={orderActionCardCrud} />
          </Box>
        </CrudActionContext.Provider>
        <Modal
          open={modals.createOrder}
          onClose={() => handleClose('createOrder')}
          title="Creating a Purchase Order"
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <ApiCreateOrder key={formKey} entityInput={createOrderInput} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.updatePurchaseOrder}
          onClose={() => handleClose('updatePurchaseOrder')}
          title="Process Purchase Order"
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <ApiUpdatePurchaseOrder key={formKey} formInputs={updatePurchaseOrderInput} />
          </CrudActionContext.Provider>
        </Modal>
        <Modal
          open={modals.updateRedeemOrder}
          onClose={() => handleClose('updateRedeemOrder')}
          title="Process Redeem Order"
          keepMounted
        >
          <CrudActionContext.Provider value={handleCrudClick}>
            <ApiUpdateRedeemOrder key={formKey} formInputs={updateRedeemOrderInput} />
          </CrudActionContext.Provider>
        </Modal>
        <GetOrder />
      </S.StyledContainer>
    </Box>
  )
}

export default ApiOrders
