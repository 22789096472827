export enum ChainAsset {
  QCAD = 'QCAD',
  USDC = 'USDC',
  KCAD = 'KCAD',
  FCAD = 'FCAD',
  QCAD_TEST = 'QCAD_TEST',
  USDC_TEST = 'USDC_TEST',
  KCAD_TEST = 'KCAD_TEST',
  FCAD_TEST = 'FCAD_TEST'
}
