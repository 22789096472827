import { ExpandMore } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import React, { useContext, useState } from 'react'
import { dropDownChangeContext, useCrudActionContext } from 'src/pages/ApiMembers/context'
import { CrudTypes } from '../CrudButtons/constants/CrudTypes'
import { getDropDownSelections } from './constants/DropDownSelections'
import { DropDownTypes } from './constants/DropDownTypes'

interface DropDownProps {
  placeholder?: CrudTypes
  type: DropDownTypes
  handleChange?: (value: string) => void
}

const DropDown: React.FC<DropDownProps> = ({ type, placeholder, handleChange }: DropDownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dropDownVal, setDropDownVal] = useState<string>('Choose')
  const dropDownSelections: string[] = getDropDownSelections(type)
  const onButtonClick = useCrudActionContext()
  const onSelectionChange = useContext(dropDownChangeContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (option: string) => {
    if (onSelectionChange !== undefined && option !== dropDownVal) {
      onSelectionChange()
    }
    setDropDownVal(option)
    setAnchorEl(null)
    if (placeholder !== undefined && onButtonClick !== undefined) {
      onButtonClick(placeholder, option)
    }
    handleChange?.(option)
  }

  // Close the menu manually
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button endIcon={<ExpandMore />} onClick={handleClick} color="primary">
        {placeholder === undefined ? dropDownVal : placeholder}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        disableAutoFocusItem
      >
        {dropDownSelections.map((selection) => (
          <MenuItem
            key={selection}
            onClick={() => {
              handleClose(selection)
            }}
          >
            {selection}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default DropDown
