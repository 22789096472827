import { Alert, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormInput } from 'src/common/components/CrudActionCard/CrudActionCard'
import FormGroup from 'src/common/components/FormGroup'
import { bankingDetails } from 'src/pages/ApiMembers/Common/data'
import { BankInfo, MemberDetailsInfo } from '../types'
import { adminCreateMemberBankDetails } from 'src/common/api/organizations'
import { alertNotification } from 'src/ui'
import { LoadingButton } from '@mui/lab'
import BankingDetailsForm from 'src/common/components/BankingDetailsForm'
import { ValidationError, isValidBankDetails } from 'src/common/helpers'

interface CreateMemberBankProps {
  entityData: FormInput[][]
}

interface CreateMemberBankState {
  detailsInfo: MemberDetailsInfo
  bank: BankInfo[]
}

const CreateMemberBankDetails: React.FC<CreateMemberBankProps> = ({ entityData }: CreateMemberBankProps) => {
  const entityInfo: FormInput[] = entityData[0]
  const entityBankDetails: FormInput[] = entityData[1]

  const emptyMemberDetailsInfo: MemberDetailsInfo = {
    orgId: '',
    memberId: ''
  }

  const emptyMemberBank: BankInfo = {
    country: '',
    name: '',
    accHolderName: '',
    bankName: '',
    institutionNum: '',
    transitNum: '',
    accountNum: '',
    achCode: '',
    swiftBicCode: ''
  }

  // store form information about member
  const [bankDetailsArr, setBankDetailsArr] = useState<FormInput[][]>([entityBankDetails])

  // store payload for create req
  const [memberDetailsInfo, setMemberDetailsInfo] = useState<MemberDetailsInfo>(emptyMemberDetailsInfo)
  const [memberBank, setMemberBank] = useState<BankInfo[]>([emptyMemberBank])

  const emptyCreateMemberBankState: CreateMemberBankState = {
    detailsInfo: emptyMemberDetailsInfo,
    bank: [emptyMemberBank]
  }

  const [memberState, setMemberState] = useState<CreateMemberBankState>(emptyCreateMemberBankState)
  const [loading, setLoading] = useState<boolean>(false)

  const handleAddBank = (): void => {
    setBankDetailsArr((prev) => [...prev, bankingDetails])

    setMemberBank((prev) => [...prev, emptyMemberBank])
  }

  const validate = () => {
    const validateBankDetails = isValidBankDetails(memberState.bank)
    if (!validateBankDetails.institutionNum) {
      throw new ValidationError('Invalid institution num. Format: 3 digits')
    } else if (!validateBankDetails.transitNum) {
      throw new ValidationError('Invalid transit num. Format: 5 digits')
    } else if (!validateBankDetails.achCode) {
      throw new ValidationError('Invalid ACH code. Format: 9 digits')
    } else if (!validateBankDetails.swiftBicCode) {
      throw new ValidationError('Invalid Swift BIC code. Format: min 8 digits, max 11 digits')
    }
  }

  const handleCreateMemberBankDetailsRequest = async () => {
    try {
      validate()
      setLoading(true)
      const response = await adminCreateMemberBankDetails({
        orgId: memberState.detailsInfo.orgId,
        memberId: memberState.detailsInfo.memberId,
        bankDetails: memberState.bank
          .map((b) => ({
            country: b.country,
            accHolderName: b.accHolderName,
            routingNum: b.institutionNum && `${b.institutionNum}-${b.transitNum}`,
            accountNum: b.accountNum,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
          .filter((b) => b.accountNum.length > 0)
      })
      if (response) {
        alertNotification(
          `Member ${memberState.detailsInfo.memberId}'s bamk details has been created successfully`,
          'success'
        )
        setMemberDetailsInfo(emptyMemberDetailsInfo)
        setMemberBank([emptyMemberBank])
        setBankDetailsArr([bankingDetails])
        setLoading(false)
      }
      setFormKey((prevKey) => prevKey + 1)
    } catch (e) {
      setLoading(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }
  const [formKey, setFormKey] = useState<number>(0)

  useEffect(() => {
    setMemberState({
      detailsInfo: memberDetailsInfo,
      bank: memberBank
    })
  }, [memberDetailsInfo, memberBank, bankDetailsArr])

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <Grid container spacing={2} marginBottom={10}>
        <Grid item md={6}>
          <FormGroup
            header="Member Information"
            formInputs={entityInfo}
            setInfo={setMemberDetailsInfo}
            info={memberDetailsInfo}
          />
        </Grid>
        <Grid item md={6}>
          <BankingDetailsForm
            key={formKey}
            bankingDetails={bankDetailsArr}
            handleAddBank={handleAddBank}
            setBank={setMemberBank}
            info={memberBank}
          />
        </Grid>
      </Grid>
      <LoadingButton loading={loading} onClick={handleCreateMemberBankDetailsRequest} variant="contained" fullWidth>
        Create member bank details
      </LoadingButton>
    </Box>
  )
}

export default CreateMemberBankDetails
