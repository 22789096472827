import { useState } from 'react'
import {
  BankDetails,
  ContactDetails,
  getContactDetails,
  getOrder,
  getUserBankDetails,
  OrderDetails,
  updateAdminPurchaseOrder,
  updateAdminRedeemOrder,
  UserModel
} from 'src/common/api'
import { OrderStatus } from 'src/common/static'
import { alertNotification } from 'src/ui'
import { isSdcProject } from '../../helpers/isSdcProject'

const useRequest = (type: 'purchase' | 'redeem') => {
  const isPurchase = type === 'purchase'
  const isRedeem = type === 'redeem'
  const isSdc = isSdcProject()

  const [order, setOrder] = useState<OrderDetails | null>(null)
  const [values, setValues] = useState({
    orderId: '',
    transferReferenceId: '',
    transactionReferenceId: '',
    cryptoAmount: ''
  })
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setIsLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [statuses, setStatuses] = useState({
    overall: 'INITIATED',
    transfer: 'INITIATED',
    transaction: isPurchase ? 'INITIATED' : 'CONFIRMED'
  })

  const [recipient, setRecipient] = useState<ContactDetails | null>(null)
  const [recipientBankDetails, setRecipientBankDetails] = useState<BankDetails | null>(null)
  const [user, setUser] = useState<UserModel | null>(null)

  const [anchorElStatus, setAnchorElStatus] = useState<null | HTMLElement>(null)
  const [anchorElTransferStatus, setAnchorElTransferStatus] = useState<null | HTMLElement>(null)
  const [anchorElTransactionStatus, setAnchorElTransactionStatus] = useState<null | HTMLElement>(null)

  const chooseStatus = (type: string, status: OrderStatus) => {
    setStatuses({ ...statuses, [type]: status })
    if (type === 'overall') handleCloseStatusMenu()
    if (type === 'transfer') handleCloseTransferStatusMenu()
    if (type === 'transaction') handleCloseTransactionStatusMenu()
  }

  const clearOrderState = () => {
    setOrder(null)
    setIsLoading(true)
    !isSdc && clearUserRecipientState() // not relevant for SDC / API use cases
  }

  const clearUserRecipientState = () => {
    setRecipient(null)
    setRecipientBankDetails(null)
    setUser(null)
  }

  const fetchOrder = async () => {
    try {
      clearOrderState()
      const response = await getOrder(values.orderId)
      if (response.data) {
        setOrder(response.data)
        setStatuses({
          overall: response.data.status,
          transfer: response.data.transfers[0].status,
          transaction: response.data.transactions[0].status
        })
        setValues({
          ...values,
          transferReferenceId: response.data.transfers[0].referenceId,
          cryptoAmount: response.data.cryptoAmount,
          transactionReferenceId: response.data.transactions[0].referenceId
        })
      }
      if (!isSdc) {
        const contactId = response.data.contactId
        const bankingDetailsId = response.data.bankingDetailsId
        if (contactId && bankingDetailsId) {
          await fetchRecipient(contactId)
          await fetchRecipientBankDetails(bankingDetailsId)
        }
      }
      setIsLoading(false)
    } catch {
      alertNotification('Order Not Found')
      setIsLoading(false)
    }
  }

  const fetchRecipient = async (contactId: string) => {
    try {
      const contactDetails = await getContactDetails(contactId)
      const recipient = contactDetails.data
      setRecipient(recipient)
    } catch {
      alertNotification('Contact Not Found')
    }
  }

  const fetchRecipientBankDetails = async (bankingDetailsId: string) => {
    try {
      const userBankDetails = await getUserBankDetails(bankingDetailsId)
      setRecipientBankDetails(userBankDetails.data)
      userBankDetails.data.user && setUser(userBankDetails.data.user)
    } catch {
      alertNotification('User Bank Details Not Found')
    }
  }

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      let response
      if (isRedeem) {
        response = await updateAdminRedeemOrder({
          orderId: values.orderId,
          orderStatus: statuses.overall,
          transferStatus: statuses.transfer,
          transferReferenceId: values.transferReferenceId
        })
      } else {
        response = await updateAdminPurchaseOrder({
          orderId: values.orderId,
          orderStatus: statuses.overall,
          transferStatus: statuses.transfer,
          transferReferenceId: values.transferReferenceId,
          transactionStatus: statuses.transaction,
          transactionReferenceId: values.transactionReferenceId,
          cryptoAmount: Number(values.cryptoAmount)
        })
      }
      setSuccessMessage(`Order ${response.data.id} updated successfully!`)
      setOrder(null)
      setSubmitting(false)
    } catch {
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValues({
      ...values,
      [event.target.name]: newValue
    })
  }

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget)
  }

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null)
  }

  const handleOpenTransferStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTransferStatus(event.currentTarget)
  }

  const handleCloseTransferStatusMenu = () => {
    setAnchorElTransferStatus(null)
  }

  const handleOpenTransactionStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTransactionStatus(event.currentTarget)
  }

  const handleCloseTransactionStatusMenu = () => {
    setAnchorElTransactionStatus(null)
  }

  return {
    isPurchase,
    isRedeem,
    order,
    recipient,
    user,
    recipientBankDetails,
    statuses,
    values,
    anchorElStatus,
    anchorElTransferStatus,
    anchorElTransactionStatus,
    successMessage,
    loading,
    submitting,
    fetchOrder,
    handleChange,
    chooseStatus,
    handleOpenStatusMenu,
    handleCloseStatusMenu,
    handleOpenTransferStatusMenu,
    handleCloseTransferStatusMenu,
    handleOpenTransactionStatusMenu,
    handleCloseTransactionStatusMenu,
    handleRequest
  }
}

export default useRequest
