/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Divider, TextField, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import SummaryTable, { Row } from 'src/ui/SummaryTable/SummaryTable'
import DropDown from '../dropDown/DropDown'
import { DropDownTypes } from '../dropDown/constants/DropDownTypes'
import { FormType } from '../dropDown/constants/FormType'

interface FormGroupProps {
  header?: string
  formInputs: FormInput[]
  setInfo?: Dispatch<SetStateAction<any>>
  index?: number
  info?: any
}
export interface FormInput {
  formTitle: string
  formId: string
  formType: FormType
  dropDownType?: DropDownTypes
  formValue?: string
  condition?: (selectedChain: string) => boolean
}

const FormGroup: React.FC<FormGroupProps> = ({ header, formInputs, setInfo, index, info }: FormGroupProps) => {
  const handleCreateChange = (value: string, target: string) => {
    if (setInfo) {
      if (index !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setInfo((prevInputs: any) => {
          const updatedInputs = [...prevInputs]
          const updatedInput = { ...updatedInputs[index], [target]: value }
          updatedInputs[index] = updatedInput
          return updatedInputs
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setInfo((prevInputs: any) => ({
          ...prevInputs,
          [target]: value
        }))
      }
    }
  }

  function convertFormInputsToRows(formInputs: FormInput[]): Row[] {
    const res = formInputs.map((input) => ({
      key: <Typography fontWeight={500}>{input.formTitle}</Typography>,
      value:
        input.dropDownType == undefined ? (
          <TextField
            name={input.formId}
            label={input.formValue}
            value={info ? info[input.formId] : ''} // Set the value based on info[input.formId]
            onChange={(e) => handleCreateChange(e.target.value, input.formId)}
          />
        ) : (
          <DropDown type={input.dropDownType} handleChange={(value) => handleCreateChange(value, input.formId)} />
        )
    }))
    return res
  }

  const data = convertFormInputsToRows(formInputs)

  return (
    <Box sx={{ width: '400px' }}>
      <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
        {header}
      </Typography>
      {header !== undefined && <Divider sx={{ marginBottom: '24px' }} />}

      <SummaryTable data={data} padding="16px 0" />
    </Box>
  )
}

export default FormGroup
