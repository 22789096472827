const ReportCode = {
  lvctr: '14',
  str: '102',
  lctr: '106',
  eftr: '145',
  cdr: '113'
}

export const GetReportCode = (reportType: string) => {
  return ReportCode[reportType as keyof typeof ReportCode]
}
