import httpClient from 'src/common/axios/config'
import { DeleteFintracReportDTO } from './dto'

export const createFintracReport = (reportTypeCode: string | undefined, reportFile: File | undefined) => {
  if (!reportTypeCode || !reportFile) throw new Error('Missing Report Type Code or Report File')
  const formData = new FormData()
  formData.append('reportFile', reportFile)
  const url = `/reports/fintrac?reportTypeCode=${reportTypeCode}`
  return httpClient.post(url, formData)
}

export const updateFintracReport = (reportFile: File | undefined) => {
  if (!reportFile) throw new Error('Missing Report File')
  const formData = new FormData()
  formData.append('reportFile', reportFile)
  const url = `/reports/fintrac`
  return httpClient.put(url, formData)
}

export const deleteFintracReport = (params: DeleteFintracReportDTO | undefined) => {
  console.log(params, 'params')
  return httpClient.delete('/reports/fintrac', {
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}
