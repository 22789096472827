import axios from 'axios'

const TOKEN = process.env.REACT_APP_FLAGSMITH_TOKEN
const ENV_KEY = process.env.REACT_APP_FLAGSMITH_ENV_KEY
const flagsmithFeatureStateUrl = `https://api.flagsmith.com/api/v1/environments/${ENV_KEY}/featurestates`

const flagsmithSession = axios.create({
  headers: {
    Authorization: `Token ${TOKEN}`,
    'Content-Type': 'application/json'
  }
})

async function getFeatureStates(featureName: string) {
  return await flagsmithSession.get(`${flagsmithFeatureStateUrl}/?feature_name=${featureName}`)
}

async function patchFeatureStates(featureStateId: string, payload: unknown) {
  return await flagsmithSession.patch(`${flagsmithFeatureStateUrl}/${featureStateId}/`, payload)
}

export { getFeatureStates, patchFeatureStates }
