import React, { useState } from 'react'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { DeleteMemberBlockchainDetailsInfo } from './types'
import { CrudActionContext } from '../context'
import { Box } from '@mui/material'
import { adminDeleteBlockchainDetails } from 'src/common/api/organizations'
import { alertNotification } from 'src/ui'
import { ValidationError } from 'src/common/helpers'

interface DeleteMemberProps {
  formInputs: FormInput[]
}

const DeleteMemberBlockchainDetails: React.FC<DeleteMemberProps> = ({ formInputs }: DeleteMemberProps) => {
  const emptyMemberBlockchainDetails: DeleteMemberBlockchainDetailsInfo = {
    memberId: '',
    blockchainDetailsId: ''
  }

  const [deleteMemberBlockchainDetailsInfo, setDeleteMemberBlockchainDetailsInfo] =
    useState<DeleteMemberBlockchainDetailsInfo>(emptyMemberBlockchainDetails)

  const handleDeleteMemberBillPayDetailsRequest = async () => {
    try {
      const response = await adminDeleteBlockchainDetails(
        deleteMemberBlockchainDetailsInfo.memberId,
        deleteMemberBlockchainDetailsInfo.blockchainDetailsId
      )
      if (response) {
        alertNotification(
          `Member ${deleteMemberBlockchainDetailsInfo.memberId}'s blockchain details ${deleteMemberBlockchainDetailsInfo.blockchainDetailsId} has been deleted successfully`,
          'success'
        )
        setDeleteMemberBlockchainDetailsInfo(emptyMemberBlockchainDetails)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <CrudActionContext.Provider value={handleDeleteMemberBillPayDetailsRequest}>
        <CrudActionCard
          crudType={CrudTypes.Delete}
          formInputs={formInputs}
          setInfo={setDeleteMemberBlockchainDetailsInfo}
          info={deleteMemberBlockchainDetailsInfo}
        />
      </CrudActionContext.Provider>
    </Box>
  )
}

export default DeleteMemberBlockchainDetails
