import { ExpandMore } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Divider, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { getUserDetails, updateUserKYB } from 'src/common/api'
import { UserModel } from 'src/common/api/users/models'
import { KybStatus } from 'src/common/static'
import { alertNotification, Container } from 'src/ui'

const KYB: React.FC = () => {
  const [value, setValue] = useState('')
  const [user, setUser] = useState<UserModel | null>(null)
  const [status, setStatus] = useState<KybStatus>(KybStatus.UNINITIATED)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const chooseStatus = (status: KybStatus) => {
    setStatus(status)
    handleCloseStatusMenu()
  }

  const clearOrderState = () => {
    setUser(null)
    setLoading(true)
  }

  const fetchUser = async () => {
    try {
      clearOrderState()
      const response = await getUserDetails(value)
      if (response.data) {
        setUser(response.data)
        setStatus(response.data.kybStatus)
      }
      setLoading(false)
    } catch {
      alertNotification('User Not Found')
      setLoading(false)
    }
  }

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      await updateUserKYB({ userId: user?.id as string, kybStatus: status })
      setSuccessMessage(`KYB status updated successfully!`)
      setUser(null)
      setSubmitting(false)
    } catch {
      alertNotification('Something went wrong, please try again later.')
      setSubmitting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)
  }

  const [anchorElStatus, setAnchorElStatus] = useState<null | HTMLElement>(null)

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget)
  }

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null)
  }

  return (
    <Container success={successMessage} maxWidth={700}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="email" placeholder="User Email" onChange={handleChange} value={value} />
          <Box>
            <Button variant="contained" onClick={fetchUser} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {user && (
          <>
            <Divider />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">KYB Details</Typography>
              <Button endIcon={<ExpandMore />} color="primary" onClick={handleOpenStatusMenu} size="small">
                {status}
              </Button>
            </Stack>
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleRequest}>
              Update Status
            </LoadingButton>
          </>
        )}
        <Menu
          sx={{ mt: '7px' }}
          id="menu-status-type"
          anchorEl={anchorElStatus}
          keepMounted
          open={Boolean(anchorElStatus)}
          onClose={handleCloseStatusMenu}
        >
          {Object.values(KybStatus).map((status) => (
            <MenuItem key={status} onClick={() => chooseStatus(status)}>
              <Typography>{status}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </Container>
  )
}

export default KYB
