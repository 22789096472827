import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { DropDownTypes } from 'src/common/components/dropDown/constants/DropDownTypes'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { BankingCountry, ChainName } from 'src/common/static'

export const updateMemberData: FormInput[][] = [
  [
    {
      formTitle: 'Organization ID',
      formId: 'orgId',
      formType: FormType.input,
      formValue: 'Organization ID'
    },

    {
      formTitle: 'Member Id',
      formId: 'memberId',
      formType: FormType.input,
      formValue: 'Member Id'
    },
    {
      formTitle: 'Business Name',
      formId: 'businessName',
      formType: FormType.input,
      formValue: 'Business Name'
    },
    {
      formTitle: 'First Name',
      formId: 'firstName',
      formType: FormType.input,
      formValue: 'First Name'
    },
    {
      formTitle: 'Last Name',
      formId: 'lastName',
      formType: FormType.input,
      formValue: 'Last Name'
    },
    {
      formTitle: 'Email',
      formId: 'email',
      formType: FormType.input,
      formValue: 'Email'
    },
    {
      formTitle: 'Interac Email',
      formId: 'interacEmail',
      formType: FormType.input,
      formValue: 'Interac Email'
    }
  ],
  [
    {
      formTitle: 'Address',
      formId: 'address',
      formType: FormType.input,
      formValue: 'Address'
    },
    {
      formTitle: 'Postal Code',
      formId: 'postalCode',
      formType: FormType.input,
      formValue: 'Postal Code'
    },
    {
      formTitle: 'Country',
      formId: 'country',
      formType: FormType.input,
      formValue: 'Country'
    },
    {
      formTitle: 'City',
      formId: 'city',
      formType: FormType.input,
      formValue: 'City'
    },
    {
      formTitle: 'State/Province',
      formId: 'stateProvince',
      formType: FormType.input,
      formValue: 'State/Province'
    }
  ]
]

export const membersActionCardCrud = {
  create: { isPresent: true, isDropdown: true },
  get: { isPresent: true, isDropdown: true },
  update: { isPresent: false, isDropdown: false },
  delete: { isPresent: true, isDropdown: true }
}

export const getMemberByMemberIdData: FormInput[] = [
  {
    formTitle: 'Organization ID',
    formId: 'orgId',
    formType: FormType.input,
    formValue: 'Organization Id'
  },
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member Id'
  }
]

export const getMemberByOrgIdData: FormInput[] = [
  {
    formTitle: 'Organization ID',
    formId: 'orgId',
    formType: FormType.input,
    formValue: 'Organization ID'
  }
]

export const deleteMemberData: FormInput[] = [
  {
    formTitle: 'Organization ID',
    formId: 'orgId',
    formType: FormType.input,
    formValue: 'Organization ID'
  },
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  }
]

export const bankingDetails: FormInput[] = [
  {
    formTitle: 'Country',
    formId: 'country',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.Country
  },
  {
    formTitle: 'Account Holder Name',
    formId: 'accHolderName',
    formType: FormType.input,
    formValue: 'Account Holder Name'
  },
  {
    formTitle: 'Institution Number',
    formId: 'institutionNum',
    formType: FormType.input,
    formValue: 'Institution Number',
    condition: (selectedCountry) => selectedCountry === BankingCountry.CAN
  },
  {
    formTitle: 'Transit Number',
    formId: 'transitNum',
    formType: FormType.input,
    formValue: 'Transit Number',
    condition: (selectedCountry) => selectedCountry === BankingCountry.CAN
  },
  {
    formTitle: 'ACH Code',
    formId: 'achCode',
    formType: FormType.input,
    formValue: 'ACH Code',
    condition: (selectedCountry) => selectedCountry === BankingCountry.US
  },
  {
    formTitle: 'SWIFT/BIC Code',
    formId: 'swiftBicCode',
    formType: FormType.input,
    formValue: 'SWIFT/BIC Code',
    condition: (selectedCountry) => selectedCountry === BankingCountry.US
  },
  {
    formTitle: 'Account Number',
    formId: 'accountNum',
    formType: FormType.input,
    formValue: 'Account Number'
  }
]

export const billPayDetails: FormInput[] = [
  {
    formTitle: 'Payee Name',
    formId: 'payeeName',
    formType: FormType.input,
    formValue: 'Payee Name'
  },
  {
    formTitle: 'Payee Code',
    formId: 'payeeCode',
    formType: FormType.input,
    formValue: 'Payee Code'
  },
  {
    formTitle: 'Payee Account Number',
    formId: 'payeeAccountNumber',
    formType: FormType.input,
    formValue: 'Payee Account Number'
  }
]

export const blockchainDetails: FormInput[] = [
  {
    formTitle: 'Wallet Name',
    formId: 'name',
    formType: FormType.input,
    formValue: 'Wallet Name'
  },
  {
    formTitle: 'Chain',
    formId: 'chain',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.Chain
  },
  {
    formTitle: 'Address',
    formId: 'address',
    formType: FormType.input,
    formValue: 'Address'
  },
  {
    formTitle: 'External Memo',
    formId: 'externalMemo',
    formType: FormType.input,
    formValue: 'External Memo',
    condition: (selectedChain) => selectedChain === ChainName.STELLAR
  }
]
