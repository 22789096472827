import { Card, CardContent, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { WalletAnalytics } from 'src/common/api'
import { QCADIcon, USDCIcon } from 'src/common/assets'
import { formatNumber, trimId } from 'src/common/helpers'
import { CHAIN_NATIVE_TOKEN_ICON, CHAIN_NATIVE_TOKEN, EXPLORER, CUSTODIAL_NAME, ChainName } from 'src/common/static'

interface WalletCardProps {
  wallet: WalletAnalytics
}

const WalletCard: React.FC<WalletCardProps> = ({ wallet }: WalletCardProps) => {
  const { address, balance, chain } = wallet
  const AssetIcon = CHAIN_NATIVE_TOKEN_ICON[chain]

  return (
    <Box mt="24px" key={address}>
      <Card elevation={3}>
        <CardContent sx={{ p: '20px' }}>
          <Box display="flex" alignItems="center" mb={3}>
            <Typography variant="h5" width="215px">
              {CUSTODIAL_NAME[chain]}
            </Typography>
          </Box>
          <Stack spacing={2} mb={2.5}>
            <Stack direction="row">
              <AssetIcon />
              <Typography flexGrow={1} ml={2}>
                {CHAIN_NATIVE_TOKEN[chain]}
              </Typography>
              <Typography>{formatNumber(balance.native as number, 2)}</Typography>
            </Stack>
            <Stack direction="row">
              <QCADIcon />
              <Typography flexGrow={1} ml={2}>
                QCAD
              </Typography>
              <Typography>
                {chain === ChainName.STELLAR
                  ? balance?.qcad_xlm?.toString()
                    ? formatNumber(balance.qcad_xlm as number)
                    : 'not opted-in'
                  : balance?.qcad?.toString()
                  ? formatNumber(balance.qcad as number)
                  : 'not opted-in'}
              </Typography>
            </Stack>
            <Stack direction="row">
              <USDCIcon />
              <Typography flexGrow={1} ml={2}>
                USDC
              </Typography>
              <Typography>
                {chain === ChainName.STELLAR
                  ? balance?.usdc_xlm?.toString()
                    ? formatNumber(balance?.usdc_xlm as number)
                    : 'not opted-in'
                  : balance?.usdc?.toString()
                  ? formatNumber(balance?.usdc as number)
                  : 'not opted-in'}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="end">
            <Link
              component="a"
              target="_blank"
              rel="noopener"
              href={`${EXPLORER[chain]}/address/${address}`}
              color="inherit"
              variant="body2"
            >
              {trimId(address)}
            </Link>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

export default WalletCard
