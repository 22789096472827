import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { FlagIcon, NewContactIcon, USFlagIcon } from 'src/common/assets'
import { alertNotification, Modal } from 'src/ui'
import Contact from 'src/common/components/Contact/Contact'
import {
  adminDeleteContact,
  adminGetUserContactDetails,
  BankDetailsModel,
  BlockchainDetailsModel,
  ContactDetailsModel,
  ContactModel
} from '../../common/api/contacts'
import NewContact from '../../common/components/Contact/NewContact'
import { LoadingButton } from '@mui/lab'
import { DeleteContactIcon } from 'src/common/assets/DeleteContactIcon'
import { getUserDetails, UserModel } from 'src/common/api'
import { BillPayDetailsModel } from '../../common/api/organizations'

interface BankColumnProps {
  primaryBank: BankDetailsModel | undefined
}

const AddressBook: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [contacts, setContacts] = useState<(ContactDetailsModel | null)[]>([])
  const [selectedContact, setSelectedContact] = useState<ContactModel>()
  const [selectedContactBank, setSelectedContactBank] = useState<BankDetailsModel[]>()
  const [selectedContactBlockchain, setSelectedContactBlockchain] = useState<BlockchainDetailsModel[]>()
  const [selectedContactBillpay, setSelectedContactBillpay] = useState<BillPayDetailsModel[]>()

  const [modals, setModals] = useState({
    contactOpen: false,
    newContactOpen: false,
    deleteContactOpen: false
  })

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleClose = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleContactOpen = (
    contact: ContactModel,
    bankDetails: BankDetailsModel[],
    blockchainDetails: BlockchainDetailsModel[],
    billPayDetails: BillPayDetailsModel[]
  ) => {
    setSelectedContact(contact)
    setSelectedContactBank(bankDetails)
    setSelectedContactBlockchain(blockchainDetails)
    setSelectedContactBillpay(billPayDetails)
    handleOpen('contactOpen')
  }

  const handleNewContactOpen = () => {
    handleOpen('newContactOpen')
  }

  const handleDeleteContactOpen = (contact: ContactModel) => {
    setSelectedContact(contact)
    handleOpen('deleteContactOpen')
  }

  const handleCloseDeleteContact = () => {
    handleClose('deleteContactOpen')
  }

  const fetchContacts = async () => {
    try {
      setLoading(true)
      if (user?.id) {
        const response = await adminGetUserContactDetails(user.id)
        setContacts(response.data)
        setLoading(false)
      }
      setLoading(false)
    } catch (err) {
      alertNotification('Fetching contacts failed. Please contact support.', 'error')
    }
  }

  const removeContact = async () => {
    try {
      if (!selectedContact) {
        throw new Error(`No contact selected`)
      }
      setLoading(true)
      const contactId = selectedContact.id
      const response = await adminDeleteContact({ contactId: contactId })
      setContacts(contacts.filter((i) => i && i.contactDetails.id !== contactId))
      setLoading(false)
      handleCloseDeleteContact()
      if (response) {
        alertNotification('Recipient removed successfully.', 'success')
      }
    } catch {
      alertNotification('Deleting contact failed. Please contact support.', 'error')
    }
  }
  useEffect(() => {
    fetchContacts()
  }, [modals.newContactOpen, modals.contactOpen])

  const RenderBankColumn = ({ primaryBank }: BankColumnProps) => {
    return (
      <ListItem sx={{ paddingLeft: '0px', paddingRight: '4px' }}>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          {primaryBank && primaryBank.country === 'CAN' ? (
            <FlagIcon fontSize="small" />
          ) : (
            <USFlagIcon fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          {primaryBank && primaryBank.name} ****
          {primaryBank && primaryBank.accountNum.substring(primaryBank.accountNum.length - 4)}
        </ListItemText>
      </ListItem>
    )
  }

  const fetchUser = async () => {
    try {
      const response = await getUserDetails(userEmail)
      if (response.data) {
        setUser(response.data)
      }
      setLoading(false)
    } catch {
      alertNotification('User Not Found')
      setLoading(false)
    }
  }

  const [userEmail, setUserEmail] = useState('')
  const [user, setUser] = useState<UserModel | null>(null)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setUserEmail(newValue)
  }

  useEffect(() => {
    fetchContacts()
  }, [user])

  return (
    <S.StyledContainer maxWidth="xl" fixed>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h5" fontWeight={600}>
          Address Book
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="email" placeholder="User Email" onChange={handleSearchChange} value={userEmail} />
          <Box>
            <Button variant="contained" onClick={fetchUser} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
      {user && (
        <>
          <S.StyledButton
            variant="outlined"
            startIcon={<NewContactIcon />}
            onClick={handleNewContactOpen}
            sx={{ marginBottom: '24px' }}
          >
            Add a new recipient
          </S.StyledButton>
          <S.StyledPaper variant="card">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Primary Bank</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    Boolean(contacts.length) &&
                    contacts.map(
                      (contact, idx) =>
                        contact && (
                          <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              {contact.contactDetails.firstName} {contact.contactDetails.lastName}
                            </TableCell>
                            <TableCell>{contact.contactDetails.companyTitle}</TableCell>
                            <TableCell>
                              {contact.bankDetails.find((primaryBank) => primaryBank.isPrimary) && (
                                <RenderBankColumn
                                  primaryBank={contact.bankDetails.find((primaryBank) => primaryBank.isPrimary)}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <S.StyledButton
                                variant="outlined"
                                size="small"
                                sx={{ width: '137px' }}
                                onClick={() =>
                                  handleContactOpen(
                                    contact.contactDetails,
                                    contact.bankDetails,
                                    contact.blockchainDetails,
                                    contact.billPayDetails
                                  )
                                }
                              >
                                View Details
                              </S.StyledButton>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDeleteContactOpen(contact.contactDetails)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && contacts.length == 0 && (
              <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="h6">No Recipients</Typography>
              </Box>
            )}
            {loading && (
              <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress disableShrink />
              </Box>
            )}
            <Modal
              open={modals.contactOpen}
              onClose={() => handleClose('contactOpen')}
              title="Beneficiary Details"
              maxWidth="lg"
              fullWidth
            >
              {selectedContact && selectedContactBank && selectedContactBlockchain && selectedContactBillpay && (
                <Contact
                  contact={selectedContact}
                  bankDetails={selectedContactBank}
                  blockchainDetails={selectedContactBlockchain}
                  billpayDetails={selectedContactBillpay}
                  userId={user.id}
                />
              )}
            </Modal>
            <Modal
              open={modals.newContactOpen}
              onClose={() => handleClose('newContactOpen')}
              title="Add a New Recipient to Address Book"
              maxWidth="lg"
              fullWidth
              keepMounted
            >
              <NewContact userId={user.id} handleClose={() => handleClose('newContactOpen')} />
            </Modal>
            <Modal
              open={modals.deleteContactOpen}
              onClose={handleCloseDeleteContact}
              title="Delete Recipient from Address Book"
              maxWidth="xs"
              fullWidth
              keepMounted
            >
              <Stack spacing={3}>
                <Box display="flex" justifyContent="center">
                  <DeleteContactIcon sx={{ fontSize: '91px' }} />
                </Box>
                <Typography variant="subtitle2" textAlign="center">
                  You are removing this Recipient from your address book. Once removed you will lose all associated
                  contact details for this recipient. Please confirm by clicking &apos;Remove Recipient&apos;.
                </Typography>
                <LoadingButton variant="contained" loading={loading} size="large" onClick={removeContact}>
                  Remove Recipient
                </LoadingButton>
                <LoadingButton loading={loading} size="large" onClick={handleCloseDeleteContact}>
                  Cancel
                </LoadingButton>
              </Stack>
            </Modal>
          </S.StyledPaper>
        </>
      )}
    </S.StyledContainer>
  )
}

export default AddressBook
