import httpClient from 'src/common/axios/config'
import {
  AdminCreateContactDTO,
  AdminUpdateContactDTO,
  DeleteBankingDetailsDTO,
  DeleteBillPayDetailsDTO,
  DeleteBlockchainDetailsDTO,
  DeleteContactDTO,
  UpdateContactNewBankDetailsDTO,
  UpdateContactNewBillPayDetailsDTO,
  UpdateContactNewBlockchainDetailsDTO,
  UpdateContactSetPrimaryBankDTO
} from './dto'
import {
  BankDetailsModel,
  BillPayDetailsModel,
  BlockchainDetailsModel,
  ContactDetailsModel,
  ContactModel
} from './models'

export const adminCreateContact = (params: AdminCreateContactDTO) =>
  httpClient.post<ContactModel>('/contacts/admin', params)

export const adminUpdateContact = (params: AdminUpdateContactDTO) =>
  httpClient.put<ContactModel>('/contacts/admin', params)

export const adminGetUserContactDetails = (userId: string | undefined) =>
  httpClient.get<ContactDetailsModel[]>(`/contacts/admin/contacts?userId=${userId}`)

export const adminDeleteContact = (params: DeleteContactDTO) => httpClient.delete('/contacts/admin', { params })

export const adminUpdateContactNewBlockchainDetails = (params: UpdateContactNewBlockchainDetailsDTO) =>
  httpClient.put<BlockchainDetailsModel[]>('/contacts/admin/blockchainDetails', params)

export const adminUpdateContactNewBankDetails = (params: UpdateContactNewBankDetailsDTO) =>
  httpClient.put<BankDetailsModel[]>('/contacts/admin/bankDetails', params)

export const adminUpdateContactNewBillPayDetails = (params: UpdateContactNewBillPayDetailsDTO) =>
  httpClient.put<BillPayDetailsModel[]>('/contacts/admin/billPayDetails', params)

export const adminDeleteContactBlockchainDetails = (params: DeleteBlockchainDetailsDTO) =>
  httpClient.delete('/contacts/admin/blockchainDetails', { params })

export const adminDeleteContactBankDetails = (params: DeleteBankingDetailsDTO) =>
  httpClient.delete('/contacts/admin/bankDetails', { params })

export const adminDeleteBillPayDetails = (params: DeleteBillPayDetailsDTO) =>
  httpClient.delete('/contacts/admin/billPayDetails', { params })

export const admimUpdateContactSetPrimaryBank = (params: UpdateContactSetPrimaryBankDTO) =>
  httpClient.put<BankDetailsModel[]>('/contacts/admin/bankDetails/setPrimary', params)
