import { Box } from '@mui/material'
import React, { useState } from 'react'
import KYActionCard from 'src/common/components/KYActionCard'
import { CrudActionContext } from '../ApiMembers/context'
import { kybFormInput } from './data'
import { adminUpdateMemberKybStatus } from 'src/common/api/organizations'
import { alertNotification } from 'src/ui'
import { KybStatus } from '../../common/static'
import { ValidationError } from 'ajv'

export interface ApiKYBInfo {
  memberId: string
  kybStatus: KybStatus
}

const ApiKYB: React.FC = () => {
  const emptyKYBInfo: ApiKYBInfo = {
    memberId: '',
    kybStatus: KybStatus.UNINITIATED
  }
  const [kybInfo, setKybInfo] = useState<ApiKYBInfo>(emptyKYBInfo)

  const handleAdminUpdateMemberKybStatusRequest = async () => {
    try {
      const response = await adminUpdateMemberKybStatus({
        memberId: kybInfo.memberId,
        kybStatus: kybInfo.kybStatus
      })
      if (response) {
        alertNotification(`Member ${kybInfo.memberId}'s KYB has been updated successfully`, 'success')
        setKybInfo(emptyKYBInfo)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <Box bgcolor="#BC19FF" color="white" p={2} textAlign="center" fontSize="24px" fontWeight="bold">
        Note: API Operations
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={10}>
        <CrudActionContext.Provider value={handleAdminUpdateMemberKybStatusRequest}>
          <KYActionCard headers="Update Member's KYB" formInput={kybFormInput} setInfo={setKybInfo} info={kybInfo} />
        </CrudActionContext.Provider>
      </Box>
    </Box>
  )
}

export default ApiKYB
