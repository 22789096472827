import React, { useState } from 'react'
import {
  Alert,
  Button,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Box } from '@mui/system'
import { alertNotification, Modal, SummaryTable } from 'src/ui'
import {
  AddBankIcon,
  AlgoIcon,
  EthIcon,
  FlagIcon,
  QCADIcon,
  StellarIcon,
  USDCIcon,
  USFlagIcon
} from 'src/common/assets'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { LoadingButton } from '@mui/lab'
import * as S from './styles'
import { BankingCountry, ChainName, EXPLORER } from '../../static'
import { adminCreateContact } from '../../api/contacts'
import {
  checkValidCanadianBank,
  checkValidUsBank,
  isAchCodeValid,
  isBlockchainAddressValid,
  isEmailValid,
  isInstitutionNumValid,
  isSwiftBicCodeValid,
  isTransitNumValid,
  trimId,
  ValidationError
} from 'src/common/helpers'
import { BlockchainIcon } from '../../assets/BlockchainIcon'
import { DeleteContactIcon } from '../../assets/DeleteContactIcon'
import { countryToAlpha2 } from 'country-to-iso'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

interface NewContactProps {
  handleClose: () => void
  userId: string
}

const NewContact: React.FC<NewContactProps> = ({ userId, handleClose }) => {
  const emptyValuesState = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    companyTitle: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    countryCode: '',
    city: '',
    stateProvince: ''
  }
  const emptyBankState = {
    country: BankingCountry.CAN,
    name: '',
    accHolderName: '',
    institutionNum: '',
    transitNum: '',
    routingNum: '',
    accountNum: '',
    achCode: '',
    swiftBicCode: ''
  }
  const emptyBlockchainState = {
    name: '',
    address: '',
    chain: ChainName.ETHEREUM
  }

  const emptyBillpayState = {
    payeeName: '',
    payeeCode: '',
    payeeAccountNumber: ''
  }

  const noErrors = {
    firstName: false,
    middleName: false,
    lastName: false,
    email: false,
    companyTitle: false,
    addressLine1: false,
    addressLine2: false,
    postalCode: false,
    countryCode: false,
    city: false,
    stateProvince: false,
    institutionNum: false,
    transitNum: false,
    bankName: false,
    accHolderName: false,
    accountNum: false,
    achCode: false,
    swiftBicCode: false,
    name: false,
    address: false,
    payeeName: false,
    payeeCode: false,
    payeeAccountNumber: false
  }
  const [values, setValues] = useState(emptyValuesState)
  const [bankIdx, setBankIdx] = useState(0)
  const [bankDetails, setBankDetails] = useState([emptyBankState])
  const [blockchainIdx, setBlockchainIdx] = useState(0)
  const [blockchainDetails, setBlockchainDetails] = useState([emptyBlockchainState])
  const [errors, setErrors] = useState(noErrors)
  const [billpayIdx, setBillpayIdx] = useState(0)
  const [billpayDetails, setBillpayDetails] = useState([emptyBillpayState])

  const [anchorElCountry, setAnchorElCountry] = useState<null | HTMLElement>(null)
  const [anchorElChain, setAnchorElChain] = useState<null | HTMLElement>(null)

  const chooseChain = (chain: ChainName) => {
    const updatedBlockchainDetails = {
      ...blockchainDetails[blockchainIdx],
      chain: chain
    }
    setBlockchainDetails(
      blockchainDetails.map((wallet, idx) => (idx === blockchainIdx ? updatedBlockchainDetails : wallet))
    )
    handleCloseChainMenu()
  }

  const chooseCountry = (country: BankingCountry) => {
    const updatedBankDetails = {
      ...bankDetails[bankIdx],
      country: country
    }
    setBankDetails(bankDetails.map((bank, idx) => (idx === bankIdx ? updatedBankDetails : bank)))
    handleCloseCountryMenu()
  }

  const handleOpenChainMenu = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setBlockchainIdx(idx)
    setAnchorElChain(event.currentTarget)
  }

  const handleCloseChainMenu = () => {
    setAnchorElChain(null)
  }

  const handleOpenCountryMenu = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setBankIdx(idx)
    setAnchorElCountry(event.currentTarget)
  }

  const handleCloseCountryMenu = () => {
    setAnchorElCountry(null)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValues({
      ...values,
      [event.target.name]: newValue
    })
  }

  const handleBankDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBankDetails = {
      ...bankDetails[bankIdx],
      [event.target.name]: event.target.value
    }
    setBankDetails(bankDetails.map((bank, idx) => (idx === bankIdx ? updatedBankDetails : bank)))
  }

  const handleBlockchainDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBlockchainDetails = {
      ...blockchainDetails[blockchainIdx],
      [event.target.name]: event.target.value
    }
    setBlockchainDetails(
      blockchainDetails.map((wallet, idx) => (idx === blockchainIdx ? updatedBlockchainDetails : wallet))
    )
  }

  const handleBillpayDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBillpayDetails = {
      ...billpayDetails[billpayIdx],
      [event.target.name]: event.target.value
    }
    setBillpayDetails(billpayDetails.map((billpay, idx) => (idx === billpayIdx ? updatedBillpayDetails : billpay)))
  }

  const addNewBankDetails = () => {
    setBankDetails([
      ...bankDetails,
      {
        country: BankingCountry.CAN,
        name: '',
        accHolderName: '',
        institutionNum: '',
        transitNum: '',
        routingNum: '',
        accountNum: '',
        achCode: '',
        swiftBicCode: ''
      }
    ])
  }

  const deleteBankDetails = (idx: number) => {
    setBankDetails(bankDetails.filter((i) => bankDetails.indexOf(i) !== idx))
  }

  const addNewBlockchainDetails = () => {
    setBlockchainDetails([...blockchainDetails, emptyBlockchainState])
  }

  const addNewBillpayDetails = () => {
    setBillpayDetails([...billpayDetails, emptyBillpayState])
  }

  const deleteBlockchainDetails = (idx: number) => {
    setBlockchainDetails(blockchainDetails.filter((i) => blockchainDetails.indexOf(i) !== idx))
  }

  const deleteBillpayDetails = (idx: number) => {
    setBillpayDetails(billpayDetails.filter((i) => billpayDetails.indexOf(i) !== idx))
  }

  const [newBlockchainOpen, setNewBlockchainOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [newBillpayOpen, setNewBillpayOpen] = useState(false)

  const isMissingBankDetails = (): boolean => {
    for (let i = 0; i < bankDetails.length; i++) {
      const bank = bankDetails[i]
      if (
        i === 0 &&
        !bank.name &&
        !bank.institutionNum &&
        !bank.transitNum &&
        !bank.accountNum &&
        !bank.achCode &&
        !bank.swiftBicCode &&
        !bank.accHolderName
      ) {
        continue
      }
      if (bank.country === BankingCountry.CAN && checkValidCanadianBank(bank)) {
        return true
      } else if (bank.country === BankingCountry.US && checkValidUsBank(bank)) {
        return true
      }
    }
    return false
  }

  const isValidBankDetails = () => {
    const isValid = {
      institutionNum: true,
      transitNum: true,
      achCode: true,
      swiftBicCode: true
    }
    for (let i = 0; i < bankDetails.length; i++) {
      const bank = bankDetails[i]
      const isCAN = bank.country === BankingCountry.CAN
      if (isCAN && bank.institutionNum && !isInstitutionNumValid(bank.institutionNum)) {
        isValid.institutionNum = false
      }
      if (isCAN && bank.transitNum && !isTransitNumValid(bank.transitNum)) {
        isValid.transitNum = false
      }
      if (!isCAN && bank.achCode && !isAchCodeValid(bank.achCode)) {
        isValid.achCode = false
      }
      if (!isCAN && bank.swiftBicCode && !isSwiftBicCodeValid(bank.swiftBicCode)) {
        isValid.swiftBicCode = false
      }
    }
    return isValid
  }

  const isMissingBlockchainDetails = (): boolean => {
    for (let i = 0; i < blockchainDetails.length; i++) {
      const wallet = blockchainDetails[i]
      if (i === 0 && !wallet.name && !wallet.address) {
        continue
      }
      if ((wallet.name || wallet.address) && (!wallet.name || !wallet.address)) {
        return true
      }
    }
    return false
  }

  const isValidBlockchainAddresses = (): boolean => {
    for (let i = 0; i < blockchainDetails.length; i++) {
      const wallet = blockchainDetails[i]
      if (wallet.address && !isBlockchainAddressValid(wallet.address, wallet.chain)) {
        return false
      }
    }
    return true
  }

  const isMissingBillpayDetails = (): boolean => {
    for (let i = 0; i < billpayDetails.length; i++) {
      const billpay = billpayDetails[i]
      if (i === 0 && !billpay.payeeName && !billpay.payeeCode && !billpay.payeeAccountNumber) {
        continue
      }
      if (
        (billpay.payeeName || billpay.payeeCode || billpay.payeeAccountNumber) &&
        (!billpay.payeeName || !billpay.payeeCode || !billpay.payeeAccountNumber)
      ) {
        return true
      }
    }
    return false
  }

  const isValidBillpayDetails = (): boolean => {
    for (let i = 0; i < billpayDetails.length; i++) {
      const billpay = billpayDetails[i]
      if (billpay.payeeCode && isNaN(Number(billpay.payeeCode))) {
        return false
      } else if (billpay.payeeAccountNumber && isNaN(Number(billpay.payeeAccountNumber))) {
        return false
      }
    }
    return true
  }

  const bankAndBlockchainEmpty = (): boolean => {
    for (let i = 0; i < bankDetails.length; i++) {
      const bank = bankDetails[i]
      if (
        bank.name ||
        bank.accHolderName ||
        bank.accountNum ||
        bank.achCode ||
        bank.institutionNum ||
        bank.transitNum ||
        bank.swiftBicCode
      ) {
        return false
      }
    }
    for (let i = 0; i < blockchainDetails.length; i++) {
      const wallet = blockchainDetails[i]
      if (wallet.address || wallet.name) {
        return false
      }
    }
    return true
  }

  const validate = () => {
    const validateBankDetails = isValidBankDetails()
    if (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.addressLine1 ||
      !values.postalCode ||
      !values.countryCode ||
      !values.city ||
      !values.stateProvince
    ) {
      setErrors({
        ...noErrors,
        firstName: !values.firstName,
        lastName: !values.lastName,
        email: !values.email,
        addressLine1: !values.addressLine1,
        postalCode: !values.postalCode,
        countryCode: !values.countryCode,
        city: !values.city,
        stateProvince: !values.stateProvince
      })
      throw new ValidationError('Please fill in required fields.')
    } else if (!isEmailValid(values.email)) {
      setErrors({
        ...noErrors,
        email: true
      })
      throw new ValidationError('Invalid email')
    } else if (!countryToAlpha2(values.countryCode)) {
      setErrors({
        ...noErrors,
        countryCode: true
      })
      throw new ValidationError('Invalid country')
    } else if (isMissingBankDetails()) {
      throw new ValidationError('Missing bank details')
    } else if (bankAndBlockchainEmpty()) {
      throw new ValidationError('A recipient must have a bank account or a blockchain address.')
    } else if (isMissingBlockchainDetails()) {
      throw new ValidationError('Missing blockchain details')
    } else if (isMissingBillpayDetails()) {
      throw new ValidationError('Missing billpay details')
    } else if (!validateBankDetails.institutionNum) {
      setErrors({
        ...noErrors,
        institutionNum: true
      })
      throw new ValidationError('Invalid institution num. Format: 3 digits')
    } else if (!validateBankDetails.transitNum) {
      setErrors({
        ...noErrors,
        transitNum: true
      })
      throw new ValidationError('Invalid transit num. Format: 5 digits')
    } else if (!validateBankDetails.achCode) {
      setErrors({
        ...noErrors,
        achCode: true
      })
      throw new ValidationError('Invalid ACH code. Format: 9 digits')
    } else if (!validateBankDetails.swiftBicCode) {
      setErrors({
        ...noErrors,
        swiftBicCode: true
      })
      throw new ValidationError('Invalid Swift BIC code. Format: min 8 digits, max 11 digits')
    } else if (!isValidBlockchainAddresses()) {
      setErrors({
        ...noErrors,
        address: true
      })
      throw new ValidationError('Invalid blockchain address')
    } else if (!isValidBillpayDetails()) {
      setErrors({
        ...noErrors,
        payeeCode: true,
        payeeAccountNumber: true
      })
      throw new ValidationError('Either payeeCode or payeeAccountNumber is not a number')
    }
  }

  const handleRequest = async () => {
    try {
      validate()
      setErrors(noErrors)
      setSubmitting(true)
      const response = await adminCreateContact({
        userId: userId,
        firstName: values.firstName,
        middleName: values.middleName ? values.middleName : '',
        lastName: values.lastName,
        email: values.email,
        companyTitle: values.companyTitle,
        address: values.addressLine2 ? `${values.addressLine1}, ${values.addressLine2}` : values.addressLine1,
        postalCode: values.postalCode,
        countryCode: values.countryCode,
        city: values.city,
        stateProvince: values.stateProvince,
        createBankDetails: bankDetails
          .map((b) => ({
            country: b.country,
            name: b.name,
            accHolderName: b.accHolderName,
            routingNum: `${b.institutionNum}-${b.transitNum}`,
            accountNum: b.accountNum,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
          .filter((b) => b.accountNum.length > 0),
        createBlockchainDetails: blockchainDetails.filter((b) => b.address.length > 0),
        createBillPayDetails: billpayDetails.filter((b) => b.payeeAccountNumber.length > 0)
      })
      if (response) {
        alertNotification('Recipient has been successfully added to your address book', 'success')
        setValues(emptyValuesState)
        setBankDetails([emptyBankState])
        setBlockchainDetails([emptyBlockchainState])
        setBillpayDetails([emptyBillpayState])
        handleClose()
      }
      setSubmitting(false)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
      setSubmitting(false)
    }
  }

  const chainIcon = (chain: ChainName) => {
    if (chain === ChainName.ETHEREUM) {
      return <EthIcon sx={{ mr: 1 }} />
    } else if (chain === ChainName.ALGORAND) {
      return <AlgoIcon sx={{ mr: 1 }} />
    } else if (chain === ChainName.STELLAR) {
      return <StellarIcon sx={{ mr: 1 }} />
    }
  }

  return (
    <Stack spacing={3}>
      <>
        <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
          <Box sx={{ width: '600px' }}>
            <Box sx={{ width: '400px' }}>
              <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
                Beneficiary Information
              </Typography>
              <Divider sx={{ marginBottom: '24px' }} />
              <SummaryTable
                data={[
                  {
                    key: <Typography fontWeight={500}>First Name*</Typography>,
                    value: (
                      <TextField
                        value={values.firstName}
                        onChange={handleChange}
                        name="firstName"
                        placeholder="First Name"
                        required
                        error={errors.firstName}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Middle Name</Typography>,
                    value: (
                      <TextField
                        value={values.middleName}
                        onChange={handleChange}
                        name="middleName"
                        placeholder="Middle Name"
                        error={errors.middleName}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Last Name*</Typography>,
                    value: (
                      <TextField
                        value={values.lastName}
                        onChange={handleChange}
                        name="lastName"
                        placeholder="Last Name"
                        required
                        error={errors.lastName}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Email*</Typography>,
                    value: (
                      <TextField
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                        placeholder="Email"
                        required
                        error={errors.email}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Company Name</Typography>,
                    value: (
                      <TextField
                        value={values.companyTitle}
                        onChange={handleChange}
                        name="companyTitle"
                        placeholder="Company Name"
                        error={errors.companyTitle}
                      />
                    )
                  }
                ]}
                padding="16px 0"
              />
              <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
                Beneficiary Address
              </Typography>
              <Divider sx={{ marginBottom: '24px' }} />
              <SummaryTable
                data={[
                  {
                    key: <Typography fontWeight={500}>Address Line 1*</Typography>,
                    value: (
                      <TextField
                        value={values.addressLine1}
                        onChange={handleChange}
                        name="addressLine1"
                        placeholder="Address Line 1"
                        required
                        error={errors.addressLine1}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Address Line 2</Typography>,
                    value: (
                      <TextField
                        value={values.addressLine2}
                        onChange={handleChange}
                        name="addressLine2"
                        placeholder="Address Line 2"
                        error={errors.addressLine2}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Postal/Zip Code*</Typography>,
                    value: (
                      <TextField
                        value={values.postalCode}
                        onChange={handleChange}
                        name="postalCode"
                        placeholder="Postal / Zip Code"
                        required
                        error={errors.postalCode}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>Country*</Typography>,
                    value: (
                      <TextField
                        value={values.countryCode}
                        onChange={handleChange}
                        name="countryCode"
                        placeholder="Country Code"
                        required
                        error={errors.countryCode}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>City*</Typography>,
                    value: (
                      <TextField
                        value={values.city}
                        onChange={handleChange}
                        name="city"
                        placeholder="City"
                        required
                        error={errors.city}
                      />
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>State/Province*</Typography>,
                    value: (
                      <TextField
                        value={values.stateProvince}
                        onChange={handleChange}
                        name="stateProvince"
                        placeholder="State/Province"
                        required
                        error={errors.stateProvince}
                      />
                    )
                  }
                ]}
                padding="16px 0"
              />
            </Box>
          </Box>
          <Box sx={{ width: '600px' }}>
            <Box sx={{ width: '400px' }}>
              <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
                Banking Details
              </Typography>
              <Divider sx={{ marginBottom: '24px' }} />
              {bankDetails.map((bank, idx) => (
                <Box key={idx}>
                  <Box display="flex" justifyContent="flex-end">
                    {idx !== 0 && (
                      <S.StyledButton
                        startIcon={<DeleteContactIcon />}
                        variant="outlined"
                        sx={{ color: '#D3080C', border: 0, ml: 1 }}
                        onClick={() => deleteBankDetails(idx)}
                      >
                        Delete Bank
                      </S.StyledButton>
                    )}
                  </Box>
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Country</Typography>,
                        value: (
                          <Button
                            startIcon={
                              bank.country === BankingCountry.CAN ? (
                                <FlagIcon fontSize="small" />
                              ) : (
                                <USFlagIcon fontSize="small" />
                              )
                            }
                            endIcon={<ExpandMore />}
                            color="primary"
                            onClick={(e) => handleOpenCountryMenu(e, idx)}
                            size="small"
                          >
                            {bank.country}
                          </Button>
                        )
                      }
                    ]}
                  />
                  {bank.country === BankingCountry.CAN ? (
                    <SummaryTable
                      data={[
                        {
                          key: <Typography fontWeight={500}>Account Holder Name*</Typography>,
                          value: (
                            <TextField
                              value={bank.accHolderName}
                              onChange={handleBankDetails}
                              name="accHolderName"
                              placeholder="Account Holder Name"
                              required
                              error={errors.accHolderName}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>Bank Name*</Typography>,
                          value: (
                            <TextField
                              value={bank.name}
                              onChange={handleBankDetails}
                              name="name"
                              placeholder="Bank Name"
                              required
                              error={errors.bankName}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>Institution Number*</Typography>,
                          value: (
                            <TextField
                              value={bank.institutionNum}
                              onChange={handleBankDetails}
                              name="institutionNum"
                              placeholder="Institution Number"
                              required
                              error={errors.institutionNum}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>Transit Number*</Typography>,
                          value: (
                            <TextField
                              value={bank.transitNum}
                              onChange={handleBankDetails}
                              name="transitNum"
                              placeholder="Transit Number"
                              required
                              error={errors.transitNum}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>Account Number*</Typography>,
                          value: (
                            <TextField
                              value={bank.accountNum}
                              onChange={handleBankDetails}
                              name="accountNum"
                              placeholder="Account Number"
                              required
                              error={errors.accountNum}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        }
                      ]}
                      padding="16px 0"
                    />
                  ) : (
                    <SummaryTable
                      data={[
                        {
                          key: <Typography fontWeight={500}>Account Holder Name*</Typography>,
                          value: (
                            <TextField
                              value={bank.accHolderName}
                              onChange={handleBankDetails}
                              name="accHolderName"
                              placeholder="Account Holder Name"
                              required
                              error={errors.accHolderName}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>Bank Name*</Typography>,
                          value: (
                            <TextField
                              value={bank.name}
                              onChange={handleBankDetails}
                              name="name"
                              placeholder="Bank Name"
                              required
                              error={errors.bankName}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>ACH Code*</Typography>,
                          value: (
                            <TextField
                              value={bank.achCode}
                              onChange={handleBankDetails}
                              name="achCode"
                              placeholder="ACH Code"
                              required
                              error={errors.achCode}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>SWIFT/BIC Code*</Typography>,
                          value: (
                            <TextField
                              value={bank.swiftBicCode}
                              onChange={handleBankDetails}
                              name="swiftBicCode"
                              placeholder="Swift BIC Code"
                              required
                              error={errors.swiftBicCode}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        },
                        {
                          key: <Typography fontWeight={500}>Account Number*</Typography>,
                          value: (
                            <TextField
                              value={bank.accountNum}
                              onChange={handleBankDetails}
                              name="accountNum"
                              placeholder="Account Number"
                              required
                              error={errors.accountNum}
                              onSelect={() => setBankIdx(idx)}
                            />
                          )
                        }
                      ]}
                      padding="16px 0"
                    />
                  )}
                </Box>
              ))}
              <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
                <S.StyledButton variant="outlined" startIcon={<AddBankIcon />} onClick={addNewBankDetails}>
                  Add another Bank Account
                </S.StyledButton>
              </Box>
              <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
                Blockchain Address
              </Typography>
              <Divider sx={{ marginBottom: '24px' }} />
              {blockchainDetails[0].address &&
                blockchainDetails.map((wallet, idx) => (
                  <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                    {chainIcon(wallet.chain as ChainName)}
                    <Typography>{wallet.name}</Typography>
                    <Link
                      component="a"
                      href={`${EXPLORER[wallet.chain as ChainName]}/address/${wallet.address}`}
                      target="_blank"
                      sx={{ ml: 2, mr: 1 }}
                    >
                      {trimId(wallet.address)}
                    </Link>
                  </Box>
                ))}
              <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
                <S.StyledButton
                  variant="outlined"
                  startIcon={<BlockchainIcon />}
                  onClick={() => setNewBlockchainOpen(true)}
                >
                  View or Add New Blockchain Addresses
                </S.StyledButton>
              </Box>
              <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
                Bill Pay Details
              </Typography>
              <Divider sx={{ marginBottom: '12px' }} />
              <Alert severity={'info'} sx={{ marginBottom: '12px' }}>
                {' '}
                Format: (Payee Code - Account Number)
              </Alert>
              {billpayDetails[0].payeeName &&
                billpayDetails.map((billpay, idx) => (
                  <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                    {<AccountBalanceIcon sx={{ mr: 1 }} />}
                    <Typography>{`${billpay.payeeName} (${billpay.payeeCode} - ${billpay.payeeAccountNumber})`}</Typography>
                  </Box>
                ))}
              <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
                <S.StyledButton
                  variant="outlined"
                  startIcon={<MonetizationOnIcon />}
                  onClick={() => setNewBillpayOpen(true)}
                >
                  View or Add New BillPay Details
                </S.StyledButton>
              </Box>
            </Box>
          </Box>
        </Stack>
        <Modal
          open={newBlockchainOpen}
          onClose={() => setNewBlockchainOpen(false)}
          title="Beneficiary Blockchain Addresses"
          maxWidth="md"
          fullWidth
          keepMounted
        >
          <Typography variant="subtitle2" color="grey.500" mb={2}>
            Please confirm the following blockchain address information is accurate.
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <S.StyledButton variant="outlined" startIcon={<AddOutlinedIcon />} onClick={addNewBlockchainDetails}>
              Add New Blockchain Address
            </S.StyledButton>
            <S.StyledButton
              variant="outlined"
              startIcon={<DoneOutlinedIcon />}
              onClick={() => setNewBlockchainOpen(false)}
            >
              Done
            </S.StyledButton>
          </Stack>
          <Divider sx={{ marginBottom: '24px' }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Address Nickname</TableCell>
                  <TableCell>Blockchain Address</TableCell>
                  <TableCell>Blockchain Network</TableCell>
                  <TableCell>Supported Assets</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blockchainDetails.map((wallet, idx) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}>
                    <TableCell component="th" scope="row">
                      <TextField
                        value={wallet.name}
                        onChange={handleBlockchainDetails}
                        name="name"
                        placeholder="Blockchain Nickname"
                        required
                        error={errors.name}
                        onSelect={() => setBlockchainIdx(idx)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={wallet.address}
                        onChange={handleBlockchainDetails}
                        name="address"
                        placeholder="Blockchain Address"
                        required
                        error={errors.address}
                        onSelect={() => setBlockchainIdx(idx)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        endIcon={<ExpandMore />}
                        color="primary"
                        onClick={(e) => handleOpenChainMenu(e, idx)}
                        size="small"
                      >
                        {wallet.chain}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <QCADIcon fontSize="small" /> <USDCIcon fontSize="small" />
                    </TableCell>
                    <TableCell>
                      {idx !== 0 && (
                        <IconButton aria-label="delete" onClick={() => deleteBlockchainDetails(idx)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal>
        <Modal
          open={newBillpayOpen}
          onClose={() => setNewBillpayOpen(false)}
          title="Beneficiary Billpay Addresses"
          maxWidth="md"
          fullWidth
          keepMounted
        >
          <Typography variant="subtitle2" color="grey.500" mb={2}>
            Please confirm the following billpay details information are accurate.
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <S.StyledButton variant="outlined" startIcon={<AddOutlinedIcon />} onClick={addNewBillpayDetails}>
              Add New Bill Pay Detail
            </S.StyledButton>
            <S.StyledButton
              variant="outlined"
              startIcon={<DoneOutlinedIcon />}
              onClick={() => setNewBillpayOpen(false)}
            >
              Done
            </S.StyledButton>
          </Stack>
          <Divider sx={{ marginBottom: '24px' }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Payee Name</TableCell>
                  <TableCell>Payee Code</TableCell>
                  <TableCell>Payee Account Number</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billpayDetails.map((billpay, idx) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}>
                    <TableCell component="th" scope="row">
                      <TextField
                        value={billpay.payeeName}
                        onChange={handleBillpayDetails}
                        name="payeeName"
                        placeholder="Payee Name"
                        required
                        error={errors.payeeName}
                        onSelect={() => setBillpayIdx(idx)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={billpay.payeeCode}
                        onChange={handleBillpayDetails}
                        name="payeeCode"
                        placeholder="Payee Code"
                        required
                        error={errors.payeeCode}
                        onSelect={() => setBillpayIdx(idx)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={billpay.payeeAccountNumber}
                        onChange={handleBillpayDetails}
                        name="payeeAccountNumber"
                        placeholder="Payee Account Number"
                        required
                        error={errors.payeeAccountNumber}
                        onSelect={() => setBillpayIdx(idx)}
                      />
                    </TableCell>
                    <TableCell>
                      {idx !== 0 && (
                        <IconButton aria-label="delete" onClick={() => deleteBillpayDetails(idx)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal>
        <Menu
          sx={{ mt: '7px' }}
          id="menu-country"
          anchorEl={anchorElCountry}
          keepMounted
          open={Boolean(anchorElCountry)}
          onClose={handleCloseCountryMenu}
        >
          {Object.values(BankingCountry).map((country) => (
            <MenuItem key={country} onClick={() => chooseCountry(country)}>
              <Typography>{country}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <Menu
          sx={{ mt: '7px' }}
          id="menu-chain"
          anchorEl={anchorElChain}
          keepMounted
          open={Boolean(anchorElChain)}
          onClose={handleCloseChainMenu}
        >
          {Object.values(ChainName).map((chain) => (
            <MenuItem
              key={chain}
              onClick={() => chooseChain(chain)}
              disabled={chain === ChainName.ALGORAND || chain === ChainName.STELLAR}
            >
              <Typography>{chain}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <LoadingButton variant="contained" onClick={handleRequest} loading={submitting} fullWidth>
          Add to Address Book
        </LoadingButton>
      </>
    </Stack>
  )
}

export default NewContact
