import { Alert, Snackbar } from '@mui/material'
import React, { useState, useEffect } from 'react'

interface State {
  message: string
  open: boolean
  severity: 'error' | 'success'
  autoClose?: boolean
}

let setNotificationState: React.Dispatch<React.SetStateAction<State>>

// interface AlertNotificationProps {
//   autoClose?: boolean
// }

const AlertNotification: React.FC = () => {
  const [state, setState] = useState<State>({
    message: '',
    open: false,
    severity: 'error',
    autoClose: true
  })

  setNotificationState = setState

  const { message, open, severity, autoClose } = state

  useEffect(() => {
    if (open && autoClose) {
      const timer = setTimeout(() => {
        setState({ ...state, open: false })
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [open, state, autoClose])

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ bottom: '40px !important' }}
    >
      <Alert
        severity={severity}
        onClose={() => setState({ ...state, open: false })}
        elevation={6}
        sx={{ minWidth: 500 }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export const alertNotification = (message?: string, severity: 'error' | 'success' = 'error', autoClose = true) => {
  setNotificationState({
    message: message || 'Something went wrong. Please, try again later.',
    open: true,
    severity,
    autoClose
  })
}

export default AlertNotification
