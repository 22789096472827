export const isSdcProject = () => {
  const apiUrl: string | undefined = process.env.REACT_APP_API_URL
  let projectName = ''

  if (apiUrl?.includes('sdc')) {
    projectName = 'sdc'
  } else if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SDC_PROJECT === 'sdc') {
    projectName = 'sdc'
  }

  return projectName === 'sdc'
}
