import { Alert, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BankingDetailsForm from 'src/common/components/BankingDetailsForm'
import BillPayDetailsForm from 'src/common/components/BillPayDetailsForm'
import BlockchainDetailsForm from 'src/common/components/BlockchainDetailsForm'
import { FormInput } from 'src/common/components/CrudActionCard/CrudActionCard'
import FormGroup from 'src/common/components/FormGroup'
import { bankingDetails, billPayDetails, blockchainDetails } from 'src/pages/ApiMembers/Common/data'
import { BankInfo, BillPayInfo, BlockchainInfo, MemberFullInfo } from '.././types'
import { alertNotification } from 'src/ui'
import {
  ValidationError,
  validateBankDetails,
  validateBlockchainAddresses,
  validateMemberInformation
} from 'src/common/helpers'
import { adminCreateMember } from 'src/common/api/organizations'
import { LoadingButton } from '@mui/lab'
import { ChainName } from 'src/common/static'

interface CreateMemberProps {
  entityData: FormInput[][]
}

interface CreateMemberState {
  info: MemberFullInfo
  banks: BankInfo[]
  billPays: BillPayInfo[]
  blockchains: BlockchainInfo[]
}

const CreateMember: React.FC<CreateMemberProps> = ({ entityData }: CreateMemberProps) => {
  const entityInfo: FormInput[] = entityData[0]
  const entityAddress: FormInput[] = entityData[1]

  // to force remount when resetting form
  const [bankingFormKey, setBankingFormKey] = useState<number>(0)
  const [blockchainFormKey, setBlockchainFormKey] = useState<number>(0)
  const [billpayFormKey, setbillPayFormKey] = useState<number>(0)

  const emptyMemberInfo: MemberFullInfo = {
    orgId: '',
    businessName: '',
    firstName: '',
    lastName: '',
    email: '',
    interacEmail: '',
    address: '',
    postalCode: '',
    countryCode: '',
    city: '',
    stateProvince: ''
  }

  const emptyMemberBank: BankInfo = {
    country: '',
    accHolderName: '',
    bankName: '',
    accountNum: '',
    name: '',
    institutionNum: '',
    transitNum: '',
    achCode: '',
    swiftBicCode: ''
  }

  const emptyMemberBillPay: BillPayInfo = {
    payeeName: '',
    payeeCode: '',
    payeeAccountNumber: ''
  }

  const emptyMemberBlockchain: BlockchainInfo = {
    name: '',
    chain: ChainName.ETHEREUM,
    address: '',
    externalMemo: ''
  }
  // store form information about member
  const [bankingDetailsArr, setBankingDetailsArr] = useState<FormInput[][]>([bankingDetails])
  const [billPayDetailsArr, setBillPayDetailsArr] = useState<FormInput[][]>([billPayDetails])
  const [blockchainDetailsArr, setBlockchainDetailsArr] = useState<FormInput[][]>([blockchainDetails])

  // store payload for create req
  const [memberInfo, setMemberInfo] = useState<MemberFullInfo>(emptyMemberInfo)
  const [memberBank, setMemberBank] = useState<BankInfo[]>([emptyMemberBank])
  const [memberBillPay, setMemberBillPay] = useState<BillPayInfo[]>([emptyMemberBillPay])
  const [memberBlockchain, setMemberBlockchain] = useState<BlockchainInfo[]>([emptyMemberBlockchain])

  const emptyCreateMemberState: CreateMemberState = {
    info: emptyMemberInfo,
    banks: [],
    billPays: [],
    blockchains: []
  }
  const [memberState, setMemberState] = useState<CreateMemberState>(emptyCreateMemberState)

  const handleAddBank = (): void => {
    setBankingDetailsArr((prev) => [...prev, bankingDetails])
    setMemberBank((prev) => [...prev, emptyMemberBank])
  }

  const handleAddBillPay = (): void => {
    setBillPayDetailsArr((prev) => [...prev, billPayDetails])
    setMemberBillPay((prev) => [...prev, emptyMemberBillPay])
  }

  const handleAddBlockchain = (): void => {
    setBlockchainDetailsArr((prev) => [...prev, blockchainDetails])
    setMemberBlockchain((prev) => [...prev, emptyMemberBlockchain])
  }

  const [submitting, setSubmitting] = useState(false)

  const validate = (): void => {
    validateMemberInformation(memberState.info)
    memberState.blockchains.length > 0 && validateBlockchainAddresses(memberState.blockchains)
    memberState.banks.length > 0 && validateBankDetails(memberState.banks)
  }

  const resetMemberState = () => {
    resetBlockchainFormState()
    resetBankFormState()
    resetBillPayState()
    setMemberInfo(emptyMemberInfo)
    setBankingDetailsArr([bankingDetails])
    setBillPayDetailsArr([billPayDetails])
    setBlockchainDetailsArr([blockchainDetails])
  }

  const handleCreateMemberDetailsRequest = async () => {
    try {
      validate()
      setSubmitting(true)
      const response = await adminCreateMember({
        orgId: memberState.info.orgId,
        businessName: memberState.info.businessName,
        firstName: memberState.info.firstName,
        lastName: memberState.info.lastName,
        email: memberState.info.email,
        interacEmail: memberState.info.interacEmail,
        address: memberState.info.address,
        postalCode: memberState.info.postalCode,
        countryCode: memberState.info.countryCode,
        city: memberState.info.city,
        stateProvince: memberState.info.stateProvince,
        createBankDetails: memberState.banks
          .map((b) => ({
            country: b.country,
            name: b.name,
            accHolderName: b.accHolderName,
            routingNum: b.institutionNum && `${b.institutionNum}-${b.transitNum}`,
            accountNum: b.accountNum,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
          .filter((b) => b.accountNum.length > 0),
        createBillPayDetails: memberState.billPays.filter((b) => b.payeeName.length > 0),
        createBlockchainDetails: memberState.blockchains.filter((b) => b.name.length > 0)
      })
      if (response) {
        alertNotification(`Member has been created successfully`, 'success')
        resetMemberState()
      }
      setSubmitting(false)
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setMemberState({
      info: memberInfo,
      banks: memberBank,
      billPays: memberBillPay,
      blockchains: memberBlockchain
    })
  }, [memberBank, memberInfo, memberBillPay, memberBlockchain])

  const resetBankFormState = () => {
    setMemberBank([emptyMemberBank])
    setBankingFormKey((prevKey) => prevKey + 1)
  }

  const resetBlockchainFormState = () => {
    setMemberBlockchain([emptyMemberBlockchain])
    setBlockchainFormKey((prevKey) => prevKey + 1)
  }

  const resetBillPayState = () => {
    setMemberBillPay([emptyMemberBillPay])
    setbillPayFormKey((prevKey) => prevKey + 1)
  }

  return (
    <Box>
      <Alert severity="warning">Please note, this is an API operation.</Alert>
      <Grid container spacing={2} marginBottom={10}>
        <Grid item md={6}>
          <FormGroup header="Member Information" formInputs={entityInfo} setInfo={setMemberInfo} info={memberInfo} />
        </Grid>
        {entityAddress && (
          <Grid item md={6}>
            <FormGroup header="Member Address" formInputs={entityAddress} setInfo={setMemberInfo} info={memberInfo} />
          </Grid>
        )}
        <Grid item md={6}>
          <BankingDetailsForm
            key={bankingFormKey}
            bankingDetails={bankingDetailsArr}
            handleAddBank={handleAddBank}
            setBank={setMemberBank}
            info={memberBank}
          />
        </Grid>
        <Grid item md={6}>
          <BlockchainDetailsForm
            key={blockchainFormKey}
            blockchainDetails={blockchainDetailsArr}
            handleAddBlockchain={handleAddBlockchain}
            setBlockchain={setMemberBlockchain}
            info={memberBlockchain}
          />
        </Grid>
        <Grid item md={6}>
          <BillPayDetailsForm
            key={billpayFormKey}
            billPayDetails={billPayDetailsArr}
            handleAddBillPay={handleAddBillPay}
            setBillPay={setMemberBillPay}
            info={memberBillPay}
          />
        </Grid>
      </Grid>
      <LoadingButton loading={submitting} variant="contained" onClick={handleCreateMemberDetailsRequest} fullWidth>
        Create member
      </LoadingButton>
    </Box>
  )
}

export default CreateMember
