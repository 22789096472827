import { Stack, Typography, Card, CardContent } from '@mui/material'
import { Box } from '@mui/system'
import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import { formatNumber } from 'src/common/helpers'
import { OrderAnalytics } from 'src/common/api'

interface AssetHoldingsOverviewProps {
  orderAnalytics: OrderAnalytics
}

const AssetHoldingsOverview: React.FC<AssetHoldingsOverviewProps> = ({
  orderAnalytics
}: AssetHoldingsOverviewProps) => {
  const composition = {
    withdrawalsWeekly: orderAnalytics ? orderAnalytics.withdrawals.weekly : 0,
    withdrawalsMonthly: orderAnalytics ? orderAnalytics.withdrawals.monthly : 0,
    depositsWeekly: orderAnalytics ? orderAnalytics.deposits.weekly : 0,
    depositsMonthly: orderAnalytics ? orderAnalytics.deposits.monthly : 0
  }

  const totalTransactionsMonthly = Number(composition.withdrawalsMonthly + composition.depositsMonthly)
  const withdrawalsMonthlyPercent = Number((composition.withdrawalsMonthly / totalTransactionsMonthly) * 100).toFixed(2)
  const depositsMonthlyPercent = Number((composition.depositsMonthly / totalTransactionsMonthly) * 100).toFixed(2)

  const data = [
    {
      id: 'Withdrawals (%)',
      label: 'Withdrawals',
      value: withdrawalsMonthlyPercent,
      color: '#f1d1ff'
    },
    {
      id: 'Deposits (%)',
      label: 'Deposits',
      value: depositsMonthlyPercent,
      color: '#d87cff'
    }
  ]

  const filteredData = data.filter((item) => Boolean(item.value))

  return (
    <Box mt="24px" key={orderAnalytics ? orderAnalytics.asset : ''}>
      <Card elevation={3}>
        <CardContent sx={{ p: '20px' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Stack justifyContent="space-around" alignSelf="stretch" flex="1 1 50%">
              <Typography variant="h5" fontWeight={500}>
                {orderAnalytics ? orderAnalytics.asset : ''} Holdings
              </Typography>
              <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Stack display="flex" flexDirection="column">
                  <Typography variant="h6">Withdrawals</Typography>
                  <Typography variant="subtitle1">This week: {formatNumber(composition.withdrawalsWeekly)}</Typography>
                  <Typography variant="subtitle1">
                    This month: {formatNumber(composition.withdrawalsMonthly)}
                  </Typography>
                </Stack>
                <Stack display="flex" flexDirection="column">
                  <Typography variant="h6">Deposits</Typography>
                  <Typography variant="subtitle1">This week: {formatNumber(composition.depositsWeekly)}</Typography>
                  <Typography variant="subtitle1">This month: {formatNumber(composition.depositsMonthly)}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box height={250} flex="1 1 50%">
              <ResponsivePie
                data={filteredData.length ? filteredData : []}
                valueFormat={(value) => value.toString()}
                arcLabel="none"
                margin={{ top: 10, bottom: 10 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                colors={{ datum: 'data.color' }}
                enableArcLinkLabels={false}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={3}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLinkLabelsDiagonalLength={6}
                arcLinkLabelsStraightLength={6}
                arcLinkLabelsSkipAngle={23}
                startAngle={-90}
                motionConfig="default"
                defs={[
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 3,
                    spacing: 10
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: 'Withdrawals (%)'
                    },
                    id: 'lines'
                  }
                ]}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default AssetHoldingsOverview
