import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { DropDownTypes } from 'src/common/components/dropDown/constants/DropDownTypes'
import { FormType } from 'src/common/components/dropDown/constants/FormType'
import { CreateOrderInput, OrderCardHeaderInput } from './types'

export const orderActionCardCrud = {
  create: { isPresent: true, isDropdown: false },
  get: { isPresent: false, isDropdown: false },
  update: { isPresent: true, isDropdown: true },
  delete: { isPresent: false, isDropdown: false }
}

const orderInfoInput: FormInput[] = [
  {
    formTitle: 'Member ID',
    formId: 'memberId',
    formType: FormType.input,
    formValue: 'Member ID'
  },
  {
    formTitle: 'First Name',
    formId: 'firstName',
    formType: FormType.input,
    formValue: 'First Name'
  },
  {
    formTitle: 'Last Name',
    formId: 'lastName',
    formType: FormType.input,
    formValue: 'Last Name'
  },
  {
    formTitle: 'Fiat Amount',
    formId: 'fiatAmt',
    formType: FormType.input,
    formValue: 'Fiat Amount'
  },
  {
    formTitle: 'Fiat Currency',
    formId: 'fiatCurrency',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.Currency
  },
  {
    formTitle: 'Crypto Amount',
    formId: 'cryptoAmount',
    formType: FormType.input,
    formValue: 'Crypto Amount'
  },
  {
    formTitle: 'Chain',
    formId: 'chain',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.Chain
  },
  {
    formTitle: 'Address',
    formId: 'address',
    formType: FormType.input,
    formValue: 'address'
  },
  {
    formTitle: 'Asset',
    formId: 'asset',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.Crypto
  },
  {
    formTitle: 'Payment Method',
    formId: 'paymentMethod',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.PaymentMethod
  },
  {
    formTitle: 'FX Rate',
    formId: 'fxRate',
    formType: FormType.input,
    formValue: 'FX Rate'
  },
  {
    formTitle: 'Banking Details ID',
    formId: 'bankingDetailsId',
    formType: FormType.input,
    formValue: 'Banking Details ID'
  }
]

export const createOrderInput: CreateOrderInput = {
  orderInfoInput: orderInfoInput
}

export const getOrderInput: FormInput[] = [
  {
    formTitle: 'Order Status',
    formId: 'orderStatus',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.OrderStatus
  }
]

export const updatePurchaseOrderInput: FormInput[] = [
  {
    formTitle: 'Order Id',
    formId: 'orderId',
    formType: FormType.input,
    formValue: 'Order Id'
  },
  {
    formTitle: 'Reference Id',
    formId: 'refId',
    formType: FormType.input,
    formValue: 'Reference Id'
  }
]

export const updateRedeemOrderInput: FormInput[] = [
  {
    formTitle: 'Order Id',
    formId: 'orderId',
    formType: FormType.input,
    formValue: 'Order Id'
  },
  {
    formTitle: 'Reference Id',
    formId: 'refId',
    formType: FormType.input,
    formValue: 'Reference Id'
  },
  //TODO: Need to check if there drop down values here are correct
  {
    formTitle: 'Order Status',
    formId: 'orderStatus',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.OrderStatus
  },
  {
    formTitle: 'Transfer Status',
    formId: 'transferStatus',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.TxStatus
  }
]

export const updatePurchaseRefIdOrderInput: FormInput[] = [
  {
    formTitle: 'Reference Id',
    formId: 'refId',
    formType: FormType.input,
    formValue: 'Reference Id'
  }
]

export const updateRedeemRefStatusesOrderInput: FormInput[] = [
  {
    formTitle: 'Reference Id',
    formId: 'refId',
    formType: FormType.input,
    formValue: 'Reference Id'
  },
  {
    formTitle: 'Order Status',
    formId: 'orderStatus',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.OrderStatus
  },
  {
    formTitle: 'Transfer Status',
    formId: 'transferStatus',
    formType: FormType.dropdown,
    dropDownType: DropDownTypes.TxStatus
  }
]

export const orderCardHeaderInput: OrderCardHeaderInput = {
  infoHeader: 'Order Details',
  transactionHeader: 'Transaction Details',
  transferHeader: 'Transfer Details'
}
