import { Box, Button, Divider, Stack, TextField, Typography, Link } from '@mui/material'
import React, { useState } from 'react'
import { fetchWallets, getUserDetails, GetWalletsModel } from 'src/common/api'
import { UserModel } from 'src/common/api/users/models'
import { ChainName, EXPLORER, GRAPES_APAYLO_CONSTANT } from 'src/common/static'
import { alertNotification, Container, SummaryTable } from 'src/ui'

const UserDetails: React.FC = () => {
  const [value, setValue] = useState('')
  const [user, setUser] = useState<UserModel | null>(null)
  const [loading, setLoading] = useState(false)
  const [wallets, setWallets] = useState<(GetWalletsModel | null)[]>([])

  const clearOrderState = () => {
    setUser(null)
    setWallets([])
    setLoading(true)
  }

  const fetchUser = async () => {
    try {
      clearOrderState()
      const response = await getUserDetails(value)
      if (response.data) {
        setUser(response.data)
        const walletsResponse = await fetchWallets({ userId: response.data.id })
        setWallets(walletsResponse.data)
      }
      setLoading(false)
    } catch {
      alertNotification('User not found or fetching user wallets failed')
      setLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)
  }

  return (
    <Container maxWidth={700}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField name="email" placeholder="User Email" onChange={handleChange} value={value} />
          <Box>
            <Button variant="contained" onClick={fetchUser} disabled={loading}>
              Search
            </Button>
          </Box>
        </Stack>
        {user && (
          <>
            <Divider />
            <Typography variant="h6">User Details</Typography>
            <SummaryTable
              padding={7}
              data={[
                {
                  key: 'Account Type',
                  value: user.accountType
                },
                {
                  key: 'Grapes Account ID (Apaylo)',
                  value: `${GRAPES_APAYLO_CONSTANT}${user.accountId}`
                },
                {
                  key: 'User First Name',
                  value: user.firstName
                },
                {
                  key: 'User Last Name',
                  value: user.lastName
                },
                {
                  key: 'User Address',
                  value: user.address
                },
                {
                  key: 'User City',
                  value: user.city
                },
                {
                  key: 'User State Province',
                  value: user.stateProvince
                },
                {
                  key: 'User Country Code',
                  value: user.countryCode
                },
                {
                  key: 'User Postal Code',
                  value: user.postalCode
                },
                {
                  key: 'User Interac Email',
                  value: user.interacEmail
                }
              ]}
            />
            <Divider />
            <Typography variant="h6">User Bank Details</Typography>
            {!loading &&
              Boolean(user.bankDetails) &&
              user.bankDetails.map(
                (bankDetail) =>
                  bankDetail && (
                    <>
                      <SummaryTable
                        padding={7}
                        data={[
                          {
                            key: 'Bank ID',
                            value: bankDetail.id
                          },
                          {
                            key: 'Country',
                            value: bankDetail.country
                          },
                          {
                            key: 'Bank Name',
                            value: bankDetail.name
                          },
                          {
                            key: 'Bank Account Holder Name',
                            value: bankDetail.accHolderName
                          },
                          {
                            key: 'Bank Routing Number',
                            value: bankDetail.routingNum ? bankDetail.routingNum : 'N/A'
                          },
                          {
                            key: 'Bank Account Number',
                            value: bankDetail.accountNum
                          },
                          {
                            key: 'Bank ACH Code',
                            value: bankDetail.achCode ? bankDetail.achCode : 'N/A'
                          },
                          {
                            key: 'Bank Swift BIC Code',
                            value: bankDetail.swiftBicCode ? bankDetail.swiftBicCode : 'N/A'
                          },
                          {
                            key: 'Primary Bank Account',
                            value: bankDetail.isPrimary ? 'Yes' : 'No'
                          }
                        ]}
                      />
                    </>
                  )
              )}
            <Divider />
            <Typography variant="h6">Connected Wallets</Typography>
            {!loading &&
              Boolean(wallets.length) &&
              wallets.map(
                (wallet) =>
                  wallet && (
                    <>
                      <SummaryTable
                        padding={7}
                        data={[
                          {
                            key: 'Wallet ID',
                            value: wallet.id
                          },
                          {
                            key: 'Address',
                            value: (
                              <Link
                                component="a"
                                href={
                                  wallet.chain != ChainName.STELLAR
                                    ? `${EXPLORER[wallet.chain]}/address/${wallet.address}`
                                    : `${EXPLORER[wallet.chain]}/account/${wallet.address}`
                                }
                                target="_blank"
                                rel="noopener"
                              >
                                {wallet.address
                                  .slice(0, 26)
                                  .concat('...')
                                  .concat(wallet.address.slice(wallet.address.length - 4))}
                              </Link>
                            )
                          },
                          {
                            key: 'Name',
                            value: wallet.name
                          },
                          {
                            key: 'Chain',
                            value: wallet.chain
                          },
                          {
                            key: 'Type',
                            value: wallet.type
                          },
                          {
                            key: 'External ID (for custodial only)',
                            value: wallet.externalId ? wallet.externalId : 'N/A'
                          }
                        ]}
                      />
                    </>
                  )
              )}
          </>
        )}
      </Stack>
    </Container>
  )
}

export default UserDetails
