import { ChainAsset, ChainName, FiatCurrency } from 'src/common/static'
import { SUPPORTED_ASSET } from '../../../pages/AnalyticsDashboard/FireblocksHoldingsOverview/constants/supportedAssets'
import { MemberModel } from '../organizations'

export interface OrderModel {
  id: number
  status: string
  createdAt: string
  fiatAmount: string
  fiatCurrency: FiatCurrency
  cryptoAmount: string
  txBuffer: string
  txId?: string
}

export interface OrderDetails {
  id: number
  type: 'PURCHASE' | 'REDEEM' | 'SEND'
  fiatAmount: string
  fiatCurrency: FiatCurrency
  cryptoAmount: string
  fxRate: string
  fxRateTimestamp: string
  status: string
  paymentMethod: 'BILL_PAY' | 'EFT' | 'FIAT_PAYOUT' | 'FIAT_OTHER' | 'CRYPTO_TRANSFER'
  createdAt: string
  ipAddress: string
  contactId?: string | null
  memberId?: string | null
  organizationId?: string | null
  bankingDetailsId?: string
  billPayDetailsId?: string
  remark?: string
  member?: MemberModel
  transfers: {
    id: number
    amount: string
    fiatCurrency: FiatCurrency
    status: string
    referenceId: string
    createdAt: string
  }[]
  transactions: {
    id: number
    asset: 'QCAD' | 'USDC'
    amount: string
    status: string
    address: string
    chain: ChainName
    referenceId: string
    createdAt: string
  }[]
}

export interface OrdersModel {
  numOfPages: number
  orders: OrderDetails[]
}

export enum KYC_STATUS {
  UNINITIATED = 'Uninitiated',
  NEW = 'New',
  PENDING = 'Pending',
  REVIEW = 'Review',
  ESCALATED = 'Escalated',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export interface Eligibility {
  walletScreening: 'fail' | 'success'
  kycStatus: KYC_STATUS
}

export interface ExchangeRates {
  eth: number
  usd: number
}

export interface OrderAnalytics {
  asset: ChainAsset
  withdrawals: {
    weekly: number
    monthly: number
  }
  deposits: {
    weekly: number
    monthly: number
  }
}

export interface OrderLimit {
  id: number
  dailyBankingLimit: string
  weeklyBankingLimit: string
  createdAt: string
  updatedAt: string
}

export interface SupportedAsset {
  id: keyof typeof SUPPORTED_ASSET
  blockHash: string
  tag: string
  balance: string
  lockedAmount: string
}

export interface AdminVault {
  id: string
  name: string
  assets: SupportedAsset[]
  autoFuel: boolean
}
