import React, { useState } from 'react'
import CrudActionCard from 'src/common/components/CrudActionCard'
import { CrudTypes } from 'src/common/components/CrudButtons/constants/CrudTypes'
import { FormInput } from 'src/common/components/FormGroup/FormGroup'
import { MemberDetailsInfo } from '../types'
import { Box } from '@mui/material'
import { CrudActionContext } from '../context'
import { alertNotification } from 'src/ui'
import { adminDeleteMember } from 'src/common/api/organizations'
import { ValidationError } from 'src/common/helpers'

interface DeleteMemberProps {
  formInputs: FormInput[]
}

const DeleteMember: React.FC<DeleteMemberProps> = ({ formInputs }: DeleteMemberProps) => {
  const emptyMemberDetailsInfo: MemberDetailsInfo = {
    orgId: '',
    memberId: ''
  }

  const [deleteMemberDetailsInfo, setDeleteMemberDetailsInfo] = useState<MemberDetailsInfo>(emptyMemberDetailsInfo)

  const handleDeleteMemberDetailsRequest = async () => {
    try {
      const response = await adminDeleteMember({
        memberId: deleteMemberDetailsInfo.memberId,
        orgId: deleteMemberDetailsInfo.orgId
      })
      if (response) {
        alertNotification(`Member ${deleteMemberDetailsInfo.memberId} has been deleted successfully`, 'success')
        setDeleteMemberDetailsInfo(emptyMemberDetailsInfo)
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else if (e instanceof Error && e.message.includes('404')) {
        alertNotification('Member not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  return (
    <Box>
      <CrudActionContext.Provider value={handleDeleteMemberDetailsRequest}>
        <CrudActionCard
          crudType={CrudTypes.Delete}
          formInputs={formInputs}
          setInfo={setDeleteMemberDetailsInfo}
          info={deleteMemberDetailsInfo}
        />
      </CrudActionContext.Provider>
    </Box>
  )
}

export default DeleteMember
